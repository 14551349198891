<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <div class="dialog-content">
                <div class="row">
                    <div class="col l-12 m-12 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="categoryName" @md-selected="getCategorySelected" :md-options="categories" @md-changed="getCategories"  @md-opened="getCategories" :class="{'md-invalid': submitted && $v.entity.categoryId.$error }">
                                <label>Loại phí</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.categoryName }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                                <span class="md-error" v-if="submitted && !$v.entity.categoryId.required">Vui lòng chọn loại phí</span>
                            </md-autocomplete>
                            <md-button @click="openCategory()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                            </md-button>
                        </div>
                        <md-field :class="{'md-invalid': submitted && $v.entity.feeCode.$error }">
                            <label for="feeCode">Mã phí</label>
                            <md-input name="feeCode" v-model="entity.feeCode"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.feeCode.required">Vui lòng nhập mã phí</span>
                        </md-field>

                        <md-field :class="{'md-invalid': submitted && $v.entity.feeAlias.$error }">
                            <label for="feeAlias">Tên viết tắt</label>
                            <md-input name="feeAlias" v-model="entity.feeAlias"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.feeAlias.required">Vui lòng nhập tên phí viết tắt</span>
                        </md-field>

                        <md-field :class="{'md-invalid': submitted && $v.entity.feeName.$error }">
                            <label for="feeName">Tên phí</label>
                            <md-input name="feeName" v-model="entity.feeName"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.feeName.required">Vui lòng nhập tên phí</span>
                        </md-field>

                        <div class="radio-group">
                            <label>Trạng thái</label>
                            <div class="form-control ">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <categoryList ref="categoryList" :type="2" title="Loại phí" @close="closeCategory"/>
    </md-dialog>
</template>

<script>
    import feeService from '../../../api/feeService';
    import categoryService from '../../../api/categoryService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import categoryList from '../../../components/popup/_CategoryList.vue';

    export default ({
        components: {
            categoryList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật phí'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, categoryId: 0, feeCode: '', feeAlias: '', feeName: '', isActive: true },
                saveAndCreate: false,
                categoryName: '',
                categories: []
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                this.categoryName = "";
                if(id == 0){
                    this.title = 'Thêm mới phí';
                    this.entity = { id: 0, categoryId: 0, feeCode: '', feeName: '', isActive: true };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật phí';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            //Category
            closeCategory(item){
                this.categoryName = item.categoryName;
                this.entity.categoryId = item.id;
                this.$refs.categoryList.close();
            },

            getCategorySelected(val){
                this.entity.categoryId = val.id;
                this.categoryName = val.categoryName;
            },

            openCategory(){
                this.$refs.categoryList.open(2);
            },

            getCategories(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 2, code: searchTerm };
                categoryService.getCategories(search).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
                this.$emit('close', this.saveAndCreate);
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = 1;
                feeService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                feeService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                feeService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.categoryName = response.data.category.categoryName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/fee');
            }
        },
        watch: { 
            categoryName: function (val) { 
                if(val == ''){
                    this.entity.categoryId = 0;
                }
            }
        },
        validations: {
            entity: {
                categoryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                feeCode: { required },
                feeAlias: { required },
                feeName: { required },
            }
       }
     })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 600px;
        height: 460px;
        max-width: 600px;
    }
    .md-scrollbar {
        height: 470px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>