<template>
    <div class="tab-container">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <div class="tab-title">{{title}}</div>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="group-content">
            <div class="group">
                <div class="group-head">
                    Thông tin chung
                </div>
                <div class="group-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                    <label>Khách hàng<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="projectName" @md-selected="getProjectSelected" :md-options="projects" @md-changed="getProjects"  @md-opened="getProjects" :class="{'md-invalid': submitted && $v.entity.projectId.$error }">
                                    <label>Dự án<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.projectCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.projectId.isSelected">Vui lòng chọn dự án</span>
                                </md-autocomplete>
                                <md-button @click="openProject()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="contractName" @md-selected="getContractSelected" :md-options="contracts" @md-changed="getContracts"  @md-opened="getContracts" :class="{'md-invalid': submitted && $v.entity.contractId.$error }">
                                    <label>Hợp đồng<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.contractName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.contractId.isSelected">Vui lòng chọn hợp đồng</span>
                                </md-autocomplete>
                                <md-button @click="openContract()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.orderCode.$error }">
                                        <label for="orderCode">Mã code<span class="label-require">(*)</span></label>
                                        <md-input disabled name="orderCode" v-model="entity.orderCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.orderCode.required">Vui lòng nhập mã đơn hàng thường</span>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="orderName">Mã lô hàng</label>
                                        <md-input name="orderName" v-model="entity.orderName"></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="ownerName" @md-selected="getOwnerSelected" :md-options="owners" @md-changed="getOwners"  @md-opened="getOwners" :class="{'md-invalid': submitted && $v.entity.ownerId.$error }">
                                    <label>Chủ hàng<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.ownerId.isSelected">Vui lòng chọn chủ hàng</span>
                                </md-autocomplete>
                                <md-button @click="openOwner()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                <label for="branchId">Chi nhánh<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.branchId" name="branchId" id="branchId">
                                    <md-option value="0">Chọn chi nhánh</md-option>
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-field :class="{'md-invalid': submitted && $v.entity.typeOfTransport.$error }">
                                    <label>Loại hình<span class="label-require">(*)</span></label>
                                    <md-select v-model="entity.typeOfTransport">
                                        <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="submitted && !$v.entity.typeOfTransport.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                                </md-field>
                            </div>
                            <md-field>
                                <label for="orderDate">Ngày lập</label>
                                <md-input :disabled="true" name="orderDate" v-model="entity.orderDate"></md-input>
                            </md-field>
                            <md-datepicker v-model="entity.deliveryDate" md-immediately :md-model-type="String">
                                <label>Ngày đóng/giao hàng</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="checkbox-group" style="padding-top: 15px;">
                                <div class="form-control">
                                    <md-checkbox v-model="entity.isTransport">Vận tải?</md-checkbox>
                                </div>
                            </div>
                            <div class="checkbox-group" style="padding-top: 15px;">
                                <div class="form-control">
                                    <md-checkbox v-model="entity.isHandle">Thủ tục hải quan?</md-checkbox>
                                </div>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="group">
                <div class="group-head">
                    Bill/booking
                </div>
                <div class="group-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="bookingNo">Số booking</label>
                                        <md-input name="bookingNo" v-model="entity.booking.bookingNo" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="masterBill">Master bill</label>
                                        <md-input name="masterBill" v-model="entity.booking.masterBill" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="houseBill">House bill</label>
                                        <md-input name="houseBill" v-model="entity.booking.houseBill" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="shipName" @md-selected="getShipSelected" :md-options="ships" @md-changed="getShips"  @md-opened="getShips">
                                    <label>Hãng tàu</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openShip()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hãng tàu</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="entity.booking.dateOfShipCome" md-immediately :md-model-type="String">
                                        <label>Ngày tàu đến</label>
                                    </md-datepicker>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="entity.booking.dateOfShipGo" md-immediately :md-model-type="String">
                                        <label>Ngày tàu đi</label>
                                    </md-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="contactName">Người liên hệ</label>
                                        <md-input name="contactName" v-model="entity.booking.contactName" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="contactPhone">Số điện thoại</label>
                                        <md-input name="contactPhone" v-model="entity.booking.contactPhone"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="numberDem">Số ngày miễn lưu DEM</label>
                                        <md-input name="numberDem" v-model="entity.booking.numberDem" type="number" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="numberDet">Số ngày miễn lưu DET</label>
                                        <md-input name="numberDet" v-model="entity.booking.numberDet" type="number" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="shipName">Tên tàu</label>
                                        <md-input name="shipName" v-model="entity.booking.shipName" ></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="shipNo">Số chuyến</label>
                                        <md-input name="shipNo" v-model="entity.booking.shipNo" ></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="entity.booking.commandDate" md-immediately :md-model-type="String">
                                        <label>Hạn lệnh</label>
                                    </md-datepicker>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="entity.booking.emptyDate" md-immediately :md-model-type="String">
                                        <label>Hạn rỗng</label>
                                    </md-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="entity.booking.cutoffDate" md-immediately :md-model-type="String">
                                        <label>CUT OFF</label>
                                    </md-datepicker>
                                </div>
                                <div class="col l-5 m-5 c-12">
                                    <md-field>
                                        <label for="cutoffTime">Giờ CUTOFF</label>
                                        <md-input name="cutoffTime" v-mask="'##:##'" placeholder="HH:mm" v-model="entity.booking.cutoffTime"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="group">
                <div class="group-head">
                    Khai báo hải quan
                </div>
                <div class="group-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="declarationName" @md-selected="getStaffDecSelected" :md-options="decList" @md-changed="getStaffDecs"  @md-opened="getStaffDecs">
                                    <label>Nhân viên khai báo</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openStaffDec()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies">
                                    <label>Cơ quan hải quan</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="declarationCode">Số tờ khai</label>
                                <md-input name="declarationCode" v-model="entity.customs.declarationCode" ></md-input>
                            </md-field>

                            <md-datepicker v-model="entity.customs.declarationDate" md-immediately :md-model-type="String">
                                <label>Ngày tờ khai</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="categoryName" @md-selected="getCategorySelected" :md-options="categories" @md-changed="getCategories"  @md-opened="getCategories">
                                    <label>Loại hình xuât/nhập khẩu</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.categoryCode + "-" + item.categoryName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCategory()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>

                            <md-datepicker v-model="entity.customs.clearanceDate" md-immediately :md-model-type="String">
                                <label>Ngày thông quan</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="thread">Phân luồng<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.customs.thread" name="thread" id="thread">
                                    <md-option :value="0">Chưa phân luồng</md-option>
                                    <md-option :value="3">Luồng xanh</md-option>
                                    <md-option :value="1">Luồng vàng</md-option>
                                    <md-option :value="2">Luồng đỏ</md-option>
                                </md-select>
                            </md-field>
                            <md-field>
                                <label for="status">Trạng thái<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.customs.status" name="status" id="status">
                                    <md-option :value="0">Chưa mở tờ khai</md-option>
                                    <md-option :value="1">Mở tờ khai</md-option>
                                    <md-option :value="2">Phân luồng</md-option>
                                    <md-option :value="3">Đăng ký</md-option>
                                    <md-option :value="4">Không làm hải quan</md-option>
                                    <md-option :value="5">Thông quan</md-option>
                                    <md-option :value="6">Mang hàng về bảo quản</md-option>
                                    <md-option :value="7">Giải phóng hàng</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <projectList ref="projectList" @close="closeProject"/>
        <contractList ref="contractList" @close="closeContract"/>
        <ownerList ref="ownerList" title="chủ hàng" :type="ownerType" @close="closeOwner"/>
        <staffList ref="staffList" @close="closeStaff"/>
        <staffDecList ref="staffDecList" @close="closeStaffDec"/>
        <shipList ref="shipList" title="hãng tàu" :type="companyShipType" @close="closeShip"/>
        <categoryList ref="categoryList" @close="closeCategory"/>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import { required } from 'vuelidate/lib/validators';
    import messageBox from '../../../utils/messageBox';
    import branchService from '../../../api/branchService';
    import bookingService from '../../../api/bookingService';
    import customsService from '../../../api/customsService';
    import companyService from '../../../api/companyService';
    import contractService from '../../../api/contractService';
    import staffService from '../../../api/staffService';
    import projectService from '../../../api/projectService';
    import orderService from '../../../api/orderService';
    import categoryService from '../../../api/categoryService';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import contractList from '../../../components/popup/_ContractList.vue';
    import projectList from '../../../components/popup/_ProjectList.vue';
    import ownerList from '../../../components/popup/_CompanyList.vue';
    import staffList from '../../../components/popup/_StaffList.vue';
    import categoryList from '@/components/popup/_CategoryXNKTypeList';
    import staffDecList from '../../../components/popup/_StaffList.vue';
    import shipList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            contractList,
            customerList,
            projectList,
            ownerList,
            staffList,
            categoryList,
            staffDecList,
            shipList
        },
        data() {
            return {
                id: 0,
                submitted: false,
                loadding: false,
                customerName: '',
                customers: [],
                projectName: '',
                projects: [],
                contractName: '',
                contracts: [],
                ownerName: '',
                owners: [],
                branchs: [],
                typeList: common.typeOfTransport,
                companyShipType: common.companyType.shipping,
                staffName: '',
                staffs: [],
                shipName: '',
                ships: [],
                customerType: common.companyType.customer,
                ownerType: common.companyType.customer,
                companyType: common.companyType.customs,
                groupType: common.groupCode.customsType,
                declarationName: '',
                decList: [],
                companies: [],
                companyName: '',
                categories: [],
                categoryName: '',
                entity: { id: 0, deptId: 0, orderNumber: 0, customerId: 0, projectId: 0, contractId: 0, orderCode: '', orderName: '', 
                    ownerId: 0, branchId: 0, planId: 1, requestId: 1, typeOfTransport: 1, staffId: 0,
                    orderDate: common.dateNow, deliveryDate: null, note: '', status: 0, isHandle: true, isTransport: true,
                    booking: { id: 0, orderId: 0, numberDem: null, numberDet: null, shipId: null, shipName: '', shipNo: '', bookingNo: '', masterBill: '', houseBill: '', contactName: '', contactPhone: '', dateOfShipCome: '', dateOfShipGo: '', cutoffDate: null, cutoffTime: null, emptyDate: null, commandDate: null },
                    customs: { id: 0, staffId: 0, companyId: null, orderId: 0, categoryId: null, declarationCode: '', declarationDate: common.dateNow, clearanceDate: '', thread: 0, status: 0 }
                },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật đơn hàng thường';
               this.getById();
            }
            else{
               this.title = 'Thêm mới đơn hàng thường';
               this.getCode();
               this.getByAccount();
            }
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                orderService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push("/order/crud/" + response.data.id);
                        this.id = response.data.id;
                        this.getById();
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                orderService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                    this.setLoading(false);
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                orderService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.contractName = response.data.contract.contractName;
                        this.customerName = response.data.customer.companyAlias;
                        this.projectName = response.data.project.projectCode;
                        this.staffName = response.data.staff.fullName;

                        if(this.entity.ownerId > 0){
                            this.ownerName = this.entity.owner.companyAlias;
                        }

                        this.getBookingByOrderId(this.id);
                        this.getCustomsByOrderId(this.id);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getCustomsByOrderId(id){
                this.setLoading(true);
                customsService.getByOrderId(id).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null){
                            this.entity.customs = response.data;
                            this.declarationName = this.entity.customs.staff.fullName;
                            if(this.entity.customs.companyId > 0){
                                this.companyName = this.entity.customs.company.companyAlias;
                            }
                            if(this.entity.customs.categoryId > 0){
                                this.categoryName = this.entity.customs.category.categoryCode + "-" + this.entity.customs.category.categoryName;
                            }
                        }
                        else{
                            this.getByAccount();
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getBookingByOrderId(id){
                this.setLoading(true);
                bookingService.getByOrderId(id).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null){
                            this.entity.booking = response.data;
                            if(this.entity.booking.shipId > 0){
                                this.shipName = response.data.ship.companyAlias;
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Category
            closeCategory(item){
                this.categoryName = item.categoryCode + "-" + item.categoryName;
                this.entity.customs.categoryId = item.id;
                this.$refs.categoryList.close();
            },

            getCategorySelected(item){
                this.entity.customs.categoryId = item.id;
                this.categoryName = item.categoryCode + "-" + item.categoryName;
            },

            openCategory(){
                this.$refs.categoryList.open();
            },

            getCategories(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 5, code: searchTerm };
                categoryService.getCategories(search).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Company
            closeCompany(item){
                this.companyName = item.companyAlias;
                this.entity.customs.companyId = item.id;
                this.$refs.companyList.close();
            },

            getCompanySelected(val){
                this.entity.customs.companyId = val.id;
                this.companyName = val.companyAlias;
            },

            openCompany(){
                this.$refs.companyList.open(11);
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: this.orderType, companyType: common.companyType.customs, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Staff
            closeStaffDec(item){
                this.declarationName = item.fullName;
                this.entity.customs.staffId = item.id;
                this.entity.customs.deptId = item.departmentId,
                this.$refs.staffList.close();
            },

            getStaffDecSelected(val){
                this.entity.customs.staffId = val.id;
                this.entity.customs.deptId = val.departmentId,
                this.declarationName = val.fullName;
            },

            openStaffDec(){
                this.$refs.staffDecList.open();
            },

            getStaffDecs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.decList = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Ship
            closeShip(item){
                this.shipName = item.companyAlias;
                this.entity.booking.shipId = item.id;
                this.$refs.shipList.close();
            },

            getShipSelected(val){
                this.entity.booking.shipId = val.id;
                this.shipName = val.companyAlias;
            },

            openShip(){
                this.$refs.shipList.open();
            },

            getShips(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.shipping, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.ships = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCode(){
                orderService.getCode().then((response) => {
                    if(response.statusCode == 200){
                        this.entity.orderCode = response.data.code;
                        this.entity.orderNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.currencyUserId = response.data.id;
                            if(this.entity.id == 0){
                                this.entity.staff = response.data;
                                this.entity.staffId = response.data.id;
                                this.entity.deptId = response.data.departmentId;
                                this.entity.branchId = response.data.department.branchId;
                                this.staffName = response.data.fullName;
                                this.entity.customs.staffId = response.data.id;
                                this.entity.customs.deptId = response.data.departmentId;
                                this.declarationName = response.data.fullName;
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId,
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId,
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Owner
            closeOwner(item){
                this.ownerName = item.companyAlias;
                this.entity.ownerId = item.id;
                this.$refs.ownerList.close();
            },

            getOwnerSelected(val){
                this.entity.ownerId = val.id;
                this.ownerName = val.companyAlias;
            },

            openOwner(){
                this.$refs.ownerList.open();
            },

            getOwners(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.owners = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
            
            //Project
            getProjectByCustomer(customerId){
                let search = { pageIndex: 1, pageSize: 30, customerId: customerId, orderType: 1, code: '' };
                projectService.getProjects(search).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data.items.length > 0){
                            this.entity.projectId = response.data.items[0].id;
                            this.projectName = response.data.items[0].projectName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeProject(item){
                this.projectName = item.projectCode;
                this.entity.projectId = item.id;
                this.$refs.projectList.close();
            },

            getProjectSelected(val){
                this.entity.projectId = val.id;
                this.projectName = val.projectCode;
            },

            openProject(){
                this.$refs.projectList.open(this.entity.customerId, 1);
            },

            getProjects(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, customerId: this.entity.customerId, orderType: 1, code: searchTerm };
                projectService.getProjects(search).then((response) => {
                    if(response.statusCode == 200){
                        this.projects = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Contract
            getContractByCustomer(customerId){
                let search = { pageIndex: 1, pageSize: 30, customerId: customerId, code: '' };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data.items.length > 0){
                            this.entity.contractId = response.data.items[0].id;
                            this.contractName = response.data.items[0].contractName;
                            this.getContractById(this.entity.contractId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getContractById(id){
                contractService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        let contract = response.data;
                        if(contract.owners.length > 0){
                            this.entity.ownerId = contract.owners[0].id;
                            this.ownerName = contract.owners[0].companyAlias;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeContract(item){
                this.contractName = item.contractName;
                this.entity.contractId = item.id;
                this.entity.customerId = item.customerId;
                this.customerName = item.customer.companyAlias;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.entity.contractId = val.id;
                this.contractName = val.contractName;
                this.entity.customerId = val.customerId;
                this.customerName = val.customer.companyAlias;
            },

            openContract(){
                this.$refs.contractList.open(this.entity.customerId);
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, customerId: this.entity.customerId, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.$refs.customerList.close();
                this.entity.contractId = 0;
                this.contractName = '';
                this.getProjectByCustomer(this.entity.customerId);
                this.getContractByCustomer(this.entity.customerId);
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
                this.entity.contractId = 0;
                this.contractName = '';
                this.getProjectByCustomer(this.entity.customerId);
                this.getContractByCustomer(this.entity.customerId);
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/order');
            }
        },
        watch: {
            customerName: function (val){
                if(val == ''){
                    this.entity.customerId = 0;
                    this.entity.contractId = 0;
                    this.contractName = '';
                    this.entity.projectId = 0;
                    this.entity.projectName = '';
                    this.projectName = '';
                }
            },
            ownerName: function (val) { 
                if(val == ''){
                    this.entity.ownerId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.entity.contractId = 0;
                    this.entity.ownerId = 0;
                    this.ownerName = '';
                }
            },
            projectName: function (val) { 
                if(val == ''){
                    this.entity.projectId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                orderCode: { required },
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                contractId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                ownerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                projectId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                planId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                requestId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                typeOfTransport: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                orderDate: { required }
            }
        }
    })
</script>