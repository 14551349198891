<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>Thêm mới admin</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.roleId.$error }">
                                <label for="role">Nhóm quyền</label>
                                <md-select v-model="entity.roleId" name="role" id="role">
                                    <md-option value="0">Chọn nhóm quyền</md-option>
                                    <md-option v-for="role in roles" :key="role.id" :value="role.id">{{role.roleName}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.roleId.isSelected">Vui lòng chọn nhóm quyền</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.userName.$error }">
                                <label for="user-name">Tên đăng nhập</label>
                                <md-input :disabled="id > 0" name="user-name" v-model="entity.userName" minlength="6"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.userName.required">Vui lòng nhập tên đăng nhập</span>
                                <span class="md-error" v-if="submitted && !$v.entity.userName.minLength">Tên đăng nhập ít nhất 4 ký tự</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.password.$error }">
                                <label for="password">Mật khẩu</label>
                                <md-input :disabled="id > 0" name="password" v-model="entity.password" minlength="6" type="password"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.password.required">Vui lòng nhập mật khẩu</span>
                                <span class="md-error" v-if="submitted && !$v.entity.password.minLength">Tên đăng nhập ít nhất 6 ký tự</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.confirmPassword.$error }">
                                <label for="confirm-password">Nhập lại mật khẩu</label>
                                <md-input :disabled="id > 0" name="confirm-password" minlength="6" v-model="entity.confirmPassword" type="password"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.confirmPassword.required">Vui lòng nhập lại mật khẩu</span>
                                <span class="md-error" v-else-if="!$v.entity.confirmPassword.sameAsPassword">Nhập lại mật khẩu không đúng</span>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.firstName.$error }">
                                        <label for="first-name">Họ và tên đệm</label>
                                        <md-input disabled name="first-name" v-model="entity.firstName" ></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.firstName.required">Vui lòng nhập họ và tên đệm</span>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.lastName.$error }">
                                        <label for="last-name">Tên</label>
                                        <md-input disabled name="last-name" v-model="entity.lastName" ></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.lastName.required">Vui lòng nhập tên</span>
                                    </md-field>
                                </div>
                            </div>

                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control">
                                    <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
    </div>
</template>

<script>
import roleService from '../../../api/roleService';
import staffService from '../../../api/staffService';
import adminService from '../../../api/adminService';
import messageBox from '../../../utils/messageBox';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import staffList from '../../../components/popup/_StaffList.vue';

export default({
    components: {
        staffList
    },
    metaInfo: {
        title: 'Thêm/Cập nhật admin',
    },
    data() {
        return {
            role: { read: false, add: false, edit: false, delete: false },
            title: '',
            id: 0,
            submitted: false,
            entity: { staffId: 0, staff: {fullName: ''}, roleId: 0, role: { id:0, roleName: '' }, passwordHash: '', passwordSalt: '', userName: '', password: '', firstName: '', lastName: '', isActive: true, isAdmin: true },
            roles: [],
            staffName: '',
            staffs: [],
        }
    },
    created() {
        this.id = this.$route.params.id;
        if(this.id > 0){
            this.title = 'Cập nhật admin';
            this.getById();
        }
        else{
            this.id = 0;
            this.title = 'Thêm mới admin';
        }
        this.getRoles();
    },
    async mounted() {
        if(this.id == 0){
            this.role = await this.getPageRole("admin", "ADD");
        }
        else{
            this.role = await this.getPageRole("admin", "EDIT");
        }
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        //Staff
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.firstName = item.firstName;
            this.entity.lastName = item.lastName;
            this.$refs.staffList.close();
        },

        getStaffSelected(val){
            this.entity.staffId = val.id;
            this.staffName = val.fullName;
            this.entity.firstName = val.firstName;
            this.entity.lastName = val.lastName;
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            adminService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            adminService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$router.push('/admin');
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            adminService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.entity.confirmPassword = 'password';
                    this.entity.password = 'password';
                    this.staffName = this.entity.fullName;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getRoles(){
            roleService.getAll()
            .then((response) => {
                if(response.statusCode == 200){
                    this.roles = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        back(){
            this.$router.push('/admin');
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
                this.entity.firstName = '';
                this.entity.lastName = '';
            }
        },
    },
    validations: {
        entity: { 
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            roleId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            userName: { required, minLength: minLength(4)},
            password: { required, minLength: minLength(6)},
            confirmPassword: { required, sameAsPassword: sameAs('password') },
            firstName: { required},
            lastName: { required}
        }
    },
})
</script>
