import httpClient  from '../api';
const API_CONTROLLER = 'report-order/';

export default {
    getReportOrderSummary(search) {
        let url = API_CONTROLLER + 'get-order-summary';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                orderId: search.orderId,
                branchId: search.branchId,
                customerId: search.customerId,
                keyword: search.keyword,
                orderName: search.orderName,
            }
        });
    },

    getReportOrderJobFee(search) {
        let url = API_CONTROLLER + 'get-order-job-fee';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                orderId: search.orderId
            }
        });
    },

    getStatisticByOrder(orderId) {
        let url = API_CONTROLLER + 'get-statistic-by-order';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getStatisticByOrderForSales(orderId) {
        let url = API_CONTROLLER + 'get-statistic-by-order-for-sales';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getReportByCont(search) {
        let url = API_CONTROLLER + 'get-report-cont';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderId: search.orderId,
                customerId: search.customerId,
                keyword: search.keyword,
                serial: search.serial,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    exportReportByCont(search) {
        let url = API_CONTROLLER + 'export-report-cont';
        return httpClient.get(url, {
            params: {
                orderId: search.orderId,
                customerId: search.customerId,
                keyword: search.keyword,
                serial: search.serial,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },
}