<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header" style="height: 40px;">
                    <div class="dialog-title">Danh sách dự toán chi phí dự án</div>
                    <div style="display: flex;">
                        <md-button @click="submit()" class="md-raised md-primary">Chọn</md-button>
                        <md-button @click="showDialog = false" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                    </div>
                </div>
                <div class="dialog-search">
                    <md-field>
                        <md-input v-model="keyword" placeholder="Tìm theo mã"></md-input>
                        <md-icon>search</md-icon>
                    </md-field>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th rowspan="2" style="width:60px; vertical-align: middle;">
                                            <md-checkbox v-model="selectAll"></md-checkbox>
                                        </th> 
                                        <th class="left" style="width: 250px;">Số dự toán</th>
                                        <th class="left" style="width: 250px;">Dự toán</th>
                                        <th class="left" style="width: 220px;">Loại dự toán</th>
                                        <th class="right" style="width: 200px;">Số tiền</th>
                                        <th class="left" style="width: 170px;">Người dự toán</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && data.length > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="center">
                                            <md-checkbox v-model="checkedList" :value="index"></md-checkbox>
                                        </td>
                                        <td class="left"><a @click="selected(item)">{{item.estimateCode}}</a></td>
                                        <td>
                                            <span v-if="item.type == 1">Dự toán dự án</span>
                                            <span v-else>Dự toán đơn hàng</span>
                                        </td>
                                        <td>
                                            <span v-if="item.paymentType == 1">Loại tạm ứng</span>
                                            <span v-else-if="item.paymentType == 2">Thanh toán đối tác</span>
                                            <span v-else-if="item.paymentType == 3">Thanh toán nhân viên</span>
                                        </td>
                                        <td class="right">{{ formatNumber(item.estimateAmount) }}</td>
                                        <td class="left">{{item.staff}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import projectAdvancePaymentService from '../../api/projectAdvancePaymentService'; 
    import messageBox from '../../utils/messageBox'; 

    export default {
        data: () => ({
            showDialog: false,
            data: [],
            loadding: false, 
            projectId: 0,
            keyword: '',
            checkedList: [],
            selectAll: false,
        }),

        created() {
        },

        methods: { 
            submit(){
                let list = [];
                for(let i = 0; i < this.checkedList.length; i++){
                    list.push(this.data[this.checkedList[i]]);
                }
                this.$emit('close', list);
                this.showDialog = false;
            },

            selected(item){
                let list = [];
                list.push(item);
                this.$emit('close', list);
                this.showDialog = false;
            },

            open(projectId){
                this.projectId = projectId;
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            refresh() {
                this.getData();
            },

            getData(){
                this.loadding = true;
                projectAdvancePaymentService.getForInvoice(this.projectId, this.keyword).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data; 
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.data.map((item, index) => {
                        this.checkedList.push(index);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
            keyword: function () { 
                this.getData(); 
            }
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 1000px;
        height: 455px;
        max-width: 1000px;
        max-height: 455px;
    }
    .dialog-content {
        height: 325px;
    }
    .dialog-content table{
        height: 320px;
        min-height: 320px !important;
    }
</style>