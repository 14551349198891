<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách cont/kiện hàng</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="submit()" md-menu-trigger class="md-raised md-primary btn-add">Lưu<md-tooltip>Nhận phân bổ cont/kiện hàng</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control">
                                    <md-radio v-model="search.isFixedCost" :value="true">Đã cập nhật</md-radio>
                                    <md-radio v-model="search.isFixedCost" :value="false">Chưa cập nhật</md-radio>
                                </div>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số con't</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 300px); overflow-x: auto; width: 100%;">
                        <table class="data-table-2" style="width: 2220px !important;">
                            <thead>
                                <tr>
                                    <th style="width:60px;">#</th>
                                    <th style="width:60px; vertical-align: middle;">
                                        <md-checkbox v-model="selectAll"></md-checkbox>
                                    </th> 
                                    <th class="right" style="width:150px;">Chi phí cố định</th>
                                    <th class="left" style="width:150px;">Người cập nhật</th>
                                    <th style="width:120px;">Ngày cập nhật</th>
                                    <th class="left" style="width:200px;">Số con't</th> 
                                    <th class="left" style="width:200px;">Mã đơn hàng</th>
                                    <th class="left" style="width:200px;">Tên hàng</th>
                                    <th style="width:150px;">Ngày giao/nhận</th>
                                    <th style="width:100px;">Đơn vị</th> 
                                    <th class="left" style="width:100px;">Số seal</th>
                                    <th style="width:150px;">Loại hình</th> 
                                    <th style="width:150px;">Loại hàng</th>
                                    <th class="left" style="width:450px;">Tuyến đường</th> 
                                </tr>
                            </thead>
                            <tbody v-if="loading == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loading == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loading == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="center">
                                        <md-checkbox @change="change(item)" v-model="checkedList" :value="item.id"></md-checkbox>
                                    </td>
                                    <td class="right">
                                        <div v-if="change(item)" class="group-control">
                                            <number v-model="item.fixedCost" v-bind="currencyF" class="form-control currency"></number> 
                                        </div>
                                        <div v-else>
                                            {{ formatNumber(item.fixedCost) }}
                                        </div>
                                    </td>
                                    <td class="center">
                                        {{item.fixedCostUpdated.fullName}}
                                    </td>
                                    <td class="center">
                                        {{item.fixedCostUpdatedTime}}
                                    </td>
                                    <td class="left">
                                        {{item.serial}}
                                    </td>
                                    <td class="left">
                                        {{item.order.orderCode}}
                                    </td>
                                    <td class="left">
                                        {{item.goods.goodsName}}
                                    </td>
                                    <td class="center">
                                        {{item.dateOfReceipt}}
                                    </td> 
                                    <td class="center">
                                        {{item.unit.unitName}}
                                    </td>
                                    <td class="center">
                                        {{item.seal}}
                                    </td>
                                    <td class="center">
                                        {{ getTypeOfTransport(item.typeOfTransport) }}
                                    </td> 
                                    <td class="center">
                                        {{ getGoodsType(item.goodsTypeId)}}
                                    </td>
                                    <td class="left" style="padding: 0 5px;">
                                        {{ getLocation(item) }}
                                    </td> 
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <contractList ref="contractList" @close="closeContract"/>
        <orderList ref="orderList" @close="closeOrder"/>
    </div>
</template>
<script>
    import orderService from '../../../api/orderService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import contractService from '../../../api/contractService';
    import companyService from '../../../api/companyService';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import contractList from '../../../components/popup/_ContractList.vue';
    import orderList from '../../../components/popup/_OrderList.vue';

    export default {
        components: {
            customerList,
            contractList,
            orderList
        },
        metaInfo: {
            title: 'Danh sách cont/kiện hàng'
        },
        data() {
            return {
                loading: false,
                id: 0,
                typeList: common.typeOfTransport,
                selectAll: false,
                checkedList: [],
                submitted: false,
                assignId: 0,
                submitDistributeId: 0,
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                currencyF: common.currencyF2,
                search: { pageIndex: 1, pageSize: common.pageSize, isFixedCost: false, orderId: 0, contractId: 0, customerId: 0, keyword: '' },
                customerName: '',
                customers: [],
                contractName: '',
                contracts: [],
                orderName: '',
                orders: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
            this.getByAccount();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            change(item){
                let checked = false;
                this.checkedList.findIndex(x => x.id == item.id);
                for(let i = 0; i < this.checkedList.length; i++){
                    if(item.id == this.checkedList[i]){
                        checked = true;
                    }
                }
                return checked;
            },

            submit(){
                this.submitted = true;
                let fixedCostList = [];
                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.data.findIndex(x => x.id == this.checkedList[i]);
                    let item = this.data[selected];
                    fixedCostList.push(item);
                }
                if(fixedCostList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                this.setLoading(true);
                orderService.updateFixedCost(fixedCostList).then((response) => {
                    if(response.statusCode == 200 && response.data > 0){
                        messageBox.showMessage("Cập nhật thành công.");
                        this.checkedList = [];
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning("Có lỗi xảy ra.");
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            refresh(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&orderId=0&customerId=0&contractId=0&orderCode=";
                this.$router.push('/fixed-cost?' + url).catch(()=>{});
                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/fixed-cost?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loading = true;
                orderService.getOrderDetailForFixedCostPaging(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loading = false; });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.submitDistributeId = response.data.id;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getLocation(item) {
                let location = [];
                if(item.placeOfReceiptCont !== null && item.placeOfReceiptCont !== undefined){
                    location.push(item.placeOfReceiptCont.locationName);
                }
                if(item.placeOfReceiptGoods !== null && item.placeOfReceiptGoods !== undefined){
                    location.push(item.placeOfReceiptGoods.locationName);
                }
                if(item.transitDelivery !== null && item.transitDelivery !== undefined){
                    location.push(item.transitDelivery.locationName);
                }
                if(item.transitReceipt !== null && item.transitReceipt !== undefined){
                    location.push(item.transitReceipt.locationName);
                }
                if(item.placeOfDeliveryGoods !== null && item.placeOfDeliveryGoods !== undefined){
                    location.push(item.placeOfDeliveryGoods.locationName);
                }
                if(item.placeOfDeliveryCont !== null && item.placeOfDeliveryCont !== undefined){
                    location.push(item.placeOfDeliveryCont.locationName);
                }

                if(location.length > 0){
                    return location.join([' - ']);
                }
                else{
                    return "";
                }
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Contract
            closeContract(item){
                this.contractName = item.contractCode;
                this.search.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.search.contractId = val.id;
                this.contractName = val.contractCode;
            },

            openContract(){
                this.$refs.contractList.open();
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Order
            closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.data.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.search.contractId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.orderId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.customerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.contractId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.keyword': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.isFixedCost': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
