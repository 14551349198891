<template>
    <md-dialog id="crud-order-estimate" :md-active.sync="showDialog" :md-fullscreen="true" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-if="entity.status == 0 || entity.status == 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary">Gửi duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button v-if="entity.status == 0" v-shortkey="['ctrl', 's']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp">Lưu tạm<md-tooltip>Lưu tạm</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên phụ trách<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                                        <label for="code">Mã dự toán<span class="label-require">(*)</span></label>
                                        <md-input disabled name="code" v-model="entity.estimateCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập mã dự toán</span>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="orderDate">Ngày dự toán</label>
                                        <md-input :disabled="true" name="orderDate" v-model="entity.estimateDate"></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="note">Nội dung</label>
                                        <md-input name="note" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="paymentType">Loại dự toán<span class="label-require">(*)</span></label>
                                        <md-select v-model="entity.paymentType" name="paymentType">
                                            <md-option v-for="item in paymentTypeList" :key="item.id" :value="item.id">{{item.text}}</md-option>
                                        </md-select>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12" v-if="entity.paymentType == 2">
                                    <div class="form-control">
                                        <md-autocomplete v-model="companyName" @md-selected="getThirdPartySelected" :md-options="companies" @md-changed="getThirdParty"  @md-opened="getThirdParty" :class="{'md-invalid': submitted && entity.paymentType == 2 && entity.companyId == null }">
                                            <label>Đối tác thanh toán</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && entity.paymentType == 2 && entity.companyId == null">Vui lòng chọn đơn vị thanh toán</span>
                                        </md-autocomplete>
                                        <md-button @click="openThirdParty()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-4 m-4 c-12" v-if="entity.paymentType == 3">
                                    <div class="form-control">
                                        <md-autocomplete v-model="paymentStaffName" @md-selected="getPaymentStaffSelected" :md-options="paymentStaffs" @md-changed="getPaymentStaffs"  @md-opened="getPaymentStaffs" :class="{'md-invalid': submitted && entity.paymentType == 3 && entity.paymentStaffId == null }">
                                            <label>Thanh toán nhân viên</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && entity.paymentType == 3 && entity.paymentStaffId == null">Vui lòng chọn nhân viên thanh toán</span>
                                        </md-autocomplete>
                                        <md-button @click="openPaymentStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div style="display:flex; width: 100%;">
                                        <div style="width: 25%;">
                                            <md-field>
                                                <label for="note">Tổng tiền</label>
                                                <md-input disabled name="note" :value="formatNumber(entity.pay)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Chi phí</label>
                                                <md-input disabled name="note" :value="formatNumber(entity.payFee)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Chi hộ</label>
                                                <md-input disabled name="note" :value="formatNumber(entity.payOnBehalf)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Dịch vụ</label>
                                                <md-input disabled name="note" :value="formatNumber(entity.payService)" ></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết chi phí">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                            <md-button @click="copy()" class="md-primary"><md-icon>content_copy</md-icon> Sao chép<md-tooltip>Copy dòng</md-tooltip></md-button>
                                                            <md-button @click="getSetingFee()" class="md-primary"><md-icon>settings</md-icon> Tải thiết lập<md-tooltip>Tải thiết lập chi phí</md-tooltip></md-button>
                                                        </div>
                                                        <table class="data-table-2"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th style="width:50px;">#</th> 
                                                                    <th style="width:100px;">Hành động</th>
                                                                    <th style="width:50px; text-align: center;">
                                                                        <md-checkbox v-model="selectAll"></md-checkbox>
                                                                    </th>
                                                                    <th style="width:23%;">Tên phí<span class="label-require">(*)</span></th> 
                                                                    <th class="left" style="width:10%;">Loại<span class="label-require">(*)</span></th> 
                                                                    <th style="width:10%;">Số lượng<span class="label-require">(*)</span></th>
                                                                    <th style="width:15%;">Đơn giá<span class="label-require">(*)</span></th>
                                                                    <th style="width:10%;">VAT</th>
                                                                    <th style="width:15%;">Thành tiền</th>
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loading == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loading == false"> 
                                                                <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="grid-action">
                                                                        <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                    </td>
                                                                    <td style="width:50px; text-align: center; padding-right: 0 !important;"><md-checkbox v-model="checkedList" :value="item.id"></md-checkbox></td>
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.fee.feeName" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm phí</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <md-field>
                                                                            <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                                                <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                            </md-select>
                                                                        </md-field>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <number @change="calSum(item)" v-model="item.quantity" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-bind="numberF" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <number @change="calSum(item)" v-model="item.price" :class="{'is-error': $v.entity.details.$each[index].price.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <number @change="calSumAmount(item)" v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <table class="data-table-2"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th style="width:100px;">#</th> 
                                                                    <th style="width:20%;">Bộ phận/phòng</th> 
                                                                    <th style="width:20%;">Người phê duyệt</th> 
                                                                    <th style="width:20%;">Tiêu đề</th>
                                                                    <th style="width:20%;">Ghi chú</th> 
                                                                    <th style="width:10%;">Thứ tự</th> 
                                                                    <th style="width:150px">Hành động</th> 
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loading == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loading == false"> 
                                                                <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.title" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.note" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                        </div>
                                                                    </td> 
                                                                    <td class="grid-action">
                                                                        <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                    </td> 
                                                                </tr> 
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <paymentStaffList ref="paymentStaffList" @close="closePaymentStaff"/>
                <staffList ref="staffList" @close="closeStaff"/>
                <feeList ref="feeList" @close="closeFee"/>
                <approveList ref="approveList" @close="closeApprove"/>
                <deptList ref="deptList" @close="closeDept"/>
                <companyList ref="companyList" title="công ty" :type="companyType" @close="closeThirdParty"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import paymentStaffList from '../../../components/popup/_StaffList.vue';
import staffList from '../../../components/popup/_StaffList.vue';
import feeList from '../../../components/popup/_FeeList.vue';
import approveList from '../../../components/popup/_StaffList.vue';
import deptList from '../../../components/popup/_DepartmentList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import approveSettingService from '../../../api/approveSettingService';
import staffService from '../../../api/staffService';
import companyService from '../../../api/companyService';
import orderEstimateService from '../../../api/orderEstimateService';
import companyList from '../../../components/popup/_CompanyList.vue';
import settingFeeService from '../../../api/settingFeeService';

export default ({
    components: {
        staffList,
        feeList,
        approveList,
        deptList,
        companyList,
        paymentStaffList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            paymentStaffName: '',
            paymentStaffs: [],
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFee,
            paymentTypeList: common.paymentType,
            companyType: 0,
            order: null,
            companyName: '',
            companies: [],
            selectAll: false,
            checkedList: [],
            entity: { id: 0, orderId: 0, paymentType: 1, orderType: 1, order: { }, details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: '', payService: 0, payOnBehalf: 0, payFee: 0 },
            detail: { id: 'id_' + common.getFakeId(), orderId: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 1, price: 0, vat: 0, amount: 0, note: '' },
            approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, status: 1, ApprovedAt: null },
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        copy(){
            if(this.checkedList.length == 0){
                messageBox.showWarning("Vui lòng chọn dòng sao chép");
                return;
            }
            for(let i = 0; i < this.checkedList.length; i++){
                let selected = this.entity.details.filter(x => x.id == this.checkedList[i])[0];
                let item = { 
                    id: 'id_' + common.getFakeId(), 
                    orderId: this.$route.params.id, 
                    fee: selected.fee, 
                    feeId: selected.feeId, 
                    type: selected.type, 
                    quantity: 0, 
                    price: 0.00, 
                    vat: 0.00, 
                    amount: 0.00, 
                    note: '' 
                };
                this.entity.details.push(item);
            }
        },

        addRow(){
            if(this.entity.details.length > 0){
                let last = this.entity.details.slice(-1).pop();
                let item = { 
                    id: 'id_' + common.getFakeId(), 
                    orderId: this.$route.params.id, 
                    fee: last.fee, 
                    feeId: last.feeId, 
                    type: last.type, 
                    quantity: 1, 
                    price: 0.00, 
                    vat: 0.00, 
                    amount: 0.00, 
                    note: '' 
                };
                this.entity.details.push(item);
            }
            else{
                let item = { id: 'id_' + common.getFakeId(), orderId: this.$route.params.id, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 1, price: 0.00, vat: 0.00, amount: 0.00, note: '' };
                this.entity.details.push(item);
            }
        },
        
        delRow(item){
            const index = this.entity.details.findIndex(x => x.id == item.id);
            this.entity.details.splice(index, 1);
            this.calSummary();
        },

        getSetingFee(){
            this.entity.details = [];
            this.setLoading(true);
            settingFeeService.getSettingFee(this.$route.params.id).then((response) => {
                if(response.statusCode == 200){
                    response.data.map(item => {
                        let obj = { 
                            id: 'id_' + common.getFakeId(), orderId: this.$route.params.id, 
                            feeId: item.feeId, type: item.type, fee: item.fee, 
                            quantity: item.quantity, price: item.price, 
                            vat: item.vat, amount: item.amount, note: '' 
                        };
                        this.entity.details.push(obj);
                    });
                    this.calSummary();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        calSum(item){
            item.amount = common.calAmount(item.quantity, item.price, item.vat);

            this.calSummary();
        },

        calSumAmount(item){
            item.price = common.calPrice(item.quantity, item.vat, item.amount);
            this.calSummary();
        },

        calSummary(){
            this.entity.pay = 0;
            this.entity.payFee = 0;
            this.entity.payService = 0;
            this.entity.payOnBehalf = 0;
            let depositCont = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                this.entity.pay = parseFloat(this.entity.pay) + parseFloat(this.entity.details[i].amount);
                if(parseInt(this.entity.details[i].type) == 1){
                    let amount = parseFloat(this.entity.details[i].price) * parseFloat(this.entity.details[i].quantity);
                    let vat = amount * (parseFloat(this.entity.details[i].vat) / 100);
                    this.entity.payFee = parseFloat(this.entity.payFee) + parseFloat(amount + vat);
                }
                else if(parseInt(this.entity.details[i].type) == 2){
                    let amount = parseFloat(this.entity.details[i].price) * parseFloat(this.entity.details[i].quantity);
                    let vat = amount * (parseFloat(this.entity.details[i].vat) / 100);
                    this.entity.payOnBehalf = parseFloat(this.entity.payOnBehalf) + parseFloat(amount + vat);
                }
                else if(parseInt(this.entity.details[i].type) == 3){
                    let amount = parseFloat(this.entity.details[i].price) * parseFloat(this.entity.details[i].quantity);
                    let vat = amount * (parseFloat(this.entity.details[i].vat) / 100);
                    this.entity.payService = parseFloat(this.entity.payService) + parseFloat(amount + vat);
                }
                else if(parseInt(this.entity.details[i].type) == 7){
                    let amount = parseFloat(this.entity.details[i].price) * parseFloat(this.entity.details[i].quantity);
                    let vat = amount * (parseFloat(this.entity.details[i].vat) / 100);
                    depositCont = parseFloat(depositCont) + parseFloat(amount + vat);
                }
            }
            this.entity.pay = this.entity.payOnBehalf + this.entity.payFee + this.entity.payService + depositCont;
        },

        open(id, order){
            this.submitted = false;
            this.order = order;
            this.id = id;
            this.companyName = '';
            this.paymentStaffName = '';
            if(id > 0){
                this.title = 'Cập nhật dự toán';
                this.getById();
            }
            else{
                this.entity = { id: 0, companyId: null, paymentType: 1, paymentStaffId: null, orderId: 0, order: { }, details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: '', payOnBehalf: 0, payFee: 0, payService: 0 };
                this.title = 'Thêm mới dự toán';
                this.addRow();
                this.getByAccount();
                this.entity.approveList.push(this.approve);
            }
            this.showDialog = true;
        },
        
        close(){
            this.showDialog = false;
        },

        submit(status){
            this.entity.orderId = parseFloat(this.$route.params.id);
            this.submitted = true;
            this.entity.orderType = 1;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.paymentType == 2 && this.entity.companyId == null){
                return;
            }
            if(this.entity.paymentType == 3 && this.entity.paymentStaffId == null){
                return;
            }
            if(this.entity.details.length > 0){
                this.entity.details = this.entity.details.filter(item => !(item.feeId == 0));
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.approveList.length > 0){
                this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId == 0));
                this.entity.approveList.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.details.length <= 0){
                messageBox.showWarning("Vui lòng thêm chi tiết dự toán");
                return;
            }
            else{
                this.entity.details.forEach(function(item, index, self) {
                    self[index].type = parseInt(self[index].type);
                });
            }
            if(this.entity.approveList.length < 1){
                messageBox.showWarning("Vui lòng thêm phê duyệt");
                return;
            }
            this.entity.status = status;
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            orderEstimateService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    this.entity.status = 0;
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            orderEstimateService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    this.entity.status = 0;
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            orderEstimateService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = response.data.staff.fullName;
                    if(this.entity.paymentType == 2 && this.entity.companyId > 0){
                        this.companyName = this.entity.company.companyAlias;
                    }
                    else if(this.entity.paymentType == 3 && this.entity.paymentStaffId > 0){
                        this.paymentStaffName = this.entity.paymentStaff.fullName;
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        closeThirdParty(item){
            this.companyName = item.companyAlias;
            this.entity.companyId = item.id;
            this.$refs.companyList.close();
        },

        getThirdPartySelected(val){
            this.entity.companyId = val.id;
            this.companyName = val.companyAlias;
        },

        openThirdParty(){
            this.$refs.companyList.open();
        },

        getThirdParty(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, companyType: 0, code: searchTerm };
            companyService.getCompanies(search).then((response) => {
                if(response.statusCode == 200){
                    this.companies = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Fee
        closeFee(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].fee = item;
            this.entity.details[selected].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = '';
        },

        openFee(id){
            this.selectedId = id;
            this.$refs.feeList.open();
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.approve.staffId = response.data.id;
                        this.approve.staff = response.data;
                        this.approve.deptId = response.data.department.id;
                        this.approve.dept = response.data.department;
                        this.getApproveSetting();
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Get Approve Setting
        getApproveSetting(){
            approveSettingService.getSetting(37).then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null && response.data.length > 0)
                    {
                        for (let i = 0; i < response.data.length; i++) {
                            let setting = response.data[i];
                            let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairEstimate), orderSort: 1, status: 1, ApprovedAt: null };
                            approve.id = 'id_' + common.getFakeId() + i;
                            approve.staffId = setting.staffId;
                            approve.deptId = setting.deptId;
                            approve.dept.code = setting.department.code;
                            approve.dept.name = setting.department.name;
                            approve.staff.code = setting.staff.code;
                            approve.staff.fullName = setting.staff.fullName;
                            approve.title = setting.title;
                            approve.orderSort = setting.orderSort + 1;
                            
                            this.entity.approveList.push(approve);
                        }
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Approve
        delApproveRow(item){
            const index = this.entity.approveList.findIndex(x => x.id == item.id);
            this.entity.approveList.splice(index, 1);
        },

        addApproveRow(){
            let order = 1;
            if(this.entity.approveList != null){
                order = this.entity.approveList.length + 1;
            }
            let item = { id: 'id_' + common.getFakeId(), status: 1, dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: '', typeCode: String(common.approveType.repairEstimate), orderSort: order, ApprovedAt: null };
            this.entity.approveList.push(item);
        },

        closeApprove(item){
            const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
            this.entity.approveList[selected].staff = item;
            this.entity.approveList[selected].staffId = item.id;
            this.$refs.approveList.close();
            this.selectedId = '';
        },

        openApprove(id){
            this.selectedId = id;
            const index = this.entity.approveList.findIndex(x => x.id == id);
            this.deptId = this.entity.approveList[index].deptId;
            this.$refs.approveList.open(this.deptId);
        },

        //DEPT
        closeDept(item){
            const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
            this.entity.approveList[selected].dept = item;
            this.entity.approveList[selected].title = item.name;
            this.entity.approveList[selected].deptId = item.id;
            if(item.managerId > 0){
                this.entity.approveList[selected].staff = item.manager;
                this.entity.approveList[selected].staffId = item.managerId;
            }
            this.$refs.deptList.close();
            this.selectedId = '';
        },

        openDept(id){
            this.selectedId = id;
            this.$refs.deptList.open();
        },

        getCode(staffId){
            this.setLoading(true);
            orderEstimateService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.estimateCode = response.data.code;
                    this.entity.estimateNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Payment Sales
        closePaymentStaff(item){
            this.paymentStaffName = item.fullName;
            this.entity.paymentStaffId = item.id;
            this.$refs.paymentStaffList.close();
        },

        getPaymentStaffSelected(item){
            this.paymentStaffName = item.fullName;
            this.entity.paymentStaffId = item.id;
        },

        openPaymentStaff(){
            this.$refs.paymentStaffList.open();
        },

        getPaymentStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.paymentStaffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Staff
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.entity.approveList[0].staffId = item.id;
            this.entity.approveList[0].deptId = item.department.id;
            this.entity.approveList[0].dept.code = item.department.code;
            this.entity.approveList[0].dept.name = item.department.name;
            this.entity.approveList[0].staff.code = item.code;
            this.entity.approveList[0].staff.fullName = item.fullName;
            this.entity.approveList[0].title = "Người đề nghị";
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.entity.approveList[0].staffId = item.id;
            this.entity.approveList[0].deptId = item.department.id;
            this.entity.approveList[0].dept.code = item.department.code;
            this.entity.approveList[0].dept.name = item.department.name;
            this.entity.approveList[0].staff.code = item.code;
            this.entity.approveList[0].staff.fullName = item.fullName;
            this.entity.approveList[0].title = "Người đề nghị";
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
    },
    watch: { 
        selectAll: function (val){
            if(val == true){
                this.checkedList = []
                this.entity.details.map((item) => {
                    this.checkedList.push(item.id);
                })
            }
            else{
                this.checkedList = []
            }
        },
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
        'entity.paymentType': function (val) { 
            if(val == 1){
                this.companyName = '';
                this.entity.companyId = null;
                this.paymentStaffName = '';
                this.entity.paymentStaffId = null;
            }
        },
    },
    validations: {
        entity: {
            estimateCode: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            estimateDate: { required },
            approveList: {
                $each: {
                    deptId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    staffId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            },
            details: {
                $each: {
                    feeId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    quantity: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    price: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    }
                }
            }
        }
    }
})
</script>

<style>
    #crud-order-estimate .md-dialog-container {
        max-width: 95% !important;
    }
</style>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        max-width: 100%;
        width: 100%;
    }
    .page-content {
        min-height: 600px;
    }
</style>