<template>
    <div>
        <div class="row">
            <div class="col l-12 m-12 c-12">
                <div class="tool-bar">
                    <md-button @click="addOrderItem()" class="md-primary"><md-icon>add_card</md-icon> Thêm hàng hóa<md-tooltip>Thêm hàng vận chuyển</md-tooltip></md-button>
                </div>
                <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 326px); overflow-x: auto; width: 100%;">
                    <table class="data-table-2" style="width: 100% !important;"> 
                        <thead> 
                            <tr> 
                                <th style="width:60px;">#</th> 
                                <th style="width:10%;">Hành động</th>
                                <th class="left" style="width:15%;">PkgNo</th>
                                <th class="left" style="width:18%;">Tên hàng</th>
                                <th style="width:15%;">Ngày giao/nhận</th>
                                <th class="left" style="width:10%;">Đơn vị</th> 
                                <th class="left" style="width:15%;">Loại hình</th> 
                                <th class="left" style="width:15%;">Loại hàng</th>
                            </tr>
                        </thead> 
                        <tbody v-if="loadding == true" style="height: 100px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-else-if="loadding == false && entity.details.length == 0" style="height: 100px;"> 
                            <tr><td colspan="100"><div style="display: flex; justify-content: center; color: red">Không có bản ghi</div></td></tr>
                        </tbody>
                        <tbody v-else-if="loadding == false && entity.details.length > 0"> 
                            <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td>
                                <td class="action" style="text-align: center">
                                    <md-button @click="editOrderItem(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td> 
                                <td class="left">
                                    {{item.pkgNo}}
                                </td>
                                <td class="left">
                                    {{item.goods.goodsName}}
                                </td>
                                <td class="center">
                                    {{item.dateOfDelivery}}
                                </td> 
                                <td class="left">
                                    {{item.unit.unitName}}
                                </td>
                                <td class="left">
                                    {{ getTypeOfTransport(item.typeOfTransport) }}
                                </td> 
                                <td class="left">
                                    {{ getGoodsTypeHeavy(item.goodsTypeId) }}
                                </td>
                            </tr> 
                        </tbody> 
                    </table>
                </div>
            </div>
        </div>
        <addForm ref="addForm" @close="closeOrderItem" />
    </div>
</template>

<script>
    import orderHeavyService from '../../../api/orderHeavyService';
    import { mapActions } from 'vuex';
    import messageBox from '../../../utils/messageBox';
    import addForm from './AddItem.vue';

    export default ({
        components: {
            addForm,
        },
        data() {
            return {
                loadding: false,
                orderId: 0,
                entity: { details: [] }
            }
        },
        created(){
            this.orderId = this.$route.params.id;
            this.getById();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            closeOrderItem(){
                this.getById();
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                orderHeavyService.deleteDetail(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            addOrderItem(){
                this.$refs.addForm.open(null);
            },

            editOrderItem(item){
                this.$refs.addForm.open(item);
            },

            getById(){
                this.setLoading(true);
                orderHeavyService.getById(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        }
    })
</script>