<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.approveType.$error }">
                                <label for="approveType">Loại</label>
                                <md-select v-model="entity.approveType" name="approveType" id="approveType">
                                    <md-option value="0">Chọn loại</md-option>
                                    <md-option v-for="g in groups" :key="g.value" :value="g.value">{{g.text}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.approveType.isSelected">Vui lòng chọn loại</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.title.$error }">
                                <label for="title">Tiêu đề</label>
                                <md-input name="title" v-model="entity.title"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.title.required">Vui lòng nhập tiêu đề</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="radio-group">
                                <label>Trạng thái</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isActive" :value="true">Kích hoạt</md-radio>
                                    <md-radio v-model="entity.isActive" :value="false">Lưu tạm</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="tool-bar">
                                <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                            </div>
                            <table class="data-table-2"> 
                                <thead> 
                                    <tr> 
                                        <th style="width:100px;">#</th> 
                                        <th style="width:25%;">Bộ phận/phòng</th> 
                                        <th style="width:25%;">Người phê duyệt</th> 
                                        <th style="width:30%;">Tiêu đề</th> 
                                        <th style="width:10%;">Thứ tự</th> 
                                        <th style="width:150px">Hành động</th> 
                                    </tr> 
                                </thead> 
                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false"> 
                                    <tr v-for="(item, index) in entity.approveTemps" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                        <td class="center">{{index + 1}}</td>
                                        <td class="center">
                                            <div class="grid-control">
                                                <input v-model="item.department.name" class="form-control" type="text">
                                                <md-button @click="openDept(item.id)" class="md-icon-button">
                                                    <md-icon>manage_search</md-icon>
                                                    <md-tooltip>Tìm bộ phận</md-tooltip>
                                                </md-button>
                                            </div>
                                        </td> 
                                        <td class="center">
                                            <div class="grid-control">
                                                <input v-model="item.staff.fullName" class="form-control" type="text">
                                                <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                    <md-icon>manage_search</md-icon>
                                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                                </md-button>
                                            </div>
                                        </td> 
                                        <td class="center">
                                            <div class="group-control">
                                                <input v-model="item.title" class="form-control" type="text">
                                            </div>
                                        </td>
                                        <td class="center">
                                            <div class="group-control">
                                                <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                            </div>
                                        </td> 
                                        <td class="grid-action">
                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                        </td> 
                                    </tr> 
                                </tbody> 
                            </table> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
   </div>
</template>
<script>
    import approveSettingService from '../../../api/approveSettingService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';

    export default ({
        components: {
            staffList,
            deptList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thiết lập phê duyệt'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               loadding: false,
               deptId: 0,
               entity: { id: 0, title: '', approveTemps: [], approveType: 0, isActive: true},
               groups: [],
               approveTemp: { id: 'id_' + common.getFakeId(), department: { code: '', name: '' }, staff: { id: 0, fullName: '' }, settingId: 0, deptId: 0, staffId: 0, title: '', orderSort: 1 }
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật thiết lập phê duyệt';
               this.getById();
            }
            else{
               this.title = 'Thêm mới thiết lập phê duyệt';
               this.entity.approveTemps.push(this.approveTemp);
            }
            this.getGroups();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Dept
            delRow(item){
                const index = this.entity.approveTemps.findIndex(x => x.id == item.id);
                this.entity.approveTemps.splice(index, 1);
            },

            addRow(){
                let order = 1;
                if(this.entity.approveTemps != null){
                    order = this.entity.approveTemps.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), department: { code: '', name: '' }, staff: { id: 0, fullName: '' }, settingId: 0, deptId: 0, staffId: 0, title: '', orderSort: order };
                this.entity.approveTemps.push(item);
            },

            closeDept(item){
                const selected = this.entity.approveTemps.findIndex(x => x.id == this.selectedId);
                this.entity.approveTemps[selected].department = item;
                this.entity.approveTemps[selected].title = item.name;
                this.entity.approveTemps[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveTemps[selected].staff = item.manager;
                    this.entity.approveTemps[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Approve
            delApproveRow(item){
                const index = this.entity.approveTemps.findIndex(x => x.id == item.id);
                this.entity.approveTemps.splice(index, 1);
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveTemps != null){
                    order = this.entity.approveTemps.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), staff: { fullName: '', code: '' }, staffId: 0, title: '', typeCode: String(common.approveType.repairEstimate), orderSort: order, isApproved: false, ApprovedAt: null };
                this.entity.approveTemps.push(item);
            },

            closeApprove(item){
                const selected = this.entity.approveTemps.findIndex(x => x.id == this.selectedId);
                this.entity.approveTemps[selected].staff = item;
                this.entity.approveTemps[selected].staffId = item.id;
                this.$refs.staffList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveTemps.findIndex(x => x.id == id);
                this.deptId = this.entity.approveTemps[index].deptId;
                this.$refs.staffList.open(this.deptId);
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.approveTemps.length > 0){
                    this.entity.approveTemps = this.entity.approveTemps.filter(item => !(item.deptId == 0));
                    this.entity.approveTemps.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveTemps == null || this.entity.approveTemps.length == 0){
                    messageBox.showWarning('Vui lòng chọn danh sách phê duyệt');
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                approveSettingService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/approve-setting');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                approveSettingService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/approve-setting');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                approveSettingService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/approve-setting');
            },
            getGroups(){
                this.groups.push({ value: 37, text: 'Phê duyệt dự toán làm hàng' });
                this.groups.push({ value: 38, text: 'Phê duyệt tạm ứng làm hàng' });
                this.groups.push({ value: 39, text: 'Phê duyệt quyết toán làm hàng' });
            },
        },
        validations: {
            entity: {
                title: { required },
                approveType: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
            }
        }
     })

</script>
