<template>
    <div class="page-content">
        <div class="view-body">
            <div class="tabs">
                <div :class="{'active' : tab == 'info'}" class="tab-item"><a @click="onTab('info')">Thông tin chung</a></div>
                <div :class="{'active' : tab == 'budget'}" class="tab-item"><a @click="onTab('budget')">Dự trù kinh phí</a></div>
                <div :class="{'active' : tab == 'estimate'}" class="tab-item"><a @click="onTab('estimate')">Dự toán dự án</a></div>
                <div :class="{'active' : tab == 'order'}" class="tab-item"><a @click="onTab('order')">Đơn hàng</a></div>
                <div :class="{'active' : tab == 'advance-payment'}" class="tab-item"><a @click="onTab('advance-payment')">Tạm ứng</a></div>
                <div :class="{'active' : tab == 'invoice'}" class="tab-item"><a @click="onTab('invoice')">Quyết toán</a></div>
                <div :class="{'active' : tab == 'revenue'}" class="tab-item"><a @click="onTab('revenue')">Thu KH</a></div>
                <div :class="{'active' : tab == 'summary'}" class="tab-item"><a @click="onTab('summary')">Tổng hợp chi phí</a></div>
            </div>
            <div class="content-tab">
                <div v-if="tab == 'info'">
                    <Info />
                </div>
                <div v-else-if="tab == 'budget'">
                    <budgetList :project="entity" />
                </div>
                <div v-else-if="tab == 'estimate'">
                    <estimateList :project="entity" />
                </div>
                <div v-else-if="tab == 'order'">
                    <orderList :project="entity" />
                </div>
                <div v-else-if="tab == 'advance-payment'">
                    <advancePaymentList :project="entity" />
                </div>
                <div v-else-if="tab == 'invoice'">
                    <invoiceList :project="entity" />
                </div>
                <div v-else-if="tab == 'revenue'">
                    <revenueList :project="entity"/>
                </div>
                <div v-else-if="tab == 'summary'">
                    <summaryForm :project="entity" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import messageBox from '../../../utils/messageBox';
    import Info from './Info.vue';
    import estimateList from './EstimateList.vue'
    import budgetList from './BudgetList.vue'
    import orderList from './OrderList.vue'
    import advancePaymentList from './AdvancePaymentList.vue'
    import invoiceList from './InvoiceList.vue'
    import revenueList from './RevenueList.vue'
    import summaryForm from './Summary.vue'

    export default ({
        components: {
            Info,
            summaryForm,
            orderList,
            estimateList,
            budgetList,
            advancePaymentList,
            invoiceList,
            revenueList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật dự án'
        },
        data() {
            return {
                title: '',
                tab: 'info',
                entity: { }
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.$route.query.tab !== undefined){
                this.tab = this.$route.query.tab;
            }
        },
        methods: {
            onTab(tabName){
                this.id = this.$route.params.id;
                if(tabName === 'info'){
                    this.tab = tabName;
                    this.$router.push('/project-heavy/crud/' + this.id + '?tab=' + tabName);
                }
                else{
                    if(this.id == 0 || this.id == undefined){
                        messageBox.showWarning("Vui lòng thêm thông tin chung dự án");
                    }
                    else{
                        this.tab = tabName;
                        this.$router.push('/project-heavy/crud/' + this.id + '?tab=' + tabName);
                    }
                }
            },
        },
    })

</script>
