<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <div class="dialog-content">
                <div class="row">
                    <div class="col l-12 m-12 c-12">
                        <div class="row">
                            <div class="col l-6 m-6 c-12">
                                <div class="form-control">
                                    <md-autocomplete v-model="categoryName" @md-selected="getCategorySelected" :md-options="categories" @md-changed="getCategories"  @md-opened="getCategories" :class="{'md-invalid': submitted && $v.entity.categoryId.$error }">
                                        <label>Nhóm vật tư</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.categoryName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                        <span class="md-error" v-if="submitted && !$v.entity.categoryId.required">Vui lòng chọn nhóm vật tư</span>
                                    </md-autocomplete>
                                    <md-button @click="openCategory()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="form-control">
                                    <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits" :class="{'md-invalid': submitted && $v.entity.unitId.$error }">
                                        <label>Đơn vị tính</label>
                                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                            <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                        </template>
                                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                            "{{ term }}" Không tìm thấy!
                                        </template>
                                        <span class="md-error" v-if="submitted && !$v.entity.unitId.isSelected">Vui lòng chọn đơn vị tính</span>
                                    </md-autocomplete>
                                    <md-button @click="openUnit()" class="md-icon-button">
                                        <md-icon>manage_search</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col l-6 m-6 c-12">
                                <md-field :class="{'md-invalid': submitted && $v.entity.productCode.$error }">
                                    <label for="code">Mã vật tư</label>
                                    <md-input disabled name="code" v-model="entity.productCode"></md-input>
                                    <span class="md-error" v-if="submitted && !$v.entity.productCode.required">Vui lòng nhập mã vật tư</span>
                                </md-field>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <md-field>
                                    <label for="pplCode">Mã kế toán</label>
                                    <md-input name="pplCode" v-model="entity.pplCode"></md-input>
                                </md-field>
                            </div>
                        </div>
                        <md-field :class="{'md-invalid': submitted && $v.entity.productName.$error }">
                            <label for="productName">Tên vật tư</label>
                            <md-input name="productName" v-model="entity.productName"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.productName.required">Vui lòng nhập tên vật tư</span>
                        </md-field>

                        <md-field class="number-control">
                            <div>Đơn giá:</div>
                            <number name="salarybase" v-model="entity.price"></number> 
                        </md-field>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <md-field v-if="entity.typeCode == '1'">
                            <label for="tireStatus">Tình trạng(%)</label>
                            <md-input name="tireStatus" v-model="entity.tireStatus"></md-input>
                            <span class="md-suffix">%</span>
                        </md-field>

                        <md-field v-if="entity.typeCode == '1'">
                            <label for="kmReplace">Km thay thế(km)</label>
                            <md-input name="kmReplace" v-model="entity.kmReplace"></md-input>
                            <span class="md-suffix">KM</span>
                        </md-field>
                    </div>
                </div>
            </div>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
            <unitList ref="unitList" @close="closeUnit"/>
            <categoryList ref="categoryList" :type="3" title="nhóm vật tư" @close="closeCategory"/>
        </md-content>
    </md-dialog>
</template>

<script>
    import productService from '../../../api/productService';
    import categoryService from '../../../api/categoryService';
    import unitService from '../../../api/productUnitService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import unitList from '../../../components/popup/_ProductUnitList.vue'
    import categoryList from '../../../components/popup/_CategoryList.vue';

    export default ({
        components: {
            unitList,
            categoryList
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               group: common.groupCode.product,
               entity: { id: 0, productCode: '', price: 0, pplCode: '', productName: '', categoryId: 0, unitId: 0, isDeleted: false, isActive: true, tireStatus: 0, kmReplate: 0 },
               units: [],
               unitName: '',
               categories: [],
               categoryName: '',
               showDialog: false,
               saveAndCreate: false
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                await this.getCategories();
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới vật tư';
                    this.categoryName = "";
                    this.unitName = "";
                    this.entity = { id: 0, productCode: '', price: 0, pplCode: '', productName: '', categoryId: 0, unitId: 0, isDeleted: false, isActive: true, tireStatus: 0, kmReplate: 0 };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật vật tư';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
                this.setLoading(false);
            },

            getCode(categoryId){
                this.setLoading(true);
                productService.getCode(categoryId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.productCode = response.data.code;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Category
            closeCategory(item){
                this.categoryName = item.categoryName;
                this.entity.categoryId = item.id;
                this.getCode(this.entity.categoryId);
                this.$refs.categoryList.close();
            },

            getCategorySelected(val){
                this.entity.categoryId = val.id;
                this.categoryName = val.categoryName;
                this.getCode(this.entity.categoryId);
            },

            openCategory(){
                this.$refs.categoryList.open();
            },

            getCategories(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 3, code: searchTerm };
                categoryService.getCategories(search).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Unit
            closeUnit(item){
                this.unitName = item.unitName;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
            },

            openUnit(){
                this.$refs.unitList.open();
            },

            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword:  searchTerm };
                unitService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                if(this.entity.price == null || this.entity.price == ''){
                    this.entity.price = 0;
                }
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                productService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                productService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                productService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.categoryName = this.entity.category.categoryName;
                        this.unitName = this.entity.unit.unitName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/product');
            },
        },
        watch: { 
            unitName: function (val) { 
                if(val == ''){
                    this.entity.unitId = 0;
                }
            },
            categoryName: function (val) { 
                if(val == ''){
                    this.entity.categoryId = 0;
                }
            }
        },
        validations: {
            entity: {
                unitId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                categoryId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                productCode: { required },
                productName: { required }
            }
       }
    })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 600px;
        height: 400px;
        max-width: 600px;
    }
    .md-scrollbar {
        height: 300px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>