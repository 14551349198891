<template>
    <div>
        <div class="row">
            <div class="col l-12 m-12 c-12">
                <div class="tool-bar">
                    <md-button @click="addOrderItem()" class="md-primary"><md-icon>add_card</md-icon>Thêm hàng hóa<md-tooltip>Thêm hàng vận chuyển</md-tooltip></md-button>
                    <md-button @click="delAllItem()" class="md-primary" style="color: red;"><md-icon style="color: red;">delete_outline</md-icon>Xóa tất cả<md-tooltip>Xóa hàng vận chuyển</md-tooltip></md-button>
                    <md-button @click="showDialogTakeCont = true" class="md-primary"><md-icon>minor_crash</md-icon> Cập nhật ngày lấy cont<md-tooltip>Cập nhật ngày lấy cont ra khỏi cảng</md-tooltip></md-button>
                </div>
                <div id="table-scroll" class="table-fixed" style="height: calc(100vh - 220px); overflow-x: auto; width: 100%;">
                    <table class="data-table" style="width: 1870px !important;"> 
                        <thead> 
                            <tr> 
                                <th style="width:60px;">#</th> 
                                <th style="width:50px; text-align: center; background-color: #eeeeee;">
                                    <md-checkbox v-model="selectAll"></md-checkbox>
                                </th>
                                <th style="width:80px;">Hành động</th>
                                <th class="left" style="width:200px;">Tên hàng</th>
                                <th style="width:150px;">Ngày giao/nhận</th>
                                <th style="width:150px;">Ngày lấy cont</th>
                                <th style="width:100px;">Đơn vị</th> 
                                <th class="left" style="width:180px;">Số con't</th> 
                                <th class="left" style="width:100px;">Số seal</th>
                                <th style="width:150px;">Loại hình</th> 
                                <th style="width:150px;">Loại hàng</th>
                                <th style="width:500px;">Tuyến đường</th> 
                            </tr>
                        </thead> 
                        <tbody v-if="loadding == true" style="height: 100px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-else-if="loadding == false && entity.details.length == 0" style="height: 100px;"> 
                            <tr><td colspan="100"><div style="display: flex; justify-content: center; color: red">Không có bản ghi</div></td></tr>
                        </tbody>
                        <tbody v-else-if="loadding == false && entity.details.length > 0"> 
                            <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td>
                                <td class="center"><md-checkbox v-model="checkedList" :value="item.id"></md-checkbox></td>
                                <td class="action" style="text-align: center">
                                    <md-button @click="editOrderItem(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button @click="openUpload(item)" class="md-fab md-mini md-upload">
                                        <i class="uil uil-upload"></i>
                                        <md-tooltip>Upload file</md-tooltip>
                                    </md-button>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td> 
                                <td class="left">
                                    {{item.goods.goodsName}}
                                </td>
                                <td class="center">
                                    {{item.dateOfReceipt}}
                                </td> 
                                <td class="center">
                                    {{item.dateTakeCont}}
                                </td> 
                                <td class="center">
                                    {{item.unit.unitName}}
                                </td>
                                <td class="left">
                                    {{item.serial}}
                                </td>
                                <td class="left">
                                    {{item.seal}}
                                </td>
                                <td class="center">
                                    {{ getTypeOfTransport(item.typeOfTransport) }}
                                </td> 
                                <td class="center">
                                    {{ getGoodsType(item.goodsTypeId) }}
                                </td>
                                <td class="left" style="padding: 0 5px; text-wrap: wrap;">
                                    {{  getLocation(item)  }}
                                </td>
                            </tr> 
                        </tbody> 
                    </table>
                </div>
            </div>
        </div>
        <addForm ref="addForm" @close="closeOrderItem" />
        <editForm ref="editForm" @close="closeEditItem" />
        <uploadItem ref="uploadItem" @close="getById" />
        
        <md-dialog :md-active.sync="showDialogTakeCont" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Cập nhật ngày lấy cont ra khỏi cảng</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 450px;" class="row">
                    <div class="col l-12 m-12 c-12">
                        <div class="form-control">
                            <md-datepicker v-model="dateTakeCont" md-immediately :md-model-type="String" :class="{'md-invalid': submittedTakeCont && dateTakeCont == '' }">
                                <label>Ngày lấy cont<span class="label-require">(*)</span></label>
                                <span class="md-error" v-if="submittedTakeCont && dateTakeCont == ''">Vui lòng chọn ngày lấy cont ra khỏi cảng</span>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submitDateTakeCont()">Lưu</md-button>
                <md-button class="md-primary" @click="showDialogTakeCont = false">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import messageBox from '../../../utils/messageBox';
    import orderService from '../../../api/orderService';
    import addForm from './AddItem.vue';
    import editForm from './EditItem.vue';
    import uploadItem from './UploadItem.vue';

    export default ({
        components: {
            addForm,
            editForm,
            uploadItem
        },
        data() {
            return {
                submitted: false,
                loadding: false,
                selectAll: false,
                checkedList: [],
                showDialogTakeCont: false,
                orderId: 0,
                entity: { details: [] }
            }
        },
        created(){
            this.orderId = this.$route.params.id;
            this.getById();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            openUpload(item) {
                this.$refs.uploadItem.open(item);
            },

            delAllItem(){
                this.setLoading(true);
                let ids = [];
                this.entity.details.map(item => {
                    ids.push(item.id);
                });
                orderService.deleteDetails(ids).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            addOrderItem(){
                this.$refs.addForm.open(null, this.entity.ownerId, this.entity.typeOfTransport);
            },

            closeOrderItem(){
                this.getById();
            },

            editOrderItem(item){
                this.$refs.editForm.open(item, this.entity.ownerId);
            },

            closeEditItem(){
                this.getById();
            },

            submitDateTakeCont(){
                this.submittedTakeCont = true;
                if (this.dateTakeCont == '') {
                    return;
                }
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.entity.details.findIndex(x => x.id == this.checkedList[i]);
                    this.entity.details[selected].dateTakeCont = this.dateTakeCont;
                }
                orderService.updateDateTakeCont(this.entity.details).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();

                this.showDialogTakeCont = false;
            },

            getById(){
                this.setLoading(true);
                orderService.getById(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getLocation(item) {
                let location = [];
                if(item.placeOfReceiptCont !== null && item.placeOfReceiptCont !== undefined){
                    location.push(item.placeOfReceiptCont.locationName);
                }
                if(item.placeOfReceiptGoods !== null && item.placeOfReceiptGoods !== undefined){
                    location.push(item.placeOfReceiptGoods.locationName);
                }
                if(item.transitDelivery !== null && item.transitDelivery !== undefined){
                    location.push(item.transitDelivery.locationName);
                }
                if(item.transitReceipt !== null && item.transitReceipt !== undefined){
                    location.push(item.transitReceipt.locationName);
                }
                if(item.placeOfDeliveryGoods !== null && item.placeOfDeliveryGoods !== undefined){
                    location.push(item.placeOfDeliveryGoods.locationName);
                }
                if(item.placeOfDeliveryCont !== null && item.placeOfDeliveryCont !== undefined){
                    location.push(item.placeOfDeliveryCont.locationName);
                }

                if(location.length > 0){
                    return location.join([' - ']);
                }
                else{
                    return "";
                }
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                let ids = [];
                ids.push(id);
                orderService.deleteDetails(ids).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: {
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.entity.details.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
        }
    })
</script>