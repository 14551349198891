<template>
    <div class="page-content">
        <div class="grid">
            <div class="page-list-body">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Phải thu khách hàng</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <div class="page-list-action">
                            <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                        </div>
                    </div>
                </div>
                <div class="form-body">
                    <div id="print-view" style="width: 1000px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                        <div style="height: 100px; display: flex; justify-content: space-between;">
                            <div style="border-bottom: 1px solid #999; 
                                width: 25%; display: flex;align-items: center; justify-content: center;
                                border-top: 1px solid #999;border-left: 1px solid #999;">
                                <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                            </div>
                            <div style="border-bottom: 1px solid #999;
                                border-left: 1px solid #999;
                                border-top: 1px solid #999;
                                border-right: 1px solid #999;
                                width: 45%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                text-transform: uppercase;
                                font-weight: bold;">
                                <div style="font-size: 18px;">BẢNG KÊ THU KHÁCH HÀNG</div>
                            </div>
                            <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                <div><span style="font-weight: bold;">Số: </span><span>{{entity.revenueCode}}</span></div>
                                <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.revenueDate}}</span></div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; padding: 10px;">
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Khách hàng:</div>
                                <div style="font-size: 16px;">{{ entity.customer.companyName  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Địa chỉ:</div>
                                <div style="font-size: 16px;">{{ entity.customer.address  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Mã số thuế:</div>
                                <div style="font-size: 16px;">{{ entity.customer.taxCode  }}</div>
                            </div>
                            
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Nội dung:</div>
                                <div style="font-size: 16px;">{{ entity.note  }}</div>
                            </div>
                        </div>
                        
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12" style="padding:20px 0 50px 0;">
                                <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                    <thead>
                                        <tr>
                                            <th style="width:50px; padding: 5px 10px; border: 1px solid #999;">Stt</th>
                                            <th style="text-align: left; width:200px; padding: 5px 10px; border: 1px solid #999;">Tên phí</th>
                                            <th style="text-align: left; width:150px; padding: 5px 10px; border: 1px solid #999;">Loại</th>
                                            <th style="text-align: center; width:100px; padding: 5px 10px; border: 1px solid #999;">Số lượng</th>
                                            <th style="text-align: right; width:100px; padding: 5px 10px; border: 1px solid #999;">Đơn giá</th>
                                            <th style="text-align: center; width:150px; padding: 5px 10px; border: 1px solid #999;">VAT(%)</th>
                                            <th style="text-align: right; width:100px; padding: 5px 10px; border: 1px solid #999;">Thành tiền</th>
                                            <th style="text-align: left; width:150px; padding: 5px 10px; border: 1px solid #999;">Ghi chú</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in entity.details"  :key="'detail-' + index">
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{index + 2}}</td>
                                            <td style="padding-left: 30px; padding: 5px 10px; border: 1px solid #999;">{{item.fee.feeName}}</td>
                                            <td style="text-align: left; padding: 5px 10px; border: 1px solid #999;">{{ getFeeType(item.type)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{item.quantity}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.price)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.vat)}}%</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.amount)}}</td>
                                            <td style="text-align: left; padding: 5px 10px; border: 1px solid #999;">{{ item.note }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td colspan="6" style="text-align: right; padding: 5px 10px; border: 1px solid #999;"><b>Tổng cộng</b></td>
                                            <td style="text-align: right; font-weight: bold; border: 1px solid #999;">{{ formatNumber(entity.amount) }}</td>
                                            <td style="text-align: right; font-weight: bold; border: 1px solid #999;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div style="display: flex; padding: 10px; justify-content: space-between;">
                            <div style="display: flex; padding: 5px 0; width: 50%; justify-content: center;">
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; padding-bottom: 100px;">{{ entity.customer.companyName }}</div>
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; padding-bottom: 50px;"></div>
                            </div>
                            <div style="display: flex; flex-direction: column; padding: 5px 0; width: 50%; justify-content: center;">
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; padding-bottom: 100px; text-align: center;">{{ ppl.companyName }}</div>
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; text-align: center; padding-bottom: 50px;">{{ entity.staff.fullName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import heavyRevenueService from '../../../api/heavyRevenueService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import { APP_CONFIG } from '@/utils/constants';

    export default ({
        metaInfo: {
            title: 'Phải thu khách hàng'
        },
        data() {
            return {
               title: 'Phải thu khách hàng',
               id: 0,
               submitted: false,
               loadding: false,
               details: [],
               entity: { id: 0, staff: { fullName: '' }, customer: { companyName: '' }, details: [] },
               transportCost: { amount: 0 },
               ppl: {}
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getOwner();
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/heavy-revenue/export-revenue?id=" + this.id;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            print () {
                this.$htmlToPaper('print-view');
            },

            getOwner(){
                this.setLoading(true);
                companyService.getOwner().then((response) => {
                    if(response.statusCode == 200){
                        this.ppl = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                heavyRevenueService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1200px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 18px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 200px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 14px;
    }
</style>
