import httpClient  from '../api';
const API_CONTROLLER = 'object-file/';

export default {
    getAll() {
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url);
    },

    delete(fileId, objectType){
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                fileId: fileId,
                objectType: objectType
            }
        });
    },

    getById(id) {
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    add(data) {
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) {
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },
}