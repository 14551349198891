<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action">
                                <md-button @click="submit()" class="md-raised md-primary">Lưu</md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên phụ trách<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.revenueCode.$error }">
                                        <label for="code">Mã dự toán<span class="label-require">(*)</span></label>
                                        <md-input disabled name="code" v-model="entity.revenueCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.revenueCode.required">Vui lòng nhập mã dự toán</span>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="revenueDate">Ngày lập</label>
                                        <md-input :disabled="true" name="revenueDate" v-model="entity.revenueDate"></md-input>
                                    </md-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-8 m-8 c-12">
                                    <md-field>
                                        <label for="note">Nội dung</label>
                                        <md-input name="note" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <div style="display:flex; width: 100%;">
                                        <div style="width: 100%;">
                                            <md-field>
                                                <label for="amount">Tổng tiền</label>
                                                <md-input disabled name="amount" :value="formatNumber(entity.amount)" ></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="tool-bar">
                                        <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                        <md-button @click="getProjectFeeOnbehalf()" class="md-primary"><i style="padding-right: 5px;" class="uil uil-usd-circle"></i> Lấy chi phí<md-tooltip>Lấy phí chi hộ và dịch vụ</md-tooltip></md-button>
                                    </div>
                                    <table class="data-table-2"> 
                                        <thead> 
                                            <tr> 
                                                <th style="width:60px;">#</th> 
                                                <th style="width:100px;">Hành động</th>
                                                <th style="width:25%;">Tên phí<span class="label-require">(*)</span></th> 
                                                <th class="left" style="width:15%;">Loại<span class="label-require">(*)</span></th> 
                                                <th style="width:10%;">Số lượng<span class="label-require">(*)</span></th>
                                                <th style="width:10%;">Đơn giá<span class="label-require">(*)</span></th>
                                                <th style="width:10%;">VAT</th>
                                                <th style="width:10%;">Thành tiền</th>
                                                <th style="width:10%;">Ghi chú</th>
                                            </tr> 
                                        </thead> 
                                        <tbody v-if="loading == true" style="height: 100px;"> 
                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                        </tbody>
                                        <tbody v-if="loading == false"> 
                                            <tr v-for="(item, index) in entity.details" :key="'fee-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                <td class="center">{{index + 1}}</td>
                                                <td class="grid-action">
                                                    <a @click="delRow(index)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                </td>
                                                <td class="center">
                                                    <div class="grid-control">
                                                        <input v-model="item.fee.feeName" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                        <md-button @click="openFee(index)" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm phí</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </td> 
                                                <td class="center">
                                                    <md-field>
                                                        <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                            <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                        </md-select>
                                                    </md-field>
                                                </td>
                                                <td class="center">
                                                    <div class="group-control">
                                                        <number @change="calSum(item)" v-model="item.quantity" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-bind="numberF" class="form-control currency"></number> 
                                                    </div>
                                                </td>
                                                <td class="center">
                                                    <div class="group-control">
                                                        <number @change="calSum(item)" v-model="item.price" :class="{'is-error': $v.entity.details.$each[index].price.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                    </div>
                                                </td>
                                                <td class="center">
                                                    <div class="group-control">
                                                        <number @change="calSum(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                    </div>
                                                </td>
                                                <td class="center">
                                                    <div class="group-control">
                                                        <number @change="calAmount(item)" v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                    </div>
                                                </td>
                                                <td class="center">
                                                    <div class="group-control">
                                                        <input v-model="item.note" class="form-control" type="text">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody> 
                                    </table> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeStaff"/>
                <feeList ref="feeList" @close="closeFee"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import staffList from '../../../components/popup/_StaffList.vue';
import feeList from '../../../components/popup/_FeeList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import staffService from '../../../api/staffService';
import heavyRevenueService from '../../../api/heavyRevenueService';
import projectService from '../../../api/projectService';
import projectEstimateService from '../../../api/projectEstimateService';

export default ({
    components: {
        staffList,
        feeList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFeeHeavy,
            entity: { staffId: 0, projectId: 0, orderId: null, revenueCode: '', revenueDate: '', status: 0, note: '' },
            detail: { id: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 1, price: 0, vat: 0, amount: 0, note: '' },
        }
    },
    created(){
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        getProjectFeeOnbehalf(){
            this.setLoading(true);
            projectEstimateService.getProjectFeeOnbehalf(this.projectId).then((response) => {
                if(response.statusCode == 200){
                    if(response.data.length > 0){
                        this.entity.details = [];
                        response.data.map(item => {
                            let obj = {
                                id: 0,
                                fee: item.fee,
                                feeId: item.feeId, 
                                type: item.type,
                                quantity: item.quantity,
                                price: item.estimatePrice,
                                vat: item.estimateVat,
                                amount: item.estimateAmount,
                                note: item.estimateNote
                            };
                            this.entity.details.push(obj);
                        });
                    }
                    messageBox.showMessage("Đã lấy chi phí thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        calSummary(){
            this.entity.amount = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                this.entity.amount = this.entity.amount + parseFloat(this.entity.details[i].amount);
            }
        },

        calAmount(item){
            item.price = common.calPrice(item.quantity, item.vat, item.amount);
            this.calSummary();
        },

        calSum(item){
            item.amount = common.calAmount(item.quantity, item.price, item.vat);
            this.calSummary();
        },

        open(id){
            this.submitted = false;
            this.projectId = this.$route.params.id;
            this.id = id;
            if(id > 0){
                this.title = 'Cập nhật phải thu';
                this.getById();
            }
            else{
                this.getProjectById();
                this.entity = { id: 0, details: [], companyId: 0, staffId: 0, projectId: this.projectId, orderId: null, revenueCode: '', revenueDate: common.dateNow, amount: 0, status: 0, note: '' };
                this.title = 'Thêm mới phải thu';
                this.addRow();
                this.getByAccount();
            }
            this.showDialog = true;
        },
        
        close(){
            this.showDialog = false;
        },

        addRow(){
            let item = { id: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 1, price: 0, vat: 0, amount: 0, note: '' };
            this.entity.details.push(item);
        },
        
        delRow(index){
            this.entity.details.splice(index, 1);
            if(this.entity.details.length == 0){
                let item = { id: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 1, price: 0, vat: 0, amount: 0, note: '' };
                this.entity.details.push(item);
            }
        },

        getProjectById(){
            this.setLoading(true);
            projectService.getById(this.projectId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.customerId = response.data.customerId;
                    this.entity.customer = response.data.customer;
                    this.customerName = response.data.customer.companyAlias;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        submit(){
            this.entity.projectId = parseFloat(this.$route.params.id);
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.details.length <= 0){
                messageBox.showWarning("Vui lòng thêm chi tiết phải thu");
                return;
            }
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            heavyRevenueService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            heavyRevenueService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            heavyRevenueService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = response.data.staff.fullName;
                    if(this.entity.company !== null) {
                        this.companyName = this.entity.company.companyAlias;
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.staffName = item.fullName;
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Fee
        closeFee(item){
            this.entity.details[this.selectedId].fee = item;
            this.entity.details[this.selectedId].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = -1;
        },

        openFee(index){
            this.selectedId = index;
            this.$refs.feeList.open(2);
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getCode(staffId){
            this.setLoading(true);
            heavyRevenueService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.revenueCode = response.data.code;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
    },
    validations: {
        entity: {
            revenueCode: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            revenueDate: { required },
            details: {
                $each: {
                    feeId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    quantity: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    price: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    }
                }
            }
        }
    }
})
</script>