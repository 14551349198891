<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách dự toán chi phí dự án</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                   <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label v-if="status == ''" for="status">[Tất cả trạng thái duyệt]</label>
                                <label v-else for="status">Trạng thái duyệt</label>
                                <md-select v-model="status" name="status" id="status" multiple class="search">
                                    <md-checkbox @change="selectAllStatus()" v-model="selectedAllStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="0">Lưu tạm</md-option>
                                    <md-option value="1">Chờ duyệt</md-option>
                                    <md-option value="2">Đã duyệt</md-option>
                                    <md-option value="3">Không duyệt</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                   </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:40px;">#</th>
                                <th style="width:100px;">Trạng thái</th>
                                <th style="width:10%;">Loại dự toán</th>   
                                <th style="width:10%;">Tạm ứng</th>
                                <th style="width:10%;">Giải chi</th>
                                <th style="width:10%;">Quyết toán</th>
                                <th style="width:14%;">Dự án</th>
                                <th style="width:14%;">Mã dự toán</th>
                                <th style="width:10%;">Người dự toán</th>
                                <th class="right" style="width:10%; padding-right: 10px;">Số tiền</th>
                                <th style="width:8%;">Ngày lập</th>
                                <th style="width:12%;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <md-chip v-if="item.status == 0" class="status-2 status-temp">Lưu tạm</md-chip>
                                    <md-chip v-if="item.status == 1" class="status-2 status-inprogress">Chờ duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 2" class="status-2 status-done">Đã duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 3" class="status-2 status-reject">Không duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 4" class="status-2 status-edit">Yêu cầu chỉnh sửa ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                </td>
                                <td class="center">
                                    <md-chip style="width: 150px;" class="md-primary">{{ getPaymentType(item.paymentType) }}</md-chip>
                                </td>
                                <td class="center">
                                    <md-chip  v-if="item.isAdvancePayment" class="md-primary">Đã tạm ứng</md-chip>
                                    <md-chip v-else>Chưa tạm ứng</md-chip>
                                </td>
                                <td class="center">
                                    <md-chip  v-if="item.isExplain" class="md-primary">Đã giải chi</md-chip>
                                    <md-chip v-else>Chưa giải chi</md-chip>
                                </td>
                                <td class="center">
                                    <md-chip  v-if="item.isInvoice" class="md-primary">Đã quyết toán</md-chip>
                                    <md-chip v-else>Chưa quyết toán</md-chip>
                                </td>
                                <td class="left">{{item.project.projectCode}}</td>
                                <td class="left">{{item.estimateCode}}</td>
                                <td class="left">{{item.staff.fullName}}</td>
                                <td class="right">{{formatNumber(item.estimateAmount)}}</td>
                                <td class="center">{{item.estimateDate}}</td>
                                <td class="action">
                                    <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                                        <i class="uil uil-eye"></i>
                                        <md-tooltip>Xem</md-tooltip>
                                    </md-button>
                                    <md-button  v-if="item.isAdvancePayment || item.isInvoice" disabled class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-else @click="edit(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <crudForm ref="crudForm" @refresh="refresh" />
    </div>
</template>
<script>
    import projectEstimateService from '@/api/projectEstimateService';
    import orderHeavyService from '@/api/orderHeavyService';
    import messageBox from '@/utils/messageBox';
    import common from '@/mixins';
    import { mapActions } from 'vuex';
    import crudForm from './Crud.vue';

    export default {
        components: {
            crudForm
        },
        metaInfo: {
            title: 'Danh sách dự toán chi phí dự án'
        },
        data() {
            return {
               loadding: false,
               search: { pageIndex: 1, pageSize: common.pageSize, orderId: 0, customerId: 0, status: '', keyword: '' },
               pager: { totalPages: 1 },
               data: [],
               rowPerPageOptions: [],
               id: 0,
               orderId: 0,
               status: [],
               selectedAllStatus: false,
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            if(this.$route.query.status != undefined){
               this.search.status = this.$route.query.status;
               this.status = this.$route.query.status.split(',')
            }
            else{
                this.status = [];
            }
            if(this.$route.query.keyword != undefined){
               this.search.keyword = this.$route.query.keyword;
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            selectAllStatus(){
                if(this.selectedAllStatus){
                    this.status = ['0','1','2','3'];
                }
                else{
                    this.status = [];
                }
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.status = '';
                this.status = [];
                this.search = { pageIndex: 1, pageSize: common.pageSize, status: '', keyword: '' };
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + "&keyword=";
                this.$router.push('/project-estimate?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + this.search.status + "&keyword=" + this.search.keyword;
                this.$router.push('/project-estimate?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                this.search.orderType = this.orderType;
                projectEstimateService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getOrderById(){
                orderHeavyService.getById(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.$refs.crudForm.open(this.id, response.data);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            add(){
                this.$router.push("/project-estimate/crud?orderType=" + this.orderType);
            },
            
            edit(item){
                this.id = item.id;
                this.orderId = item.orderId;
                this.getOrderById();
            },

            view(id){
                this.$router.push('/project-estimate/view/' + id);
            }
        },
        watch: {
            status: function () {
                this.search.status = String(this.status);
                if(this.status.length < 4){
                    this.selectedAllStatus = false;
                }
                else{
                    this.selectedAllStatus = true;
                }
                this.filter();
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
