<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header" style="height: 40px;">
                    <div class="dialog-title">Danh sách dự toán chi phí làm hàng</div>
                    <div style="display: flex;">
                        <md-button @click="submit()" class="md-raised md-primary">Chọn</md-button>
                        <md-button @click="showDialog = false" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                    </div>
                </div>
                <div class="dialog-search">
                    <div class="search-box">
                        <div class="search-item">
                            <md-field>
                                <md-input v-model="search.code" placeholder="Tìm theo số dự toán"></md-input>
                                <md-icon>search</md-icon>
                            </md-field>
                        </div>
                        <div class="search-item">
                            <md-field>
                                <md-input v-model="search.orderCode" placeholder="Tìm mã đơn hàng/lô hàng"></md-input>
                                <md-icon>search</md-icon>
                            </md-field>
                        </div>
                        <div class="search-item">
                            <md-field>
                                <md-input v-model="search.name" placeholder="Tìm theo số bill/booking"></md-input>
                                <md-icon>search</md-icon>
                            </md-field>
                        </div>
                        <div class="search-item">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label v-if="search.fromDate == ''">Từ ngày lập</label>
                            </md-datepicker>
                        </div>
                        <div class="search-item">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label  v-if="search.toDate == ''">Tới ngày lập</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th rowspan="2" style="width:60px; vertical-align: middle;">
                                            <md-checkbox v-model="selectAll"></md-checkbox>
                                        </th> 
                                        <th class="left" style="width: 200px;">Số dự toán</th>
                                        <th style="width: 120px;">Ngày dự toán</th>
                                        <th class="left" style="width: 180px;">Người dự toán</th>
                                        <th class="left" style="width: 220px;">Khách hàng</th>
                                        <th class="left" style="width: 200px;">Số bill/booking</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && pager.totalItems > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="center">
                                            <md-checkbox v-model="checkedList" :value="item.id"></md-checkbox>
                                        </td>
                                        <td><a @click="selected(item)">{{item.estimateCode}}</a></td>
                                        <td>{{item.estimateDate}}</td>
                                        <td>{{item.staff.fullName}}</td>
                                        <td>{{item.order.customer.companyAlias}}</td>
                                        <td>{{item.order.booking.noBooking}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer" v-if="pager.totalPages > 0">
                    <div class="dialog-paging">
                        <div v-if="pager.totalPages > 0">
                            <v-pagination v-model="search.pageIndex"
                            :page-count="pager.totalPages"
                            @change="onChange"></v-pagination>
                        </div>
                    </div>
                    <div>|</div>
                    <div v-if="pager.totalPages > 0" class="dialog-data-info">Xem <span>{{pager.startIndex}}-{{pager.endIndex}}/{{pager.totalItems}}</span> bản ghi</div>
                    <div>|</div>
                    <div class="dialog-paging-row-of-page">Rows/page: <a v-for="item in rowPerPageOptions" :key="item.id" :class="{'current-row-of-page': search.pageSize == item.id}" @click="changeRowPerPage(item.id)">{{item.text}}</a></div>
                    <div>|</div>
                    <div class="dialog-refresh"><a @click="refresh()">Làm tươi</a></div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import orderEstimateService from '../../api/orderEstimateService'; 
    import messageBox from '../../utils/messageBox'; 
    import common from '../../mixins';
    import vPagination from '../Pagination.vue';

    export default {
        components: {
            vPagination
        },
        data: () => ({
            showDialog: false,
            ids: [],
            data: [],
            pager: { totalPages: 1 }, 
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, code: '', orderCode: '', name: '', fromDate: '', toDate: '' },
            rowPerPageOptions: common.rowPerPagePopupOptions,
            orderType: 1,
            checkedList: [],
            selectAll: false,
        }),

        created() {
        },

        methods: { 
            submit(){
                let list = [];
                for(let i = 0; i < this.checkedList.length; i++){
                    list.push(this.data.filter(x => x.id == this.checkedList[i])[0]);
                }
                this.$emit('close', list);
                this.showDialog = false;
            },

            selected(item){
                let list = [];
                list.push(item);
                this.$emit('close', list);
                this.showDialog = false;
            },

            onChange: function () {
                this.getData();
            },

            open(orderType){
                this.orderType = orderType;
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            changeRowPerPage(pageSize){
                this.search = { pageIndex: 1, pageSize: pageSize, code: '', name: ''};
                this.getData();
            },

            refresh() {
                this.search = { pageIndex: 1, pageSize: common.pageSize, code: '', name: ''};
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.search.orderType = this.orderType;                
                orderEstimateService.getForAdvancePayment(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items; 
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.data.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
            'search.pageIndex': function () { 
                this.getData(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.code': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.name': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.orderCode': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.fromDate': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.toDate': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            }
        }, 
    }
</script>

<style lang="css" scoped>
   .search-box {
        display: flex;
    }
    .search-item {
        margin: 0 10px;
        width: 200px;
    }
    .search-item:first-child {
        margin-left: 0;
    }
    .search-item:last-child {
        margin-right: 20px;
    }
    .md-content {
        padding: 10px;
        width: 1100px;
        height: 555px;
        max-width: 1100px;
        max-height: 555px;
    }
    .dialog-content {
        height: 427px;
    }
    .dialog-content table{
        height: 427px;
        min-height: 427px !important;
    }
</style>