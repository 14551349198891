<template>
    <md-dialog id="crud-project-invoice" :md-active.sync="showDialog" :md-click-outside-to-close="false" :md-fullscreen="true">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-if="entity.status == 0 || entity.status == 4" @click="submit(1)" class="md-raised md-primary">Gửi duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button v-if="entity.status == 0" @click="submit(0)" class="md-raised md-temp">Lưu tạm<md-tooltip>Lưu tạm</md-tooltip></md-button>
                                <md-button @click="back()" class="md-raised">Đóng</md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                        <label for="invoiceCode">Số quyết toán</label>
                                        <md-input disabled name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập số quyết toán</span>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="invoiceDate">Ngày lập</label>
                                        <md-input :disabled="true" name="invoiceDate" v-model="entity.invoiceDate"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <div style="display:flex;">
                                        <md-field>
                                            <label for="amount">Tổng quyết toán</label>
                                            <md-input disabled name="amount" :value="formatNumber(entity.amount)" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên phụ trách</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>  
                                <div class="col l-4 m-4 c-12">
                                    <md-field>
                                        <label for="reason">Nội dung</label>
                                        <md-input name="reason" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-estimate" class="tab-content" md-label="Dự toán">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="openAdvancePaymentList()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <table class="data-table-2"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th style="width:120px;">#</th>
                                                                    <th class="left" style="width:45%;">Số dự toán</th> 
                                                                    <th class="right" style="width:30%;">Tổng dự toán</th> 
                                                                    <th style="width:20%">Hành động</th> 
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loadding == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loadding == false"> 
                                                                <tr v-for="(item, index) in entity.advancePaymentList" :key="'advancepayment-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="left">{{item.estimate.estimateCode}}</td> 
                                                                    <td class="right">{{formatNumber(item.estimate.estimateAmount)}}</td>
                                                                    <td class="grid-action">
                                                                        <a @click="delRow(item, index)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                    </td>
                                                                </tr> 
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết quyết toán">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 1650px; padding-top: 10px;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px;">#</th> 
                                                                        <th class="left" rowspan="2" style="width:220px;">Dự toán</th> 
                                                                        <th  class="left" rowspan="2" style="width:250px;">Tên phí</th> 
                                                                        <th rowspan="2" style="width:120px;">Loại</th>
                                                                        <th colspan="3" style="width:340px; background-color: #B4E380;">Dự toán</th>
                                                                        <th colspan="3" style="width:340px; background-color: #FFD18E;">Tạm ứng</th>
                                                                        <th colspan="3" style="width:340px; background-color: #CB9DF0;">Quyết toán</th>
                                                                    </tr>
                                                                    <tr> 
                                                                        <th class="right" style="width:120px; background-color: #B4E380;">Số tiền</th>
                                                                        <th class="right" style="width:100px; background-color: #B4E380;">VAT</th>
                                                                        <th class="right" style="width:120px; background-color: #B4E380;">Thành tiền</th>

                                                                        <th class="right" style="width:120px; background-color: #FFD18E;">Số tiền</th>
                                                                        <th class="right" style="width:100px; background-color: #FFD18E;">VAT</th>
                                                                        <th class="right" style="width:120px; background-color: #FFD18E;">Thành tiền</th>

                                                                        <th class="right" style="width:120px; background-color: #CB9DF0;">Số tiền</th>
                                                                        <th class="right" style="width:100px; background-color: #CB9DF0;">VAT</th>
                                                                        <th class="right" style="width:120px; background-color: #CB9DF0;">Thành tiền</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loadding == false"> 
                                                                    <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="center">{{index + 1}}</td>
                                                                        <td class="left">
                                                                            {{ item.estimate.estimateCode }}
                                                                        </td>
                                                                        <td class="left">
                                                                            <span>{{ item.fee.feeName}}</span>
                                                                        </td>
                                                                        <td class="center">
                                                                            {{ getFeeType(item.type) }}
                                                                        </td>
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.estimatePrice)}}</div> 
                                                                        </td>
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.estimateVat)}}%</div> 
                                                                        </td> 
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.estimateAmount)}}</div> 
                                                                        </td>
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.advancePaymentPrice)}}</div> 
                                                                        </td>
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.advancePaymentVat)}}%</div> 
                                                                        </td> 
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.advancePaymentAmount)}}</div> 
                                                                        </td>
                                                                        
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.invoicePrice" :class="{'is-error': $v.entity.details.$each[index].invoicePrice.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.invoiceVat" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number v-model="item.invoiceAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <table class="data-table-2"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th style="width:100px;">#</th> 
                                                                    <th style="width:20%;">Bộ phận/phòng</th> 
                                                                    <th style="width:20%;">Người phê duyệt</th> 
                                                                    <th style="width:20%;">Tiêu đề</th>
                                                                    <th style="width:20%;">Ghi chú</th> 
                                                                    <th style="width:10%;">Thứ tự</th> 
                                                                    <th style="width:150px">Hành động</th> 
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loadding == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loadding == false"> 
                                                                <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.dept.name" class="form-control" type="text">
                                                                            <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                            <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.title" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.note" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                        </div>
                                                                    </td> 
                                                                    <td class="grid-action">
                                                                        <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                    </td> 
                                                                </tr> 
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeApprove"/>
                <approveList ref="approveList" @close="closeApprove"/>
                <deptList ref="deptList" @close="closeDept"/>
                <advancePaymentList ref="advancePaymentList" @close="closeAdvancePayment"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import projectInvoiceService from '@/api/projectInvoiceService';
    import projectEstimateService from '@/api/projectEstimateService';
    import orderHeavyEstimateService from '@/api/orderHeavyEstimateService';
    import approveSettingService from '@/api/approveSettingService';
    import staffService from '@/api/staffService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import staffList from '@/components/popup/_StaffList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import advancePaymentList from '@/components/popup/_ProjectAdvancePaymentList.vue';
    import approveList from '@/components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            advancePaymentList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật quyết toán chi phí dự án STST'
        },
        data() {
            return {
                title: '',
                id: 0,
                showDialog: false,
                submitted: false,
                loadding: false,
                entity: { id: 0, status: 0, details: [], amount: 0, advancePaymentList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, invoiceCode: '', invoiceDate: common.dateNow, pay: 0, payFee: 0, payOnBehalf: 0, payService: 0 },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                advancePayment: { id: 'id_' + common.getFakeId(), amount: 0, advancePaymentId: 0, estimateId: 0, advancePayment: { advancePaymentCode: ''}, estimate: { estimateCode: '' } },
                vat: common.vatF,
                currencyF: common.currencyF2,
                staffName: '',
                staffs: [],
                types: common.typeFee,
                catFee: 0,
                estimateAmount: 0,
            }
        },
        created(){
            this.projectId = parseFloat(this.$route.params.id);
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            open(id){
                this.id = id;
                if(id == 0){
                    this.title = "Thêm mới quyết toán";
                    this.entity = { 
                        id: 0, projectId: this.projectId, staffId: 0, 
                        invoiceCode: '',
                        invoiceDate: common.dateNow,
                        details: [], approveList: [], advancePaymentList: [],
                        status: 0, amount: 0, note: ''
                    };
                    this.getByAccount();
                    this.entity.approveList.push(this.approve);
                    this.getApproveSetting();
                }
                else{
                    this.title = "Cập nhật quyết toán";
                    this.getById();
                }
                this.showDialog = true;
            },

            calSummary(){
                this.entity.amount = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.entity.amount = this.entity.amount + parseFloat(this.entity.details[i].invoiceAmount);
                }
            },

            calSum(item){
                let price = parseFloat(item.invoicePrice);
                let vat =  parseFloat(item.invoiceVat) / 100;
                item.invoiceAmount = Math.round(price +  (price * vat));

                this.calSummary();
            },

            getCode(staffId){
                this.setLoading(true);
                projectInvoiceService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.invoiceCode = response.data.code;
                        this.entity.invoiceNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Estimate Request
            delRow(item, index){
                this.entity.advancePaymentList.splice(index, 1);

                this.entity.details = this.entity.details.filter(obj => !(obj.estimateId == item.estimateId && obj.estimateType == item.estimateType));

                this.calSummary();
            },

            closeAdvancePayment(items){
                for(let i = 0; i < items.length; i ++){
                    let check = this.entity.advancePaymentList.findIndex(x => x.estimateId == items[i].estimateId && x.estimateType == items[i].type);
                    if(check == -1){
                        if(items[i].type == 1){
                            this.getProjectEstimateById(items[i]);
                        }
                        else{
                            this.getOrderEstimateById(items[i]);
                        }  
                    }
                }
            },

            getProjectEstimateById(obj){
                this.setLoading(true);
                projectEstimateService.getById(obj.estimateId).then((response) => {
                    if(response.statusCode == 200){
                        let item = {
                            estimateId: obj.estimateId,
                            estimate: { id: obj.estimateId, estimateCode: obj.estimateCode, estimateAmount: obj.estimateAmount },
                            estimateType: 1
                        };
                        this.entity.advancePaymentList.push(item);
                        response.data.details.map(item => {
                            let detail = {
                                id: item.id,
                                estimateId: item.estimateId,
                                estimate: item.estimate,
                                feeId: item.feeId,
                                fee: item.fee,
                                type: item.type,
                                estimatePrice: item.estimatePrice,
                                estimateVat: item.estimateVat,
                                estimateAmount: item.estimateAmount,
                                advancePaymentPrice: item.advancePaymentPrice > 0 ? item.advancePaymentPrice : 0,
                                advancePaymentVat: item.advancePaymentVat > 0 ? item.advancePaymentVat : 0,
                                advancePaymentAmount: item.advancePaymentAmount > 0 ? item.advancePaymentAmount : 0,
                                invoicePrice: item.estimatePrice,
                                invoiceVat: item.estimateVat,
                                invoiceAmount: item.estimateAmount,
                                estimateType: obj.type
                            };
                            this.entity.details.push(detail);
                        });
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getOrderEstimateById(obj){
                this.setLoading(true);
                orderHeavyEstimateService.getById(obj.estimateId).then((response) => {
                    if(response.statusCode == 200){
                        let item = {
                            estimateId: obj.estimateId,
                            estimate: { id: obj.estimateId, estimateCode: obj.estimateCode, estimateAmount: obj.estimateAmount },
                            estimateType: 2
                        };
                        this.entity.advancePaymentList.push(item);
                        response.data.details.map(item => {
                            let detail = {
                                id: item.id,
                                estimateId: item.estimateId,
                                estimate: item.estimate,
                                feeId: item.feeId,
                                fee: item.fee,
                                type: item.type,
                                estimatePrice: item.estimatePrice,
                                estimateVat: item.estimateVat,
                                estimateAmount: item.estimateAmount,
                                advancePaymentPrice: item.advancePaymentPrice,
                                advancePaymentVat: item.advancePaymentVat,
                                advancePaymentAmount: item.advancePaymentAmount,
                                invoicePrice: item.estimatePrice,
                                invoiceVat: item.estimateVat,
                                invoiceAmount: item.estimateAmount,
                                estimateType: obj.type
                            };
                            this.entity.details.push(detail);
                        });
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            openAdvancePaymentList(){
                this.$refs.advancePaymentList.open(this.projectId);
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.customsAdvancePayment).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairAdvancePayment), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.advancePaymentList.length <= 0){
                    messageBox.showWarning("Vui lòng nhập tạm ứng chi phí dự án STST");
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập chi tiết quyết toán");
                    return;
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                projectInvoiceService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                projectInvoiceService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                projectInvoiceService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.showDialog = false;
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                invoiceCode: { required },
                invoiceDate: { required },
                details: {
                    $each: {
                        invoicePrice: {
                            required,
                            isSelected(value) {
                                if (value >= 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                }
            }
        }
    })
</script>

<style>
    #crud-project-invoice .md-dialog-container {
        max-width: 95% !important;
    }
</style>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        max-width: 100%;
        width: 100%;
    }
    .page-content {
        min-height: 600px;
    }
</style>