<template>
    <div id="app">
        <router-view></router-view>
        <notifications group="foo" />
        <v-dialog />
        <Loader />
        <notifications group="success-template"
                   :duration="3000"
                   :width="400"
                   animation-name="v-fade-left"
                   position="top right">

            <template slot="body" slot-scope="props">
                <div class="success-template">
                    <div class="success-template-icon">
                        <md-icon>task_alt</md-icon>
                    </div>
                    <div class="custom-template-content">
                        <div class="success-template-title">{{props.item.title}}</div>
                        <div class="success-template-text" v-html="props.item.text"></div>
                    </div>
                    <div class="success-template-close"
                        @click="props.close">
                        <md-icon>close</md-icon>
                    </div>
                </div>
            </template>
        </notifications>
        <notifications group="error-template"
                   :duration="3000"
                   :width="450"
                   animation-name="v-fade-left"
                   position="top right">

            <template slot="body" slot-scope="props">
                <div class="error-template">
                    <div class="error-template-icon">
                        <md-icon>error_outline</md-icon>
                    </div>
                    <div class="custom-template-content">
                        <div class="error-template-title">{{props.item.title}}</div>
                        <div class="error-template-text" v-html="props.item.text"></div>
                    </div>
                    <div class="error-template-close"
                        @click="props.close">
                        <md-icon>close</md-icon>
                    </div>
                </div>
            </template>
        </notifications>
        <notifications group="warning-template"
                   :duration="3000"
                   :width="450"
                   animation-name="v-fade-left"
                   position="top right">

            <template slot="body" slot-scope="props">
                <div class="warning-template">
                    <div class="warning-template-icon">
                        <md-icon>warning_amber</md-icon>
                    </div>
                    <div class="custom-template-content">
                        <div class="warning-template-title">{{props.item.title}}</div>
                        <div class="warning-template-text" v-html="props.item.text"></div>
                    </div>
                    <div class="warning-template-close" @click="props.close">
                        <md-icon>close</md-icon>
                    </div>
                </div>
            </template>
        </notifications>
        <notifications group="info-template"
                   :duration="3000"
                   :width="400"
                   animation-name="v-fade-left"
                   position="top right">

            <template slot="body" slot-scope="props">
                <div class="info-template">
                    <div class="info-template-icon">
                        <md-icon>info_outline</md-icon>
                    </div>
                    <div class="custom-template-content">
                        <div class="info-template-title">{{props.item.title}}</div>
                        <div class="info-template-text" v-html="props.item.text"></div>
                    </div>
                    <div class="info-template-close"
                        @click="props.close">
                        <md-icon>close</md-icon>
                    </div>
                </div>
            </template>
        </notifications>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import jQuery from 'jquery'
    window.jQuery = window.$ = jQuery
    import Loader from './components/Loadding.vue';
    import common from './mixins/index'; 

    export default {
        name: "App",
        computed: {
          ...mapGetters({
             loading: 'common/loading'
          })
        },
        components: {
          Loader
        },
        mounted() {
            this.redirectToMobileOrTablet();
            window.addEventListener('resize', () => {
                common.windowWidth = window.innerWidth;
            })
        },
        created() {
            this.$material.locale.dateFormat = 'dd-MM-yyyy';
            this.$material.locale.startYear = 1900;
            this.$material.locale.endYear = 2099;
            this.$material.locale.days = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];
            this.$material.locale.shortDays = ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];
            this.$material.locale.shorterDays = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
            this.$material.locale.months = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
            this.$material.locale.shortMonths = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
            this.$material.locale.shorterMonths = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
            this.$material.locale.firstDayOfAWeek = 1;
            this.$material.locale.cancel = 'Cancel';
            this.$material.locale.confirm = 'Ok';
        },
        methods: {
            redirectToMobileOrTablet() {
                const userAgent = navigator.userAgent.toLowerCase();

                // Kiểm tra thiết bị mobile hoặc iPad
                const isMobile = /iphone|ipod|blackberry|windows phone|android.*mobile/i.test(userAgent);
                const isTablet = /ipad|android(?!.*mobile)/i.test(userAgent);

                // Nếu là thiết bị mobile hoặc iPad, chuyển hướng
                if (isMobile || isTablet) {
                    window.location.href = 'https://m.tms.ppl.com.vn'; // Chuyển hướng đến trang mobile hoặc tablet
                }
            }
        }
    };
</script>

<style scoped lang="css">
    @import  'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,200;0,400;0,500;0,600;1,100;1,200;1,400;1,500&display=swap';
    @import  'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css';
    @import  'https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.css';
    @import  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons';
    @import  'https://unpkg.com/vue-material/dist/vue-material.min.css';
    @import  'https://unpkg.com/vue-material/dist/theme/default.css';
    @import  './assets/css/reset.css';
    @import  './assets/css/grid.css';
    @import  './assets/css/styles.css';
    @import  './assets/css/components.css';
    @import  'https://unicons.iconscout.com/release/v4.0.0/css/line.css';

.warning-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #fff1e0;
    border: 1px solid #fb8c05;
}

.warning-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.warning-template-icon i {
    color: #fb8c05 !important;
    font-size: 32px !important;
}

.warning-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #fb8c05;
}

.warning-template-text {
    color: #fb8c05;
}

.warning-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.warning-template-close i {
    color: #fb8c05;
}

.warning-template-close:hover {
    opacity: 0.8;
}

.info-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #e7f2fd;
    border: 1px solid #2196f3;
}

.info-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.info-template-icon i {
    color: #2196f3 !important;
    font-size: 32px !important;
}

.info-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #2196f3;
}

.info-template-text {
    color: #2196f3;
}

.info-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.info-template-close i {
    color: #2196f3 !important;
}

.info-template-close:hover {
    opacity: 0.8;
}

.error-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #f8d7da;
    border: 1px solid #f5c2c7;
}

.error-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.error-template-icon i {
    color: #842029 !important;
    font-size: 32px !important;
}

.error-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #842029;
}

.error-template-text {
    color: #842029;
}

.error-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.error-template-close i {
    color: #842029 !important;
}

.error-template-close:hover {
    opacity: 0.8;
}

.success-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    background: #d9ffd9;
    border: 1px solid #15C371;
}

.success-template-icon {
    flex: 0 1 auto;
    padding: 0 10px;
}

.success-template-icon i {
    color: #15C371 !important;
    font-size: 32px !important;
}

.custom-template-content {
    padding: 10px;
    flex: 1 0 auto;
    font-size: 14px;
}

.success-template-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 5px;
    color: #0f5132;
}

.success-template-text {
    color: #0f5132;
}

.success-template-close {
    flex: 0 1 auto;
    padding: 0 10px;
    font-size: 16px;
    opacity: 0.4;
    cursor: pointer;
}

.success-template-close i {
    color: #0f5132 !important;
}

.success-template-close:hover {
    opacity: 0.8;
}
</style>

