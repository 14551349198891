<template>
    <div class="page-content">
        <div class="view-body">
            <div class="tabs">
                <div :class="{'active' : tab == 'info'}" class="tab-item"><a @click="onTab('info')">Thông tin chung</a></div>
                <div :class="{'active' : tab == 'detail'}" class="tab-item"><a @click="onTab('detail')">Chi tiết đơn hàng</a></div>
                <div :class="{'active' : tab == 'job'}" class="tab-item"><a @click="onTab('job')">Kế hoạch điều động</a></div>
                <div :class="{'active' : tab == 'estimate'}" class="tab-item"><a @click="onTab('estimate')">Dự toán</a></div>
            </div>
            <div class="content-tab">
                <div v-if="tab == 'info'">
                    <Info />
                </div>
                <div v-else-if="tab == 'detail'">
                    <OrderItems />
                </div>
                <div v-else-if="tab == 'job'">
                    <JobList />
                </div>
                <div v-else-if="tab == 'estimate'">
                    <Estimate />
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import messageBox from '../../../utils/messageBox';
    import Info from './Info.vue';
    import OrderItems from './OrderItems.vue';
    import JobList from './Job.vue';
    import Estimate from './Estimate.vue';

    export default ({
        components: {
            Info,
            OrderItems,
            JobList,
            Estimate
        },
        metaInfo: {
            title: 'Thêm/Cập nhật đơn hàng siêu trường siêu trọng'
        },
        data() {
            return {
                title: '',
                id: 0,
                tab: 'info',
                projectId: 0
            }
        },
        created(){
            this.projectId = this.$route.query.projectId;
            this.id = this.$route.params.id;
            if(this.$route.query.tab !== undefined){
                this.tab = this.$route.query.tab;
            }
        },
        methods: {
            onTab(tabName){
                this.id = this.$route.params.id;
                if(tabName === 'info'){
                    this.tab = tabName;
                    this.$router.push('/order-heavy/crud/' + this.id + '?projectId=' + this.projectId + '&tab=' + tabName);
                }
                else{
                    if(this.id == 0 || this.id == undefined){
                        messageBox.showWarning("Vui lòng thêm thông tin chung đơn hàng");
                    }
                    else{
                        this.tab = tabName;
                        this.$router.push('/order-heavy/crud/' + this.id + '?projectId=' + this.projectId + '&tab=' + tabName);
                    }
                }
            },
        },
    })

</script>
