<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách cont/kiện hàng chưa được phân bổ</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="submit()" md-menu-trigger class="md-raised md-primary btn-add">Lưu phân bổ<md-tooltip>Nhận phân bổ cont/kiện hàng</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số cont/kiện hàng</label>
                                <md-input v-model="search.serial"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 326px); overflow-x: auto; width: 100%;">
                        <table class="data-table" style="width:  100% !important;">
                            <thead>
                                <tr>
                                    <th rowspan="3" style="background-color: #DDDDDD; z-index: 999; width: 100px;">STT</th>
                                    <th rowspan="3" style="width:60px; vertical-align: middle; background-color: #DDDDDD;">
                                        <md-checkbox v-model="selectAll"></md-checkbox>
                                    </th> 
                                    <th colspan="3" style="background-color: #B4E380;">THÔNG TIN ĐƠN HÀNG</th>
                                    <th colspan="8" style="background-color: #FFD0D0;">THÔNG TIN BILL/BOOKING</th>
                                    <th colspan="3" style="background-color: #BBE9FF;">ĐỊA ĐIỂM GIAO NHẬN</th>
                                </tr>
                                <tr>
                                    <th style="width: 100px; background-color: #B4E380;">Ngày giao nhận</th>
                                    <th class="left" style="width: 100px; background-color: #B4E380;">Mã đơn hàng</th>
                                    <th class="left" style="width: 100px; background-color: #B4E380;">Người tạo</th>
                                    <th class="left" style="width: 150px; background-color: #FFD0D0;">Khách hàng</th>
                                    <th style="width: 100px; background-color: #FFD0D0;">Loại hình</th>
                                    <th style="width: 100px; background-color: #FFD0D0;">KL (tấn)</th>
                                    <th class="left" style="width: 100px; background-color: #FFD0D0;">Bill/Booking</th>
                                    <th style="width: 100px; background-color: #FFD0D0;">Loại cont</th>
                                    <th class="left" style="width: 100px; background-color: #FFD0D0;">Số cont</th>
                                    <th class="left" style="width: 100px; background-color: #FFD0D0;">Số seal</th>
                                    <th style="width: 100px; background-color: #FFD0D0; color: red;">Closing time</th>

                                    <th class="left" style="width: 100px; background-color: #BBE9FF;">Nơi nhận cont</th>
                                    <th class="left" style="width: 100px; background-color: #BBE9FF;">Kho đóng/rút hàng</th>
                                    <th class="left" style="width: 100px; background-color: #BBE9FF;">Nơi trả cont</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data" style="text-align: center;"><span >Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="'index-' + index" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="center">
                                        <md-checkbox v-model="checkedList" :value="item.id"></md-checkbox>
                                    </td>
                                    <td> 
                                        <span v-if="item.dateOfReceipt != ''">{{ item.dateOfReceipt }}</span>
                                        <span v-else>{{ item.dateOfDelivery }}</span>
                                    </td>
                                    <td> {{ item.order.orderCode }}</td>
                                    <td> {{ item.order.staff.fullName }}</td>

                                    <td>{{ item.order.customer.companyAlias }}</td>
                                    <td>{{ getTypeOfTransport(item.typeOfTransport) }}</td>
                                    <td>{{ Math.round(item.kg/1000, 2) }}</td>
                                    <td>
                                        <span v-if="item.order.booking.noBooking !== ''">{{ item.order.booking.noBooking }}</span>
                                    </td>
                                    <td>{{ item.unit.unitName }}</td>
                                    <td>{{ item.serial }}</td>
                                    <td>{{ item.seal }}</td>
                                    <td>
                                        <span v-if="item.order.booking.cutoffTime !== ''">{{ item.order.booking.cutoffTime }}</span>
                                        <span style="padding-left: 2px;" v-if="item.order.booking.cutoffDate !== ''">{{ item.order.booking.cutoffDate }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.locations.length >= 1">
                                            {{ item.locations[0].locationName }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.locations.length >= 2">
                                            {{ item.locations[1].locationName }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.locations.length >= 3">
                                            {{ item.locations[2].locationName }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <contractList ref="contractList" @close="closeContract"/>
        <orderList ref="orderList" @close="closeOrder"/>
    </div>
</template>
<script>
    import orderService from '../../../api/orderService';
    import branchService from '../../../api/branchService';
    import companyService from '../../../api/companyService';
    import staffService from '../../../api/staffService';
    import contractService from '../../../api/contractService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import contractList from '../../../components/popup/_ContractList.vue';
    import orderList from '../../../components/popup/_OrderList.vue';

    export default {
        components: {
            customerList,
            contractList,
            orderList
        },
        metaInfo: {
            title: 'Danh sách cont/kiện hàng chưa phân bổ'
        },
        data() {
            return {
                loadding: false,
                id: 0,
                customerType: common.companyType.customer,
                typeList: common.typeOfTransport,
                submitted: false,
                search: { pageIndex: 1, pageSize: 50, status: 0, branchId: 0, orderId: 0, serial: '', customerId: 0, fromDate: this.getRangeDate(-7), toDate: common.dateNow },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                orderType: 1,
                customerName: '',
                customers: [],
                contractName: '',
                contracts: [],
                orderName: '',
                orders: [],
                branchs: [],
                selectAll: false,
                checkedList: [],
                assignId: 0,
                submitDistributeId: 0
            }
        },
        created(){
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.orderType = this.$route.query.orderType == undefined ? 1 :  this.$route.query.orderType;
            if(this.$route.query.serial != undefined){
               this.search.serial = this.$route.query.serial;
            }
            if(this.$route.query.customerId != undefined){
               this.search.customerId = parseInt(this.$route.query.customerId);
            }
            if(this.$route.query.orderId != undefined){
               this.search.orderId = parseInt(this.$route.query.orderId);
            }
            if(this.$route.query.status != undefined){
               this.search.status = parseInt(this.$route.query.status);
            }
            this.getBranchs();
            this.getOwner();
            this.getByAccount();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                if (this.assignId == 0) {
                    messageBox.showWarning("Không tải được dữ liệu phân bổ");
                    return;
                }
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng phân bổ");
                    return;
                }

                let distributeList = [];

                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.data.findIndex(x => x.id == this.checkedList[i]);
                    this.data[selected].distributeId = this.assignId;

                    distributeList.push(
                        { 
                            id: this.data[selected].id, 
                            distributeId: this.assignId,
                            submitDistributeId: this.submitDistributeId,
                            isPpl: true
                        } 
                    )
                }
                if(distributeList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng phân bổ");
                    return;
                }
                this.setLoading(true);
                orderService.updateDistribute(distributeList).then((response) => {
                    console.log('response', response)
                    if(response.statusCode == 200 && response.data > 0){
                        messageBox.showMessage("Phân bổ thành công.");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning("Có lỗi xảy ra khi phân bổ.");
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            refresh(){
                this.checkedList = [];
                this.search.status = 0;
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/distribute?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/distribute?' + url).catch(()=>{});

                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            getData(){
                this.loadding = true;
                orderService.getGoodsForDistribute(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.submitDistributeId = response.data.id;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getOwner(){
                this.setLoading(true);
                companyService.getOwner().then((response) => {
                    if(response.statusCode == 200){
                        this.assignId = response.data.id;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Contract
            closeContract(item){
                this.contractName = item.contractCode;
                this.search.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.search.contractId = val.id;
                this.contractName = val.contractCode;
            },

            openContract(){
                this.$refs.contractList.open();
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Order
            closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.data.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.search.contractId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.branchId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.orderId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.customerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.status': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.serial': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.fromDate': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.toDate': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }

</script>
