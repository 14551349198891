<template> 
    <div class="page-content"> 
        <div class="grid"> 
            <div class="row no-gutters page-list-header"> 
                <div class="col l-6 m-6 c-12"> 
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách phiếu mượn vật tư</span></h4> 
                </div> 
                <div class="col l-6 m-6 c-12"> 
                    <div class="page-list-action">  
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary"> 
                            <md-icon>cached</md-icon> 
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button> 
                    </div> 
                </div> 
            </div> 
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label v-if="status == ''" for="status">[Tất cả trạng thái duyệt]</label>
                                <label v-else for="status">Trạng thái duyệt</label>
                                <md-select v-model="status" name="status" id="status" multiple class="search">
                                    <md-checkbox @change="selectAllStatus()" v-model="selectedAllStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="0">Lưu tạm</md-option>
                                    <md-option value="1">Chờ duyệt</md-option>
                                    <md-option value="2">Đã duyệt</md-option>
                                    <md-option value="3">Không duyệt</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo mã phiếu</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="receiverName" @md-selected="getReceiverSelected" :md-options="receivers" @md-changed="getReceivers"  @md-opened="getReceivers">
                                    <label>Người nhận</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openReceiver()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm người nhận</md-tooltip>
                                </md-button>
                            </div>

                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="delivererName" @md-selected="getDelivererSelected" :md-options="deliverers" @md-changed="getDeliverers"  @md-opened="getDeliverers">
                                    <label>Người giao</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openDeliverer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm người giao</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="table-content"> 
                    <table class="data-table"> 
                        <thead> 
                            <tr> 
                                <th style="width:60px;">#</th> 
                                <th style="width:100px;">Trạng thái</th>
                                <th class="left" style="width:20%;">Mã phiếu</th> 
                                <th class="left" style="width:15%;">Người nhận</th> 
                                <th class="left" style="width:15%;">Người giao</th>
                                <th class="center" style="width:12%;">Ngày lập</th> 
                                <th class="center" style="width:12%;">Ngày mượn</th>
                                <th class="center" style="width:12%;">Ngày trả</th>
                                <th style="width:10%;">Hành động</th> 
                            </tr> 
                         </thead> 
                        <tbody v-if="loadding == true" style="height: 150px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr> 
                        </tbody> 
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody> 
                        <tbody v-if="loadding == false && pager.totalItems > 0"> 
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td> 
                                <td class="center">
                                    <md-chip v-if="item.status == 0" class="status-2 status-temp">Lưu tạm</md-chip>
                                    <md-chip v-if="item.status == 1" class="status-2 status-inprogress">Chờ duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 2" class="status-2 status-done">Đã duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 3" class="status-2 status-reject">Không duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 4" class="status-2 status-edit">Yêu cầu chỉnh sửa ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                </td>
                                <td class="left">{{item.exportCode}}</td>
                                <td class="left">{{item.receiver.fullName}}</td> 
                                <td class="left">{{item.deliverer.fullName}}</td>
                                <td class="center">{{item.exportDate}}</td> 
                                <td class="center">{{item.loanDate}}</td> 
                                <td class="center">{{item.returnDate}}</td> 
                                <td class="action"> 
                                    <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                                        <i class="uil uil-eye"></i>
                                        <md-tooltip>Xem</md-tooltip>
                                    </md-button>
                                    <router-link v-if="item.status == 0 || item.status == 4" :to="'/store-loan/crud/' + item.id"> 
                                        <md-button class="md-fab md-mini md-second"> 
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button> 
                                    </router-link> 
                                    <md-button v-if="item.status == 0" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain"> 
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip> 
                                    </md-button> 
                                </td> 
                            </tr> 
                        </tbody> 
                    </table> 
                </div> 
                 <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <receiverList ref="receiverList" @close="closeReceiver"/>
        <delivererList ref="delivererList" @close="closeDeliverer"/>
    </div> 
</template>
<script> 
    import storeExportService from '../../../api/storeExportService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox'; 
    import common from '../../../mixins'; 
    import { mapActions } from 'vuex'; 
    import receiverList from '../../../components/popup/_StaffList.vue';
    import delivererList from '../../../components/popup/_StaffList.vue';
    import { APP_CONFIG } from '@/utils/constants'

    export default {
        components: {
            receiverList,
            delivererList
        },
        metaInfo: { 
            title: 'Danh sách phiếu mượn vật tư' 
        }, 
        data() { 
            return { 
                loadding: false, 
                search: { pageIndex: 1, pageSize: common.pageSize, type: 1, status: '', code: '', receiverId: 0, delivererId: 0, fromDate: '', toDate: '' }, 
                pager: { totalPages: 1 }, 
                data: [], 
                rowPerPageOptions: [], 
                id: 0,
                receiverName: '',
                receivers: [],
                delivererName: '',
                deliverers: [],
                status: [],
                selectedAllStatus: false,
            } 
        }, 
        created(){ 
            this.rowPerPageOptions = common.rowPerPageOptions; 
            if(this.$route.query.page != undefined){ 
               this.search.pageIndex = parseInt(this.$route.query.page); 
            } 
            if(this.$route.query.pageSize != undefined){ 
               this.search.pageSize = parseInt(this.$route.query.pageSize); 
            } 
            this.filter(); 
        }, 
        methods: { 
            ...mapActions('common', ['setLoading']), 

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/store-export/export-excel-loan?status=" + this.search.status +
                "&code=" + this.search.code + "&supplierId=" + this.search.supplierId + "&receiverId=" + this.search.receiverId +
                "&delivererId=" + this.search.delivererId + "&fromDate=" + this.search.fromDate + "&toDate=" + this.search.toDate;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            selectAllStatus(){
                if(this.selectedAllStatus){
                    this.status = ['0','1','2','3'];
                }
                else{
                    this.status = [];
                }
            },

            //Deliver
            closeDeliverer(item){
                this.delivererName = item.fullName;
                this.search.delivererId = item.id;
                this.$refs.delivererList.close();
            },

            getDelivererSelected(val){
                this.search.delivererId = val.id;
                this.delivererName = val.fullName;
            },

            openDeliverer(){
                this.$refs.delivererList.open();
            },

            getDeliverers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.deliverers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Receiver
            closeReceiver(item){
                this.receiverName = item.fullName;
                this.search.receiverId = item.id;
                this.$refs.receiverList.close();
            },

            getReceiverSelected(val){
                this.search.receiverId = val.id;
                this.receiverName = val.fullName;
            },

            openReceiver(){
                this.$refs.receiverList.open();
            },

            getReceivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.receivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            onPage(page){ 
                if(page > this.pager.totalPages){ 
                    return; 
                } 
                if(page == 0){ 
                    return; 
                } 
                this.search.pageIndex = page; 
                this.getData(); 
            }, 

            refresh(){ 
                this.search = { pageIndex: 1, pageSize: common.pageSize, status: '', code: '', receiverId: 0, delivererId: 0, fromDate: '', toDate: '' };
                this.delivererName = '';
                this.receiverName = ''; 
                this.getData(); 
            }, 

            filter(){ 
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize; 
                this.$router.push('/store-loan?' + url).catch(()=>{}); 

                this.getData(); 
            }, 

            getData(){ 
                this.loadding = true; 
                this.search.type = 2;
                storeExportService.filter(this.search).then((response) => { 
                    if(response.statusCode == 200){ 
                        this.data = response.data.items; 
                        this.pager = response.data.pager; 
                    } 
                    else{ 
                        messageBox.showWarning(response.data); 
                    } 
                }).finally(() => { this.loadding = false; }); 
            }, 

            confirmDelete(id){ 
                this.$modal.show('dialog', { 
                title: 'Thông báo', 
                text: 'Bạn có chắc muốn xóa không?', 
                buttons: [ 
                        { 
                            title: 'Hủy', 
                            handler: () => { 
                                this.$modal.hide('dialog') 
                            } 
                        }, 
                        { 
                            title: 'Xóa', 
                            handler: () => { 
                                this.del(id) 
                            } 
                       } 
                ] 
                }) 
            }, 

            del(id){ 
                this.$modal.hide('dialog'); 
                this.setLoading(true); 
                storeExportService.delete(id).then((response) => { 
                    if(response.statusCode == 200){ 
                        messageBox.showMessage("Xóa thành công"); 
                        this.refresh(); 
                    } 
                    else{ 
                        messageBox.showWarning(response.data); 
                    } 
                }).finally(() => { this.setLoading(false); }); 
            }, 

            add(){
                this.$router.push('/store-loan/crud');
            },
            view(id){
                this.$router.push('/store-loan/view/' + id);
            }
        }, 
        watch: {
            status: function () {
                this.search.status = String(this.status);
                if(this.status.length < 4){
                    this.selectedAllStatus = false;
                }
                else{
                    this.selectedAllStatus = true;
                }
                this.filter();
            },

            receiverName: function (val) { 
                if(val == ''){
                    this.search.receiverId = 0;
                }
            },
            delivererName: function (val) { 
                if(val == ''){
                    this.search.delivererId = 0;
                }
            },
            'search.pageIndex': function () { 
                this.filter(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.filter(); 
            },
            'search.code': function () { 
                this.search.pageIndex = 1; 
                this.filter(); 
            },
            'search.delivererId': function () { 
                this.search.pageIndex = 1; 
                this.filter(); 
            },
            'search.receiverId': function () {  
                this.search.pageIndex = 1; 
                this.filter(); 
            },
            'search.fromDate': function () {  
                this.search.pageIndex = 1; 
                this.filter(); 
            },
            'search.toDate': function () {  
                this.search.pageIndex = 1; 
                this.filter(); 
            } 
        } 
    } 

</script> 
