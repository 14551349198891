<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>{{ title }}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                   <div class="row">
                       <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                       </div>
                       <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="status">Trạng thái</label>
                                <md-select v-model="search.status" name="status" id="status">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option :value="1">Chưa thu</md-option>
                                    <md-option :value="2">Đã thu</md-option>
                                </md-select>
                            </md-field>
                        </div>
                       <div class="col l-3 m-3 c-12">
                           <md-field>
                               <label>Tìm theo mã đơn hàng/bill/booking/Số tờ khai</label>
                               <md-input v-model="search.keyword"></md-input>
                           </md-field>
                       </div>
                   </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 280px); overflow-x: auto; width: 100%;">
                        <table class="data-table">
                            <thead>
                                <tr>
                                    <th style="width:60px;">#</th>
                                    <th style="width:15%;">Mã đơn hàng</th>
                                    <th style="width:10%;">Bill/Booking</th>
                                    <th style="width:10%;">Số tờ khai</th>
                                    <th style="width:15%;">Khách hàng</th>
                                    <th style="width:15%;">Hãng tàu</th>
                                    <th style="width:10%;">Số lượng</th>
                                    <th style="width:10%;">Đơn giá</th>
                                    <th style="width:10%;">Thành tiền</th>
                                    <th style="width:10%;">Trạng thái</th>
                                    <th style="width:10%;">Ngày thu</th>
                                    <th style="width:10%;">Cập nhật</th>
                                    <th style="width:7%;">Hành động</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left">{{item.order.orderCode}}</td>
                                    <td class="left">{{item.order.booking.noBooking}}</td>
                                    <td class="left">{{item.order.customs.declarationCode}}</td>
                                    <td class="left">{{item.order.customer.companyAlias}}</td>
                                    <td class="left">{{item.company.companyAlias}}</td>
                                    <td class="right">{{formatNumber(item.quantity)}}</td>
                                    <td class="right">{{formatNumber(item.price)}}</td>
                                    <td class="right">{{formatNumber(item.amount)}}</td>
                                    <td class="center">
                                        <md-chip v-if="item.backDepositId > 0" style="width: 100px;" class="status status-done">Đã thu hồi</md-chip>
                                        <md-chip v-else  class="status status-temp" style="width: 100px;">Chưa thu hồi</md-chip>
                                    </td>
                                    <td class="center">{{ item.backDepositDate }}</td>
                                    <td class="center">{{ item.backDepositUser.fullName }}</td>
                                    <td class="action">
                                        <md-button @click="openEdit(item)" class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Cập nhật thu hồi tiền cược cont</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 500px;" class="row">
                    <div class="col l-12 m-12 c-12" style="display: flex; align-items: center;">
                        <div style="width: 120px;">Hãng tàu:</div>
                        <div style="font-weight: bold;">{{ obj.company.companyName }}</div>
                    </div>
                    <div class="col l-12 m-12 c-12" style="display: flex; align-items: center; padding-top: 15px;">
                        <div style="width: 120px;">Số tiền:</div>
                        <div style="font-weight: bold;">{{ formatNumber(obj.amount) }}</div>
                    </div>
                    <div class="col l-12 m-12 c-12" style="display: flex; align-items: center;">
                        <div style="width: 120px;">Ngày thu hồi:</div>
                        <div>
                            <md-datepicker v-model="entity.backDepositDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && entity.backDepositDate == null }">
                                <span class="md-error" v-if="submitted && entity.backDepositDate == null">Vui lòng chọn ngày</span>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submit()">Lưu</md-button>
                <md-button class="md-primary" @click="close()">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>

        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
    </div>
</template>
<script>
    import orderEstimateService from '../../../api/orderEstimateService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import companyService from '../../../api/companyService';
    import customerList from '../../../components/popup/_CompanyList.vue';

    export default {
        components: {
            customerList,
        },
        metaInfo: {
            title: 'Quản lý cược cont'
        },
        data() {
            return {
                loadding: false,
                customerType: common.companyType.customer,
                search: { pageIndex: 1, pageSize: common.pageSize, customerId: 0, status: 0, keyword: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                orderType: 1,
                title: 'Quản lý cược cont',
                showDialog: false,
                obj: { company: { companyName: '' } },
                entity: { id: 0, backDepositDate: common.dateNow },
                submitted: false,
                customerName: '',
                customers: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            close(){
                this.showDialog = false;
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
            
            openEdit(item){
                this.obj = item;
                this.entity.id = item.id;
                this.showDialog = true;  
            },

            submit(){
                this.submitted = true;
                if (this.entity.backDepositDate == '' || this.entity.backDepositDate == null) {
                    return;
                }
                this.setLoading(true);
                orderEstimateService.updateDepositCont(this.entity.id, this.entity.backDepositDate).then((response) => {
                    if(response.statusCode == 200){
                        this.filter();
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });

                this.showDialog = false;
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.pageIndex = 1;
                this.search.pageSize = 10;
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/deposit-cont?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/deposit-cont?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                orderEstimateService.getDepositCont(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
               
        },
        watch: {
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.customerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.status': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.keyword': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
