<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>{{ title }}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary md-search">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                   <div class="row">
                       <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="status">Trạng thái thu hồi</label>
                                <md-select v-model="search.status" name="status" id="status">
                                    <md-option :value="-1">Tất cả</md-option>
                                    <md-option :value="0">Chưa thu hồi</md-option>
                                    <md-option :value="1">Đã thu hồi</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="status">Chi phí sửa chữa</label>
                                <md-select v-model="search.repairStatus" name="status" id="status">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option :value="4">Không phát sinh</md-option>
                                    <md-option :value="1">Chi hộ</md-option>
                                    <md-option :value="2">Lái xe chi</md-option>
                                    <md-option :value="3">Chi phí công ty</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label>Tìm theo mã đơn hàng/bill/booking/Số tờ khai</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo số con't</label>
                                <md-input v-model="search.serial"></md-input>
                            </md-field>
                        </div>
                   </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 280px); overflow-x: auto; width: 100%;">
                        <table class="data-table" style="width: 3200px;">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width:50px;">#</th>
                                    <th rowspan="2" style="width:80px;">Hành động</th>
                                    <th rowspan="2" style="width:250px;">Mã đơn hàng</th>
                                    <th rowspan="2" style="width:200px;">Khách hàng</th>
                                    <th rowspan="2" style="width:150px;">Mã lô hàng</th>
                                    <th rowspan="2" style="width:200px;">Bill/Booking</th>
                                    <th rowspan="2" style="width:150px;">Tên hàng</th>
                                    <th rowspan="2" style="width:150px;">Số con't</th>
                                    <th rowspan="2" style="width:150px;">Số phiếu thu</th>
                                    <th rowspan="2" style="width:120px;">Ngày hạ rỗng</th>
                                    <th rowspan="2" style="width:200px;">Lý do</th>
                                    <th rowspan="2" style="width:200px;">Nơi hạ rỗng</th>
                                    <th rowspan="2" style="width:150px;">Trạng thái</th>
                                    <th rowspan="2" style="width:150px;">Người cập nhật</th>
                                    <th colspan="4" style="width:500px; background-color: #B4E380;">Số tiền cược</th>
                                    <th colspan="4" style="width:500px; background-color: #FFDE4D;">Chi phí sửa chữa</th>                                
                                </tr>
                                <tr>
                                    <th style="width:120px; background-color: #B4E380;">Đơn giá</th>
                                    <th style="width:100px; background-color: #B4E380;">Vat</th>
                                    <th style="width:120px; background-color: #B4E380;">Thành tiền</th>
                                    <th style="width:120px; background-color: #B4E380;">Trạng thái</th>

                                    <th style="width:120px; background-color: #FFDE4D;">Đơn giá</th>
                                    <th style="width:100px; background-color: #FFDE4D;">Vat</th>
                                    <th style="width:120px; background-color: #FFDE4D;">Thành tiền</th>
                                    <th style="width:120px; background-color: #FFDE4D;">Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td style="width: 80px;" class="action">
                                        <md-button @click="openEdit(item)" class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </td>
                                    <td class="left">{{item.order.orderCode}}</td>  
                                    <td class="left">{{item.order.customer.companyAlias}}</td>
                                    <td class="left">{{item.order.orderName}}</td>                              
                                    <td class="left">{{item.order.booking.noBooking}}</td>
                                    <td class="left">{{item.orderDetail.goods.goodsName}}</td>
                                    <td class="left">{{item.orderDetail.serial}}</td>
                                    <td class="left">{{item.invoiceNumber}}</td>
                                    <td class="left">{{item.invoiceDate}}</td>
                                    <td class="left">{{item.explainNote}}</td>
                                    <td class="left">{{item.orderDetail.placeOfDeliveryCont.locationName}}</td>
                                    <td class="center">
                                        <md-chip v-if="item.depositRepairId == 0 || item.depositRepairId == null"  class="status status-temp" style="width: 115px;">Chưa cập nhật</md-chip>
                                        <md-chip v-else style="width: 115px;" class="status status-done">Đã cập nhật</md-chip>
                                    </td>

                                    <td class="left">
                                        <span v-if="item.depositRepairId > 0">{{item.depositRepairUser.fullName}}</span>
                                    </td>

                                    <td class="right">{{formatNumber(item.explainPrice)}}</td>
                                    <td class="right">{{formatNumber(item.explainVat)}}%</td>
                                    <td class="right">{{formatNumber(item.explainAmount)}}</td>
                                    <td class="center">
                                        <md-chip v-if="item.backDepositStatus == 0"  class="status status-temp" style="width: 100px;">Chưa thu hồi</md-chip>
                                        <md-chip v-else-if="item.backDepositStatus == 1" style="width: 100px;" class="status status-done">Đã thu hồi</md-chip>
                                    </td>

                                    <td class="right">{{formatNumber(item.depositRepairPrice)}}</td>
                                    <td class="right">{{formatNumber(item.depositRepairVat)}}%</td>
                                    <td class="right">{{formatNumber(item.depositRepairAmount)}}</td>

                                    <td class="center">
                                        <md-chip v-if="item.depositRepairStatus == 1" style="width: 115px;" class="status status-done">Chi hộ</md-chip>
                                        <md-chip v-else-if="item.depositRepairStatus == 2" style="width: 115px;" class="status status-inprogress">Lái xe chi</md-chip>
                                        <md-chip v-else-if="item.depositRepairStatus == 3" class="status status-red" style="width: 115px;">Chi phí Công ty</md-chip>
                                        <md-chip v-else-if="item.depositRepairStatus == 4"  class="status status-temp" style="width: 115px;">Không phát sinh</md-chip>
                                        <md-chip v-else class="status status-temp" style="width: 115px;">Chưa cập nhật</md-chip>
                                    </td>                              
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 0">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <crud ref="crudForm" @refresh="refresh" />
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
    </div>
</template>
<script>
    import jobEstimateService from '../../../api/jobEstimateService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import crud from './Crud.vue';
    import companyService from '../../../api/companyService';
    import customerList from '../../../components/popup/_CompanyList.vue';

    export default {
        components: {
            crud,
            customerList
        },
        metaInfo: {
            title: 'Quản lý cược sửa chữa'
        },
        data() {
            return {
                loadding: false,
                customerType: common.companyType.customer,
                search: { pageIndex: 1, pageSize: common.pageSize, customerId: 0, status: -1, repairStatus: 0, keyword: '', serial: ''},
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                orderType: 1,
                title: 'Quản lý cược sửa chữa',
                showDialog: false,
                customerName: '',
                customers: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.filter();
            },
            
            openEdit(item){
                this.$refs.crudForm.open(item);
                this.showDialog = true;  
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.pageIndex = 1;
                this.search.pageSize = 10;
                this.search.status = -1;
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/deposit-repair?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/deposit-repair?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                jobEstimateService.getDepositRepair(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
            
            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }

</script>
