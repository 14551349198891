import httpClient  from '.';
const API_CONTROLLER = 'report-dashboard/';

export default {
    getDashboard() {
        let url = API_CONTROLLER + 'get-dashboard';
        return httpClient.get(url);
    },

    getOrderChart() {
        let url = API_CONTROLLER + 'get-order-chart';
        return httpClient.get(url);
    },

    getJobChart() {
        let url = API_CONTROLLER + 'get-job-chart';
        return httpClient.get(url);
    },

    getStatsOrder() {
        let url = API_CONTROLLER + 'get-report-stats-order';
        return httpClient.get(url);
    },

    getStatsJob() {
        let url = API_CONTROLLER + 'get-report-stats-job';
        return httpClient.get(url);
    },

    getStatsProjectHeavy() {
        let url = API_CONTROLLER + 'get-report-stats-project-heavy';
        return httpClient.get(url);
    },

    getStatsOrderHeavy() {
        let url = API_CONTROLLER + 'get-report-stats-order-heavy';
        return httpClient.get(url);
    },

    getStatsJobHeavy() {
        let url = API_CONTROLLER + 'get-report-stats-job-heavy';
        return httpClient.get(url);
    },

    getStatsStore() {
        let url = API_CONTROLLER + 'get-report-stats-store';
        return httpClient.get(url);
    },

    getStatsOrderEstimate() {
        let url = API_CONTROLLER + 'get-report-stats-order-estimate';
        return httpClient.get(url);
    },

    getStatsRepair() {
        let url = API_CONTROLLER + 'get-report-stats-repair';
        return httpClient.get(url);
    },
}