import Vue from 'vue';
import moment from 'moment';
import permissionService from '@/api/permissionService';

export default Vue.mixin({
    methods: {
        getPlanHeavyList: function (value) {
            if(value == 1){
                return "Đường bộ"
            }
            else if(value == 2){
                return "Hàng sắt"
            }
            else if(value == 3){
                return "Hàng hàng không"
            }
            else if(value == 4){
                return "Hàng biển"
            }
            else if(value == 5){
                return "Hàng thủy"
            }
        },
        getPaymentType: function (value) {
            if(value == 1){
                return "Tạm ứng"
            }
            else if(value == 2){
                return "Thanh toán đối tác"
            }
            else if(value == 3){
                return "Thanh toán nhân viên"
            }
            else if(value == 4){
                return "Xuất kho"
            }
        },
        getRangeDate: function (value) {
            return moment().add(value, 'days').format("DD-MM-YYYY");
        },
        getRevenueType: function (value) {
            if(value == 1){
                return "Phải trả làm hàng"
            }
            else if(value == 2){
                return "Phải trả vận chuyển"
            }
            else if(value == 3){
                return "Phải trả vendor"
            }
            else if(value == 4){
                return "Cước vận chuyển"
            }
        },
        getGoodsTypeHeavy: function (value) {
            if(value == 1){
                return "Hàng cont"
            }
            else if(value == 3){
                return "Hàng rời"
            }
            else if(value == 5){
                return "Hàng STST"
            }
        },
        getGoodsType: function (value) {
            if(value == 1){
                return "Hàng cont"
            }
            else if(value == 2){
                return "Hàng lẻ"
            }
            else if(value == 3){
                return "Hàng rời"
            }
            else if(value == 4){
                return "Hàng air"
            }
        },
        getPlanOfTransport: function (value) {
            if(value == 1){
                return "Vận chuyển hàng container"
            }
            else if(value == 2){
                return "Vận chuyển hàng lẻ/rời"
            }
        },

        getRequireOfTransport: function (value) {
            if(value == 1){
                return "Nội địa"
            }
            else if(value == 2){
                return "Quốc tế"
            }
        },

        getTypeOfTransport: function (value) {
            if(value == 1){
                return "Hàng xuất"
            }
            else if(value == 2){
                return "Hàng nhập"
            }
            else if(value == 3){
                return "Hàng door-door"
            }
            else if(value == 4){
                return "Hàng chuyển cảng"
            }
            else if(value == 5){
                return "Hàng chuyển kho"
            }
            else if(value == 6){
                return "Chuyển rỗng"
            }
        },
        getFeeType: function (value) {
            if(value == 1){
                return "Chi phí"
            }
            else if(value == 2){
                return "Chi hộ"
            }
            else if(value == 3){
                return "Phí dịch vụ"
            }
            else if(value == 4){
                return "Lương lái xe"
            }
            else if(value == 5){
                return "Cước vận chuyển"
            }
            else if(value == 6){
                return "VETC"
            }
            else if(value == 7){
                return "Cược cont"
            }
            else if(value == 8){
                return "Cược sửa chữa"
            }
            else if(value == 9){
                return "Lương cố định"
            }
            else if(value == 10){
                return "Chi phí khấu hao"
            }
            else if(value == 11){
                return "Chi phí cố định"
            }
            else if(value == 12){
                return "Biến phí"
            }
        },
        formatNumber: function (value) {
            let val = (value/1).toFixed(2).replace(',', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        formatFloat: function (value, decimal = 2) {
            let val = (value/1).toFixed(decimal).replace(',', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        romanize: function (num) {
            if (isNaN(num))
                return NaN;
            var digits = String(+num).split(""),
                key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                    "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                    "","I","II","III","IV","V","VI","VII","VIII","IX"],
                roman = "",
                i = 3;
            while (i--)
                roman = (key[+digits.pop() + (i * 10)] || "") + roman;
            return Array(+digits.join("") + 1).join("M") + roman;
        },
        getFakeId: function (id) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );

            return uuid + "-" + id;
        },
        getPageRole: async function(moduleCode, action){
            const userId = localStorage.getItem("userId");
            var res = await permissionService.getPageRole(userId, moduleCode);
            if(res.statusCode == 200){
                if(action == "READ"){
                    if(res.data.read == false){
                        this.$router.push('/access-denied');
                    }
                }
                else if(action == "ADD"){
                    if(res.data.add == false){
                        this.$router.push('/access-denied');
                    }
                }
                else if(action == "EDIT"){
                    if(res.data.edit == false){
                        this.$router.push('/access-denied');
                    }
                }
                return res.data;
            }
            else{
                return { read: false, add: false, edit: false, delete: false };
            }
        }
    },
})


