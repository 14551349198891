import { render, staticRenderFns } from "./Profit.vue?vue&type=template&id=48588ebe&scoped=true"
import script from "./Profit.vue?vue&type=script&lang=js"
export * from "./Profit.vue?vue&type=script&lang=js"
import style0 from "./Profit.vue?vue&type=style&index=0&id=48588ebe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48588ebe",
  null
  
)

export default component.exports