import { render, staticRenderFns } from "./_AreaList.vue?vue&type=template&id=22fc61a8&scoped=true"
import script from "./_AreaList.vue?vue&type=script&lang=js"
export * from "./_AreaList.vue?vue&type=script&lang=js"
import style0 from "./_AreaList.vue?vue&type=style&index=0&id=22fc61a8&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22fc61a8",
  null
  
)

export default component.exports