<template>
    <md-dialog id="explain-order-estimate" :md-fullscreen="true"  :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @click="submit()" class="md-raised md-primary">Lưu giải chi<md-tooltip>Giải chi</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="staffName">Nhân viên</label>
                                        <md-input disabled  name="staffName" v-model="staffName"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                                        <label for="code">Mã dự toán</label>
                                        <md-input disabled  name="code" v-model="entity.estimateCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập mã dự toán</span>
                                    </md-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="code">Ngày dự toán</label>
                                        <md-input disabled  name="estimateDate" v-model="entity.estimateDate"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div style="display:flex; width: 100%;">
                                        <div style="width: 25%;">
                                            <md-field>
                                                <label for="note">Tổng giải chi</label>
                                                <md-input disabled name="pay" :value="formatNumber(entity.explainPay)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Chi phí</label>
                                                <md-input disabled name="payFee" :value="formatNumber(entity.explainFee)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 25%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Chi hộ</label>
                                                <md-input disabled name="payOnBehalf" :value="formatNumber(entity.explainOnBehalf)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 22%; margin-left: 5px;">
                                            <md-field>
                                                <label for="note">Dịch vụ</label>
                                                <md-input disabled name="payOnBehalf" :value="formatNumber(entity.explainService)" ></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết dự toán">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <div class="list" style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 1600px !important;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:70px;">#</th> 
                                                                        <th rowspan="2" style="width:230px;">Tên phí</th> 
                                                                        <th rowspan="2" style="width:120px;">Loại</th> 
                                                                        <th rowspan="2" style="width:100px;">Số lượng</th>
                                                                        <th colspan="3" style="width:320px; background-color: #96C9F4">Dự toán</th>
                                                                        <th colspan="6" style="width:720px; background-color: #F6E6CB">Giải chi</th>
                                                                    </tr> 
                                                                    <tr>
                                                                        <th style="width:120px; background-color: #96C9F4;">Đơn giá</th>
                                                                        <th style="width:80px; background-color: #96C9F4;">VAT</th>
                                                                        <th style="width:120px; background-color: #96C9F4;">Thành tiền</th>
                                                                        <th style="width:120px; background-color: #F6E6CB;">Đơn giá</th>
                                                                        <th style="width:80px; background-color: #F6E6CB;">VAT</th>
                                                                        <th style="width:150px; background-color: #F6E6CB;">Thành tiền</th>
                                                                        <th style="width:120px; background-color: #F6E6CB;">Số hóa đơn</th>
                                                                        <th style="width:150px; background-color: #F6E6CB;">Ngày hóa đơn</th>
                                                                        <th style="width:150px; background-color: #F6E6CB;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="center">{{index + 1}}</td>
                                                                        <td class="center">
                                                                            <div class="grid-control">
                                                                                <input v-model="item.fee.feeName" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                                <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                                    <md-icon>manage_search</md-icon>
                                                                                    <md-tooltip>Tìm phí</md-tooltip>
                                                                                </md-button>
                                                                            </div>
                                                                        </td> 
                                                                        <td class="center">
                                                                            <md-field>
                                                                                <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                </md-select>
                                                                            </md-field>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number :disabled="item.id > 0" @change="calSum2(item)" v-model="item.quantity" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-bind="numberF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number disabled v-model="item.price" :class="{'is-error': $v.entity.details.$each[index].price.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number disabled v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number disabled v-model="item.amount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.explainPrice" :class="{'is-error': $v.entity.details.$each[index].explainPrice.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.explainVat" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumAmount(item)" v-model="item.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.billNumber" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <md-datepicker v-model="item.billDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <feeList ref="feeList" @close="closeFee"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import staffService from '../../../api/staffService';
import orderEstimateService from '../../../api/orderEstimateService';
import feeList from '../../../components/popup/_FeeList.vue';

export default ({
    components: {
        feeList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFee,
            order: {},
            entity: { id: 0, orderId: 0, order: { }, details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: '', payOnBehalf: 0, payFee: 0 },
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        calSum2(item){
            const selected = this.entity.details.findIndex(x => x.id == item.id);
            let price = parseFloat(this.entity.details[selected].price);
            let vat = price * (parseFloat(this.entity.details[selected].vat) / 100);
            this.entity.details[selected].amount = Math.round(price + vat) * parseFloat(this.entity.details[selected].quantity);

            this.calSummary();
        },
        
        addRow(){
            if(this.entity.details.length > 0){
                let last = this.entity.details.slice(-1).pop();
                let item = { 
                    id: 'id_' + common.getFakeId(), 
                    orderId: this.$route.params.id, 
                    fee: last.fee, 
                    feeId: last.feeId, 
                    type: last.type, 
                    quantity: 1, 
                    price: 0, 
                    vat: 0, 
                    amount: 0, 
                    note: '' ,
                    explainPrice: 0,
                    explainVat: 0,
                    explainAmount: 0
                };
                this.entity.details.push(item);
            }
            else{
                let item = { id: 'id_' + common.getFakeId(), orderId: this.$route.params.id, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 0, price: 0, vat: 0, amount: 0, note: '' };
                this.entity.details.push(item);
            }
        },
        
        delRow(item){
            const index = this.entity.details.findIndex(x => x.id == item.id);
            this.entity.details.splice(index, 1);
            this.calSummary();
        },

        calSummary(){
            this.entity.explainPay = 0;
            this.entity.explainService = 0;
            this.entity.explainOnBehalf = 0;
            this.entity.explainFee = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                if(parseFloat(this.entity.details[i].type) == 1){
                    this.entity.explainFee = this.entity.explainFee + parseFloat(this.entity.details[i].explainAmount);
                }
                if(parseFloat(this.entity.details[i].type) == 2){
                    this.entity.explainOnBehalf = this.entity.explainOnBehalf + parseFloat(this.entity.details[i].explainAmount);
                }
                if(parseFloat(this.entity.details[i].type) == 3){
                    this.entity.explainService = this.entity.explainService + parseFloat(this.entity.details[i].explainAmount);
                }
            }
            this.entity.explainPay = this.entity.explainFee + this.entity.explainService + this.entity.explainOnBehalf;
        },

        calSumAmount(item){
            item.explainPrice = common.calPrice(item.quantity, item.explainVat, item.explainAmount);

            this.calSummary();
        },

        calSum(item){
            item.explainAmount = common.calAmount(item.quantity, item.explainPrice, item.explainVat);

            this.calSummary();
        },

        calSumAmount2(item){
            let amountNotVat = parseFloat(item.price) * parseFloat(item.quantity);
            let vatAmount = parseFloat(item.amount) - amountNotVat;
            item.vat = (vatAmount / amountNotVat) * 100;
            this.calSummary();
        },

        open(id, order){
            this.order = order;
            this.id = id;
            if(id > 0){
                this.title = 'Giải chi dự toán';
                this.getById();
            }
            this.showDialog = true;
        },
        
        close(){
            this.showDialog = false;
        },

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.details.length > 0){
                this.entity.details = this.entity.details.filter(item => !(item.feeId == 0));
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.id > 0){
                this.explain();
            }
        },

        explain(){
            this.setLoading(true);
            this.entity.isExplain = true;
            orderEstimateService.explain(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            orderEstimateService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.staffName = response.data.staff.fullName;
                    response.data.details.map((item) => {
                        if(response.data.isExplain == false){
                            item.explainPrice = item.price;
                            item.explainVat = item.vat;
                            item.explainAmount = item.amount;
                        }
                    });
                    this.entity = response.data;
                    this.calSummary();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.approve.staffId = response.data.id;
                        this.approve.staff = response.data;
                        this.approve.deptId = response.data.department.id;
                        this.approve.dept = response.data.department;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Fee
        closeFee(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].fee = item;
            this.entity.details[selected].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = '';
        },

        openFee(id){
            this.selectedId = id;
            this.$refs.feeList.open();
        },
    },
    validations: {
        entity: {
            estimateCode: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            estimateDate: { required },
            details: {
                $each: {
                    feeId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    quantity: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    price: {
                        required,
                        isSelected(value) {
                            if (value >= 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    explainPrice: {
                        required,
                        isSelected(value) {
                            if (value >= 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    explainAmount: {
                        required
                    },
                }
            }
        }
    }
})
</script>

<style>
    #explain-order-estimate .md-dialog-container {
        max-width: 95% !important;
    }
</style>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        max-width: 100%;
        width: 100%;
    }
    .page-content {
        min-height: 600px;
    }
</style>