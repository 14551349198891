<template>
    <div>
        <div class="grid">
            <div class="row">
                <div class="col l-6 m-6 c-12">
                    <div class="form-control" style="padding-top:20px;">
                        <span class="btn-file"> Chọn file
                            <input type="file" id="fileUpload" multiple ref="fileUpload" v-on:change="upload()">
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" style="padding: 30px 0;">
                <div class="col l-12 m-12 c-12">
                    <table class="data-table" style="min-height: 150px !important;">
                        <thead>
                            <tr>
                                <th style="width: 80px;">#</th>
                                <th style="width: 140px;">Ảnh</th>
                                <th style="width: calc(100vw - 280px);">Tên file</th>
                                <th style="width: 15%;">Ngày tạo</th>
                                <th style="width: 60px;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="entity.files.length == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có file</span></td></tr>
                        </tbody>
                        <tbody>
                            <tr v-for="(item, index) in entity.files" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <a @click="view(item)">
                                        <img v-if="item.fileExt == 'pdf' || item.fileExt == 'PDF'" class="box-img" src="../../../assets/images/pdf.svg" />
                                        <img v-else-if="item.fileExt == 'xlsx' || item.fileExt == 'XLS' || item.fileExt == 'xls' || item.fileExt == 'XLSX'" class="box-img" src="../../../assets/images/excel.svg" />
                                        <img v-else-if="item.fileExt == 'docx' || item.fileExt == 'DOCX'" class="box-img" src="../../../assets/images/word.svg" />
                                        <img v-else class="box-img" :src="item.fullPath" />
                                    </a>
                                </td>
                                <td class="left"><a @click="view(item)">{{item.fileName}}</a></td>
                                <td class="center">{{item.createdTime}}</td>
                                <td class="action">
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import uploadService from '@/api/uploadService';
    import orderService from '../../../api/orderService';
    import objectFileService from '../../../api/objectFileService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        data() {
            return {
                submitted: false,
                loadding: false,
                orderId: 0,
                entity: { details: [] }
            }
        },

        created() {
            this.orderId = this.$route.params.id;
            this.getById();
        },

        methods: {
            ...mapActions('common', ['setLoading']),

            view(obj){
                const link = document.createElement('a')
                link.href = obj.fullPath;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            upload() {
                for(let i = 0; i < this.$refs.fileUpload.files.length; i ++){
                    let file = this.$refs.fileUpload.files[i];
                    this.setLoading(true);
                    let formData = new FormData();
                    formData.append('files[0]', file);

                    uploadService.uploadFile(formData).then((response) => {
                        if(response.data.statusCode == 200){
                            this.add(response.data.data.id)
                        }
                        else {
                            messageBox.showWarning(response.data.data);
                        }
                    }).finally(() => { this.setLoading(false)});
                }
            },

            add(fileId){
                this.setLoading(true);
                let data = {
                    objectId: this.orderId,
                    fileId: fileId,
                    objectType: 10,
                };
                objectFileService.add(data).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Upload thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                orderService.getById(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                objectFileService.delete(id, 10).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    }
</script>

<style scoped>
    .box-img {
        width: 120px;
        height: 60px;
    }
</style>