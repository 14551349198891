import httpClient  from '../api';
const API_CONTROLLER = 'permission/';

export default {
    getMenu() {
        let url = API_CONTROLLER + 'get-menu';
        return httpClient.get(url);
    },

    getByRoleId(roleId) {
        let url = API_CONTROLLER + 'get-by-role-id';
        return httpClient.get(url, {
            params: {
                roleId: roleId
            }
        });
    },

    getPageRole(userId, moduleCode) {
        let url = API_CONTROLLER + 'get-page-role';
        return httpClient.get(url, {
            params: {
                userId: userId,
                moduleCode: moduleCode
            }
        });
    },

    save(data) {
        let url = API_CONTROLLER + 'save';
        return httpClient.post(url, data);
    }
}