<template>
    <div>
        <div class="row">
            <div class="col l-12 m-12 c-12">
                <div class="tool-bar">
                    <md-button @click="openDistribute()" class="md-primary"><md-icon>minor_crash</md-icon>Nhập phân bổ<md-tooltip>Phân bổ hàng</md-tooltip></md-button>
                    <md-button @click="openTransport()" class="md-primary"><md-icon>minor_crash</md-icon>Nhập cước thu KH<md-tooltip>Nhập cước thu khách hàng</md-tooltip></md-button>
                    <md-button @click="loadSetingFeeTransport()" class="md-primary"><md-icon>paid</md-icon> Tải cước phí vận chuyển<md-tooltip>Tải thiết lập cước phí vận chuyển</md-tooltip></md-button>
                    <md-button @click="loadSetingFeeDistribute()" class="md-primary"><md-icon>paid</md-icon> Tải cước phí phân bổ<md-tooltip>Tải thiết lập cước phí phân bổ</md-tooltip></md-button>
                    <md-button @click="openAllSubExplain()" class="md-primary"><md-icon>paid</md-icon>Thanh toán Vendor phụ<md-tooltip>thanh toán cho tất cả vendor</md-tooltip></md-button>
                     <md-button @click="confirmDeleteAllSubVendor()" class="md-primary" style="color: red;"><md-icon style="color: red;">delete_outline</md-icon>Xóa tất cả Vendor phụ<md-tooltip>Xóa tất cả thanh toán Vendor phụ</md-tooltip></md-button>
                </div>
                <div class="table-fixed" style="height: calc(100vh - 220px); overflow-x: auto; width: 100%;">
                    <table class="data-table-2 distribute" style="width: 2550px; margin-bottom: 0;"> 
                        <thead> 
                            <tr> 
                                <th rowspan="2" style="width:60px; background-color: #eee; ">#</th> 
                                <th rowspan="2" style="width:60px; vertical-align: middle; background-color: #eee; ">
                                    <md-checkbox v-model="selectAll"></md-checkbox>
                                </th> 
                                <th colspan="3" class="center" style="width:360px;background-color: #D2E0FB;">Vendor phụ</th>
                                <th colspan="2" style="width:300px;background-color: #FFCCE1;">Xe ngoài</th>
                                <th colspan="4" style="width:500px; background-color: #FFD18E ;">Phân bổ vận tải</th>
                                <th colspan="3" style="width:360px; background-color: #95D2B3;">Cước thu KH</th>
                                <th rowspan="2" style="width:200px;background-color: #eee;">Tên hàng</th>
                                <th rowspan="2" style="width:100px;background-color: #eee;">Đơn vị</th> 
                                <th rowspan="2" style="width:150px;background-color: #eee;">Số con't</th> 
                                <th rowspan="2" style="width:350px;background-color: #eee;">Tuyến đường</th> 
                                <th rowspan="2" style="width:150px;background-color: #eee;">Loại hình</th> 
                                <th rowspan="2" style="width:150px;background-color: #eee;">Loại hàng</th>
                                <th rowspan="2" style="width:100px;background-color: #eee;">Số seal</th>
                            </tr>
                            <tr> 
                                <th style="width:120px;background-color: #D2E0FB;">Thanh toán</th> 
                                <th style="width:120px;background-color: #D2E0FB;">Trạng thái</th>
                                <th class="left" style="width:120px;background-color: #D2E0FB;">Đơn vị</th> 

                                <th style="width:120px;background-color: #FFCCE1;">Thanh toán</th> 
                                <th style="width:120px;background-color: #FFCCE1;">Trạng thái</th>

                                <th class="left" style="width:150px; background-color: #FFD18E;">Đơn vị vận tải</th>
                                <th class="right" style="width:120px; background-color: #FFD18E; ">Cước phí</th>
                                <th class="center" style="width:120px; background-color: #FFD18E; ">VAT</th>
                                <th class="right" style="width:120px; background-color: #FFD18E; ">Thành tiền</th>

                                <th class="right" style="width:120px; background-color: #95D2B3;">Cước phí</th>
                                <th class="center" style="width:120px; background-color: #95D2B3;">VAT</th>
                                <th class="right" style="width:120px; background-color: #95D2B3;">Thành tiền</th>
                            </tr>
                        </thead> 
                        <tbody v-if="loadding == true" style="height: 100px;"> 
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && entity.details.length > 0"> 
                            <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <md-checkbox v-model="checkedList" :value="item.id"></md-checkbox>
                                </td>
                                <td class="action" style="width: 100px;text-align: center">
                                    <md-button @click="openRefundSubVendor(item)" class="md-fab md-mini md-view">
                                        <i class="uil uil-usd-circle"></i>
                                        <md-tooltip>Thanh toán Vendor phụ</md-tooltip>
                                    </md-button>
                                </td>
                                <td class="center" style="width: 150px;">
                                    <md-chip v-if="item.subDistributeId > 0" class="md-primary">Đã thanh toán</md-chip>
                                    <md-chip v-else>Chưa thanh toán</md-chip>
                                </td>
                                <td class="left" style="width: 150px;">
                                    <span v-if="item.subDistributeId > 0">{{ item.subVendor.companyAlias }}</span>
                                    <span v-else>Không có</span>
                                </td> 
                                <td class="action" style="width: 150px;text-align: center">
                                    <md-button v-if="item.distributeId !== 1" @click="openRefund(item)" class="md-fab md-mini md-view">
                                        <i class="uil uil-usd-circle"></i>
                                        <md-tooltip>Thanh toán vendor</md-tooltip>
                                    </md-button>
                                    <md-button v-else disabled class="md-fab md-mini md-view">
                                        <i class="uil uil-usd-circle"></i>
                                        <md-tooltip>Thanh toán vendor</md-tooltip>
                                    </md-button>
                                </td>
                                <td class="center" style="width: 120px;">
                                    <md-chip v-if="item.refundAmount > 0" class="md-primary">Đã thanh toán</md-chip>
                                    <md-chip v-else>Chưa thanh toán</md-chip>
                                </td>
                                <td class="left" style="width: 120px;">
                                    <span v-if="item.distributeId > 0">{{ item.distribute.companyAlias }}</span>
                                    <span v-else style="color: red;">Chưa phân bổ</span>
                                </td>
                                <td class="right" style="width: 120px;">
                                    {{ formatNumber(item.distributePrice) }}
                                </td>
                                <td class="center" style="width: 120px;">
                                    {{ formatNumber(item.distributeVat) }}
                                </td>
                                <td class="right" style="width: 150px;">
                                    <span>{{ formatNumber(item.distributeAmount) }}</span>
                                </td>
                                <td class="right">
                                    {{ formatNumber(item.transportPrice) }}
                                </td>
                                <td class="center">
                                    {{ formatNumber(item.transportVat) }}
                                </td>
                                <td class="right">
                                    <span>{{ formatNumber(item.transportAmount) }}</span>
                                </td>
                                <td class="left">
                                    {{item.goods.goodsName}}
                                </td>
                                <td class="center">
                                    {{item.unit.unitName}}
                                </td>
                                <td class="left">
                                    {{item.serial}}
                                </td>
                                <td class="left" style="padding: 0 5px;">
                                    {{  getLocation(item)  }}
                                </td> 
                                <td class="center">
                                    {{ getTypeOfTransport(item.typeOfTransport) }}
                                </td> 
                                <td class="center">
                                    {{ getGoodsType(item.goodsTypeId) }}
                                </td>
                                <td class="center">
                                    {{item.seal}}
                                </td>
                            </tr> 
                        </tbody> 
                    </table>
                </div>
            </div>
        </div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Phân bổ hàng hóa</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 450px;" class="row">
                    <div class="col l-12 m-12 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="assignName" @md-selected="getAssignSelected" :md-options="assigns" @md-changed="getAssigns"  @md-opened="getAssigns" :class="{'md-invalid': submitted && assignId == 0 }">
                                <label>Đơn vị vận chuyển</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                                <span class="md-error" v-if="submitted && assignId == 0">Vui lòng chọn đơn vị vận chuyển</span>
                            </md-autocomplete>
                            <md-button @click="openAssign()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                            </md-button>
                        </div>
                        <md-field class="number-control">
                            <div>Cước phí:</div>
                            <number @change="calDistribute()" name="distributePrice" v-model="distributePrice" v-bind="currencyF2"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>VAT(%):</div>
                            <number @change="calDistribute()" name="distributeVat" v-model="distributeVat" v-bind="vatF"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>Thành tiền:</div>
                            <number disabled name="distributeAmount" v-model="distributeAmount" v-bind="currencyF"></number> 
                        </md-field>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submitDistribute()">Lưu</md-button>
                <md-button class="md-primary" @click="close()">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showDialogTransport" :md-click-outside-to-close="false" style="z-index: 9998;">
            <md-dialog-title>Cước thu khách hàng</md-dialog-title>
            <md-content class="md-scrollbar">
                <div style="width: 450px;" class="row">
                    <div class="col l-12 m-12 c-12">
                        <md-field class="number-control">
                            <div>Cước phí:</div>
                            <number @change="calTransport()" name="transportPrice" v-model="transportPrice" v-bind="currencyF2"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>VAT(%):</div>
                            <number @change="calTransport()" name="transportVat" v-model="transportVat" v-bind="vatF"></number> 
                        </md-field>
                        <md-field class="number-control">
                            <div>Thành tiền:</div>
                            <number disabled name="transportAmount" v-model="transportAmount" v-bind="currencyF"></number> 
                        </md-field>
                    </div>
                </div>
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submitTransport()">Lưu</md-button>
                <md-button class="md-primary" @click="showDialogTransport = false">Đóng</md-button>
            </md-dialog-actions>
        </md-dialog>

        <assignList ref="assignList" title="công ty vận tải" @close="closeAssign"/>
        <crudRefundAllSubVendorDetailForm ref="crudRefundAllSubVendorDetailForm" @refresh="getById()"/>
        <crudRefundSubVendorDetail ref="crudRefundSubVendorDetail" @refresh="getById()"/>
        <crudRefundDetailForm ref="crudRefundDetailForm" @refresh="getById()"/>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import messageBox from '../../../utils/messageBox';
    import orderService from '../../../api/orderService';
    import settingDebtService from '../../../api/settingDebtService';
    import refundService from '../../../api/refundService';
    import companyService from '../../../api/companyService';
    import assignList from '../../../components/popup/_TransportList.vue';
    import crudRefundAllSubVendorDetailForm from './CrudRefundAllSubVendorDetail.vue';
    import crudRefundSubVendorDetail from './CrudRefundSubVendorDetail.vue';
    import crudRefundDetailForm from './CrudRefundDetail.vue';

    export default {
        components: {
            assignList,
            crudRefundDetailForm,
            crudRefundAllSubVendorDetailForm,
            crudRefundSubVendorDetail
        },
        data() {
            return {
                submitted: false,
                submittedTransport: false,
                loadding: false,
                selectAll: false,
                checkedList: [],
                orderId: 0,
                showDialog: false,
                assigns: [],
                assignName: '',
                assignId: 0,
                distributeAmount: 0,
                distributePrice: 0,
                distributeVat: 0,
                currencyF: common.currencyF,
                vatF: common.vatF,
                showDialogTransport: false,
                transportAmount: 0,
                transportPrice: 0,
                transportVat: 0,
                entity: { details: [] },
                assign: {}
            }
        },
        created(){
            this.orderId = this.$route.params.id;
            this.getById();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            openDistribute(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                else{
                    this.showDialog = true;
                }
            },

            openTransport(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                else{
                    this.showDialogTransport = true;
                }
            },

            openRefund(obj){
                if(obj.distributeId == null || obj.distributeId == 0){
                    messageBox.showWarning("Vui lòng phân bổ đơn vị ngoài trước khi thực hiện giải chi");
                }
                else{
                    this.$refs.crudRefundDetailForm.open(obj);
                }
            },

            openRefundSubVendor(obj){
                this.$refs.crudRefundSubVendorDetail.open(obj);
            },

            openAllSubExplain(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                this.$refs.crudRefundAllSubVendorDetailForm.open(this.checkedList);
            },

            confirmDeleteAllSubVendor(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                else{
                    this.$modal.show('dialog', {
                        title: 'Thông báo',
                        text: 'Bạn có chắc muốn xóa không?',
                        buttons: [
                                {
                                    title: 'Hủy',
                                    handler: () => {
                                        this.$modal.hide('dialog')
                                    }
                                },
                                {
                                    title: 'Xóa',
                                    handler: () => {
                                        this.delAllSubVendor()
                                    }
                                }
                        ]
                    })
                }
            },

            delAllSubVendor(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                refundService.deleteAllSubVendor(this.checkedList.toString()).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getById();
                        this.checkedList = [];
                        this.$modal.hide('dialog')
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            loadSetingFeeDistribute(){
                this.setLoading(true);
                settingDebtService.getSettingForDistribute(this.id).then((response) => {
                    if(response.statusCode == 200){
                        response.data.map(item => {
                            const selected = this.entity.details.findIndex(x => x.id == item.id);
                            if(selected > -1){
                                this.entity.details[selected].distributeAmount = item.distributeAmount;
                                this.entity.details[selected].distributeVat = item.distributeVat;
                                this.entity.details[selected].distributePrice = item.distributePrice;
                            }
                        });
                        messageBox.showInfo("Tải cước phí phân bổ xong");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            loadSetingFeeTransport(){
                this.setLoading(true);
                settingDebtService.getSettingByOrder(this.id).then((response) => {
                    if(response.statusCode == 200){
                        response.data.map(item => {
                            const selected = this.entity.details.findIndex(x => x.id == item.id);
                            if(selected > -1){
                                this.entity.details[selected].transportAmount = item.transportAmount;
                                this.entity.details[selected].transportVat = item.transportVat;
                                this.entity.details[selected].transportPrice = item.transportPrice;
                            }
                        });
                        messageBox.showInfo("Tải cước phí xong");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            submitTransport(){
                this.submittedTransport = true;
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }
                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.entity.details.findIndex(x => x.id == this.checkedList[i]);
                    this.entity.details[selected].transportPrice = this.transportPrice;
                    this.entity.details[selected].transportVat = this.transportVat;
                    this.entity.details[selected].transportAmount = this.transportAmount;
                }
                this.update();
            },

            submitDistribute(){
                this.submitted = true;
                if (this.assignId == 0) {
                    return;
                }
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng");
                    return;
                }

                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.entity.details.findIndex(x => x.id == this.checkedList[i]);
                    this.entity.details[selected].distribute = this.assign;
                    this.entity.details[selected].distributeId = this.assignId;
                    this.entity.details[selected].distributePrice = this.distributePrice;
                    this.entity.details[selected].distributeVat = this.distributeVat;
                    this.entity.details[selected].distributeAmount = this.distributeAmount;
                    this.entity.details[selected].submitDistributeId = this.currencyUserId;
                    this.entity.details[selected].isPpl = this.assignId == 1;
                    this.entity.details[selected].distributeDate = new Date();
                }
                this.update();
            },

            update(){
                this.setLoading(true);
                orderService.updateOrderDetails(this.entity.details).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                        this.showDialog = false;
                        this.showDialogTransport = false;
                        this.showDialogSalan = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            calTransport(){
                this.transportAmount = parseFloat(this.transportPrice) + (parseFloat(this.transportPrice) * (parseFloat(this.transportVat) / 100));
            },

            calDistribute(){
                this.distributeAmount = parseFloat(this.distributePrice) + (parseFloat(this.distributePrice) * (parseFloat(this.distributeVat) / 100));
            },

            //Assign
            closeAssign(item){
                this.assignName = item.companyAlias;
                this.assign = item;
                this.assignId = item.id;
                this.$refs.assignList.close();
            },

            getAssignSelected(val){
                this.assignName = val.companyAlias;
                this.assignId = val.id;
                this.assign = val;
            },

            openAssign(){
                this.$refs.assignList.open();
            },

            getAssigns(searchTerm){
                let search = { pageIndex: 1, pageSize: 30,  code: searchTerm };
                companyService.getTransport(search).then((response) => {
                    if(response.statusCode == 200){
                        this.assigns = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            close(){
                this.showDialog = false;
            },

            getById(){
                this.setLoading(true);
                orderService.getById(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getLocation(item) {
                let location = [];
                if(item.placeOfReceiptCont !== null && item.placeOfReceiptCont !== undefined){
                    location.push(item.placeOfReceiptCont.locationName);
                }
                if(item.placeOfReceiptGoods !== null && item.placeOfReceiptGoods !== undefined){
                    location.push(item.placeOfReceiptGoods.locationName);
                }
                if(item.transitDelivery !== null && item.transitDelivery !== undefined){
                    location.push(item.transitDelivery.locationName);
                }
                if(item.transitReceipt !== null && item.transitReceipt !== undefined){
                    location.push(item.transitReceipt.locationName);
                }
                if(item.placeOfDeliveryGoods !== null && item.placeOfDeliveryGoods !== undefined){
                    location.push(item.placeOfDeliveryGoods.locationName);
                }
                if(item.placeOfDeliveryCont !== null && item.placeOfDeliveryCont !== undefined){
                    location.push(item.placeOfDeliveryCont.locationName);
                }

                if(location.length > 0){
                    return location.join([' - ']);
                }
                else{
                    return "";
                }
            },
        },
        watch: {
            selectAll: function (val){
                if(val == true){
                    this.checkedList = []
                    this.entity.details.map((item) => {
                        this.checkedList.push(item.id);
                    })
                }
                else{
                    this.checkedList = []
                }
            },
        }
    }
</script>