<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách admin</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-if="role.add" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-else disabled class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary"> 
                            <md-icon>cached</md-icon> 
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button> 
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo tên</label>
                                <md-input v-model="search.lastName"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo tên đăng nhập</label>
                                <md-input v-model="search.userName"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
					<table class="data-table">
						<thead>
                            <tr>
                                <th style="width: 100px;">#</th>
                                <th class="left" style="width: 10%;">Nhóm quyền</th>
                                <th class="left" style="width: 10%;">Tên đăng nhập</th>
                                <th class="left" style="width: 15%;">Họ và tên</th>
                                <th class="left" style="width: 17%;">Phòng ban</th>
                                <th class="left" style="width: 15%;">Chi nhánh</th>
                                <th style="width: 10%;">Ngày tạo</th>
                                <th style="width: 8%;">Trạng thái</th>
                                <th style="width: 10%;">Hành động</th>
                            </tr>
						</thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
						<tbody v-if="loadding == false && pager.totalItems > 0">
							<tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
								<td class="center">{{index + 1}}</td>
                                <td class="left">{{item.role.roleName}}</td>
								<td class="left">{{item.userName}}</td>
                                <td class="left">{{item.fullName}}</td>
                                <td class="left">{{item.deptName}}</td>
                                <td class="left">{{item.branchName}}</td>
                                <td class="center">{{item.createdTime}}</td>
                                <td class="center">
                                    <span v-if="item.isActive" class="s-active"><i class="fas fa-check"></i></span>
                                    <span v-else class="s-inactive"><i class="fas fa-check"></i></span>
                                </td>
								<td class="action">
                                    <md-button v-if="role.edit == true" @click="edit(item.id)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-else disabled class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-if="!item.isSystem && role.delete == true" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                    <md-button v-else disabled class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                    <md-button v-if="!item.isSystem" @click="openChangePassword(item.id)" class="md-fab md-mini md-primary">
                                        <md-icon>vpn_key</md-icon>
                                        <md-tooltip>Đổi mật khẩu</md-tooltip>
                                    </md-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog-prompt
            :md-active.sync="isChangePassword"
            v-model="newPassword"
            md-title="Thay đổi mật khẩu"
            md-input-placeholder="Nhập mật khẩu mới..."
            md-confirm-text="Lưu" 
            md-cancel-text="Hủy"
            @md-confirm="onChangePassword"
            @md-cancel="onCancel"/>
    </div>
</template>

<script>
import adminService from '../../../api/adminService';
import messageBox from '../../../utils/messageBox';
import common from '../../../mixins';
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Danh sách admin'
    },
    data() {
        return {
            role: { read: false, add: false, edit: false, delete: false },
            loadding: false,
            isChangePassword: false,
            newPassword: '',
            search: { pageIndex: 1, pageSize: common.pageSize, userName: '', lastName: ''},
            pager: { totalPages: 0, totalItems: 0 },
            data: [],
            rowPerPageOptions: [],
            id: 0
        }
    },
    async created(){
        this.rowPerPageOptions = common.rowPerPageOptions;
        if(this.$route.query.page != undefined){
            this.search.pageIndex = parseInt(this.$route.query.page);
        }
        if(this.$route.query.pageSize != undefined){
            this.search.pageSize = parseInt(this.$route.query.pageSize);
        }
        if(this.$route.query.username != undefined){
            this.search.userName = this.$route.query.username;
        }
        if(this.$route.query.lastname != undefined){
            this.search.lastName = this.$route.query.lastname;
        }
        this.filter();
    },
    async mounted() {
        this.role = await this.getPageRole("admin", "READ");
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        onCancel(){
            this.newPassword = '';
        },

        onChangePassword(){
            if(this.newPassword == '' || this.newPassword.length < 6){
                messageBox.showWarning('Vui lòng nhập mật khẩu ít nhất 6 ký tự');
            }
            else{
                adminService.changePassword(this.id, this.newPassword).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Đổi mật khẩu thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            }
        },

        openChangePassword(id){
            this.id = id;
            this.isChangePassword = true;
        },

        onPage(page){
            if(page > this.pager.totalPages){
                return;
            }
            if(page == 0){
                return;
            }
            this.search.pageIndex = page;
            this.filter();
        },

        refresh(){
            this.search = { pageIndex: 1, pageSize: common.pageSize, userName: '', lastName: '' };
            this.getData();
        },

        filter(){
            let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
            if(this.search.userName != undefined && this.search.userName != ''){
                url = url + '&username=' + this.search.userName;
            }
            if(this.search.lastName != undefined && this.search.lastName != ''){
                url = url + '&lastname=' + this.search.lastName;
            }
            this.$router.push('/admin?' + url).catch(()=>{});

            this.getData();
        },

        getData(){
            this.loadding = true;
            adminService.filter(this.search).then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data.items;
                    this.pager = response.data.pager;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loadding = false; });
        },

        confirmDelete(id){
            this.$modal.show('dialog', {
            title: 'Thông báo',
            text: 'Bạn có chắc muốn xóa không?',
            buttons: [
                {
                    title: 'Hủy',
                    handler: () => {
                        this.$modal.hide('dialog')
                    }
                },
                {
                    title: 'Xóa',
                    handler: () => {
                        this.del(id)
                    }
                }
            ]
            })
        },

        del(id){
            this.$modal.hide('dialog');
            this.setLoading(true);
            adminService.delete(id).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Xóa thành công");
                    this.refresh();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },
        
        add(){
            this.$router.push('/admin/crud');
        },

        edit(id){
            this.$router.push('/admin/crud/' + id);
        },
    },
    watch: {
        'search.userName': function () {
            this.search.pageIndex = 1;
            this.filter();
        },
        'search.lastName': function () {
            this.search.pageIndex = 1;
            this.filter();
        },
        'search.pageIndex': function () {
            this.filter();
        },
        'search.pageSize': function () {
            this.search.pageIndex = 1;
            this.filter();
        },
    },
}
</script>