import httpClient  from '../api';
const API_CONTROLLER = 'report-logistic/';

export default {
    getReportSummaryVehicle(search) {
        let url = API_CONTROLLER + 'get-report-summary-by-vehicle';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                vehicleId: search.vehicleId,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getReportJob(search) {
        let url = API_CONTROLLER + 'get-report-by-job';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                customerId: search.customerId,
                orderId: search.orderId,
                jobId: search.jobId,
                driverId: search.driverId,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getReportOrder(search) {
        let url = API_CONTROLLER + 'get-report-by-order';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                orderId: search.orderId
            }
        });
    },

    getReportCustomer(search) {
        let url = API_CONTROLLER + 'get-report-by-customer';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                customerId: search.customerId
            }
        });
    },

    getReportDriver(search) {
        let url = API_CONTROLLER + 'get-report-by-driver';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                driverId: search.driverId
            }
        });
    },

    getReportContract(search) {
        let url = API_CONTROLLER + 'get-report-by-contract';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                contractId: search.contractId
            }
        });
    },

    getReportProject(search) {
        let url = API_CONTROLLER + 'get-report-by-project';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                projectId: search.projectId
            }
        });
    },

    getReportSummaryByGoods(search) {
        let url = API_CONTROLLER + 'get-report-summary-by-goods';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                branchId: search.branchId,
                serial: search.serial
            }
        });
    },
}