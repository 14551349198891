import httpClient  from '../api'; 
const API_CONTROLLER = 'order/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    deleteDetails(ids){ 
        let url = API_CONTROLLER + 'delete-details';
        return httpClient.post(url, ids);
    },

    updateFiles(orderDetailId, data) { 
        let url = API_CONTROLLER + 'update-file-order-detail?orderDetailId=' + orderDetailId;
        return httpClient.post(url, data);
    },

    updateDateTakeCont(data) { 
        let url = API_CONTROLLER + 'update-date-take-cont';
        return httpClient.post(url, data);
    },

    updateFixedCost(data) { 
        let url = API_CONTROLLER + 'update-fixed-cost';
        return httpClient.post(url, data);
    },

    updateDistribute(data) { 
        let url = API_CONTROLLER + 'update-distribute';
        return httpClient.post(url, data);
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    updateOrderDetail(data) { 
        let url = API_CONTROLLER + 'update-detail';
        return httpClient.post(url, data);
    },

    addOrderDetails(orderId, data) { 
        let url = API_CONTROLLER + 'add-details?orderId=' + orderId;
        return httpClient.post(url, data);
    },

    updateOrderDetails(data) { 
        let url = API_CONTROLLER + 'update-details';
        return httpClient.post(url, data);
    },


    getFiles(orderDetailId) { 
        let url = API_CONTROLLER + 'get-file-order-detail';
        return httpClient.get(url, {
            params: {
                orderDetailId: orderDetailId
            }
        });
    },

    getOrderFiles(orderId) { 
        let url = API_CONTROLLER + 'get-file-order';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    copy(id) { 
        let url = API_CONTROLLER + 'copy';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                contractId: search.contractId,
                customerId: search.customerId,
                staffId: search.staffId,
                distribute: search.distribute,
                code: search.code,
                orderName: search.orderName,
                keyword: search.keyword,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getOrders(search) { 
        let url = API_CONTROLLER + 'get-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getGoods(orderId) { 
        let url = API_CONTROLLER + 'get-goods';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getHandleGoods(orderId) { 
        let url = API_CONTROLLER + 'get-handle-goods';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getOrderFeeOrders(search) { 
        let url = API_CONTROLLER + 'get-order-fee-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getHandleOrders(search) { 
        let url = API_CONTROLLER + 'get-handle-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getBookingOrders(search) { 
        let url = API_CONTROLLER + 'get-booking-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getPortOrders(search) { 
        let url = API_CONTROLLER + 'get-port-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getBookingGoods(orderId) { 
        let url = API_CONTROLLER + 'get-booking-goods';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getJobGoods(orderId) { 
        let url = API_CONTROLLER + 'get-job-goods';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getDistributeGoods(orderId) { 
        let url = API_CONTROLLER + 'get-distribute-goods';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getGoodsRoutes(orderDetailId) { 
        let url = API_CONTROLLER + 'get-goods-route';
        return httpClient.get(url, {
            params: {
                orderDetailId: orderDetailId
            }
        });
    },

    getForPortEstimate(search) { 
        let url = API_CONTROLLER + 'get-for-port-estimate';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getCode() { 
        let url = API_CONTROLLER + 'get-code';
        return httpClient.get(url);
    },

    getRoutes(search) { 
        let url = API_CONTROLLER + 'get-routes';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderId: search.orderId,
                status: search.status
            }
        });
    },

    getServiceOrders(search) { 
        let url = API_CONTROLLER + 'get-service-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getJobOrders(search) { 
        let url = API_CONTROLLER + 'get-job-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getOrdersForProject(search) { 
        let url = API_CONTROLLER + 'get-orders-for-project';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getForDistribute(search) { 
        let url = API_CONTROLLER + 'get-distribute-orders';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                contractId: search.contractId,
                customerId: search.customerId,
                code: search.code
            }
        });
    },

    getGoodsForDistribute(search) { 
        let url = API_CONTROLLER + 'get-goods-for-distribute';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                orderId: search.orderId,
                customerId: search.customerId,
                status: search.status,
                serial: search.serial,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getGoodsForOrderVehicel(search) { 
        let url = API_CONTROLLER + 'get-goods-for-order-vehicle';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                branchId: search.branchId,
                planId: search.planId,
                orderId: search.orderId,
                customerId: search.customerId,
                status: search.status,
                keyword: search.keyword,
                fromDate: search.fromDate,
                toDate: search.toDate,
                serial: search.serial,
                code: search.code
            }
        });
    },

    getGoodsNotOrderVehicel(search) { 
        let url = API_CONTROLLER + 'get-goods-not-order-vehicle';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                keyword: search.keyword,
            }
        });
    },

    getOrderDetailForFixedCostPaging(search) { 
        let url = API_CONTROLLER + 'get-order-detail-for-fixed-cost-paging';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                isFixedCost: search.isFixedCost,
                orderId: search.orderId,
                contractId: search.contractId,
                customerId: search.customerId,
                keyword: search.keyword
            }
        });
    },
}