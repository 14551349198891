<template>
    <div class="page-content">
        <div class="view-body">
            <div class="tabs">
                <div :class="{'active' : tab == 'info'}" class="tab-item"><a @click="onTab('info')">Thông tin chung</a></div>
                <div :class="{'active' : tab == 'detail'}" class="tab-item"><a @click="onTab('detail')">Chi tiết đơn hàng</a></div>
                <div :class="{'active' : tab == 'distribute'}" class="tab-item"><a @click="onTab('distribute')">Phân bổ - cước vận chuyển</a></div>
                <div v-if="role.crudEstimate == true" :class="{'active' : tab == 'estimate'}" class="tab-item"><a @click="onTab('estimate')">Dự toán</a></div>
                <div :class="{'active' : tab == 'refund'}" class="tab-item"><a @click="onTab('refund')">Phải trả vendor</a></div>
                <div :class="{'active' : tab == 'revenue'}" class="tab-item"><a @click="onTab('revenue')">Thu Khách hàng</a></div>
                <div :class="{'active' : tab == 'profit'}" class="tab-item"><a @click="onTab('profit')">Chi phí - doanh thu</a></div>
                <div :class="{'active' : tab == 'file'}" class="tab-item"><a @click="onTab('file')">Upload file</a></div>
            </div>
            <div class="content-tab">
                <div v-if="tab == 'info'">
                    <Info />
                </div>
                <div v-else-if="tab == 'detail'">
                    <DetailList />
                </div>
                <div v-else-if="tab == 'distribute'">
                    <Distribue />
                </div>
                <div v-else-if="tab == 'estimate'">
                    <EstimateList />
                </div>
                <div v-else-if="tab == 'refund'">
                    <RefundList />
                </div>
                <div v-else-if="tab == 'revenue'">
                    <RevenueList />
                </div>
                <div v-else-if="tab == 'profit'">
                    <ProfitForm />
                </div>
                <div v-else-if="tab == 'file'">
                    <UploadFile />
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import messageBox from '../../../utils/messageBox';
    import Info from './Info.vue';
    import DetailList from './OrderDetail.vue';
    import Distribue from './Distribue.vue';
    import EstimateList from './Estimate.vue';
    import RevenueList from './Revenue.vue';
    import RefundList from './Refund.vue';
    import ProfitForm from './Profit.vue';
    import UploadFile from './UploadFile.vue';
    import permisonIndividualService from '../../../api/permisonIndividualService';
    import { mapActions } from 'vuex';

    export default ({
        components: {
            Info,
            DetailList,
            Distribue,
            EstimateList,
            RevenueList,
            RefundList,
            ProfitForm,
            UploadFile
        },
        metaInfo: {
            title: 'Thêm/Cập nhật đơn hàng thường'
        },
        data() {
            return {
                title: '',
                tab: 'info',
                role: { crudEstimate: false }
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.$route.query.tab !== undefined){
                if(this.$route.query.tab == 'estimate'){
                    if(this.role.crudEstimate == true){
                        this.tab = this.$route.query.tab;
                    }
                }
                else{
                    this.tab = this.$route.query.tab;
                }
            }
        },
        mounted(){
            this.getPermission();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            onTab(tabName){
                this.id = this.$route.params.id;
                if(tabName === 'info'){
                    this.tab = tabName;
                    this.$router.push('/order/crud/' + this.id + '?tab=' + tabName);
                }
                else{
                    if(this.id == 0 || this.id == undefined){
                        messageBox.showWarning("Vui lòng thêm thông tin chung đơn hàng");
                    }
                    else{
                        this.tab = tabName;
                        this.$router.push('/order/crud/' + this.id + '?tab=' + tabName);
                    }
                }
            },

            getPermission(){
                this.setLoading(true);
                const userId = localStorage.getItem("userId");
                permisonIndividualService.getPermission(userId, 'order').then((response) => {
                    if(response.statusCode == 200){
                        response.data.map((role) => {
                           if(role.actionCode == 'CRUD-ESTIMATE'){
                                this.role.crudEstimate = true;
                            }
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
     })
</script>

<style scoped>
    #tab-info {
        padding: 0 !important;
        margin-top: 10px;
    }
    .distribute thead  tr th {
        z-index: 9998;
    }
    .table-scroll tr th {
        z-index: 9999;
        background-color: #eee !important;
    }
    tr th.sticky-col, th.sticky-col-2, th.sticky-col-3, th.sticky-col-4 {
        z-index: 9999;
    }
    tr td.sticky-col, td.sticky-col-2, td.sticky-col-3, td.sticky-col-4 {
        z-index: 9998;
    }
    .sticky-col {
        position: sticky !important;
        left: 0 !important;
        z-index: 9999;
    }
    .sticky-col-2 {
        position: sticky !important;
        left: 35px !important;
        z-index: 9999;
    }
    .sticky-col-3 {
        position: sticky !important;
        left: 140px !important;
        z-index: 9999;
    }
    .sticky-col-4 {
        position: sticky !important;
        left: 350px !important;
        z-index: 9999;
    }

    ::-webkit-scrollbar{
        height: 12px;
        width: 0;
        background: #FFF;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #e0e0e0;
        border-radius: 10px;
    }
    
    .table-scroll {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: contents;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll th, .table-scroll td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll td:last-child, .table-scroll th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody th {
        text-align:left;
    }
    .table-scroll thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll tfoot, .table-scroll tfoot th, .table-scroll tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
    a:focus {
        background:red
    }/* testing links*/

    .table-scroll-2 {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll-2 table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: inline-table;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll-2 th, .table-scroll-2 td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll-2 tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll-2 tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll-2 td:last-child, .table-scroll-2 th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll-2 thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll-2 tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll-2 tbody th {
        text-align:left;
    }
    .table-scroll-2 thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll-2 thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll-2 tfoot, .table-scroll-2 tfoot th, .table-scroll-2 tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
</style>
