<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Báo cáo hàng tồn</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary md-search">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'e']" @click="exportExcel()" class="md-raised md-primary btn-add">
                            Export<md-tooltip>Xuất excel</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="storeName" @md-selected="getStoreSelected" :md-options="stores" @md-changed="getStores"  @md-opened="getStores">
                                    <label>Kho hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.storeName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openStore()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="productName" @md-selected="getProductSelected" :md-options="products" @md-changed="getProducts"  @md-opened="getProducts">
                                    <label>Vật tư</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.productName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openProduct()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(100vh - 280px); overflow-x: auto; width: 100%;">
                        <table class="data-table">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width:50px;">#</th>
                                    <th rowspan="2" style="width:10%;">Kho hàng</th>
                                    <th rowspan="2" style="width:150px;">Mã vật tư</th>
                                    <th rowspan="2" style="width:150px;">Mã kế toán</th>
                                    <th rowspan="2" style="width:10%;">Tên vật tư</th>
                                    <th rowspan="2" style="width:10%;">Trạng thái</th>
                                    <th rowspan="2" style="width:10%;">ĐVT</th>
                                    <th style="width:15%; background-color: #FBD288;" colspan="2">Tồn đầu kỳ</th>
                                    <th style="width:15%; background-color: #4CC9FE;" colspan="2">Tổng nhập</th>
                                    <th style="width:15%; background-color: #FEEC37;" colspan="2">Tổng xuất</th>
                                    <th style="width:15%; background-color: #A0D683;" colspan="2">Tồn Kho</th>
                                    
                                </tr>
                                <tr>
                                    <th style="width:100px; background-color: #FBD288;">Số lượng</th>
                                    <th style="width:120px; background-color: #FBD288;">Thành tiền</th>

                                    <th style="width:100px; background-color: #4CC9FE;">Số lượng</th>
                                    <th style="width:120px; background-color: #4CC9FE;">Thành tiền</th>

                                    <th style="width:100px; background-color: #FEEC37;">Số lượng</th>
                                    <th style="width:120px; background-color: #FEEC37;">Thành tiền</th>

                                    <th style="width:100px; background-color: #A0D683;">Số lượng</th>
                                    <th style="width:120px; background-color: #A0D683;">Thành tiền</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td>{{item.storeName}}</td>
                                    <td>
                                        <router-link :to="'/report-receipt?productId=' + item.productId + '&productCode=' + item.productCode" target="_blank">
                                            {{item.productCode}}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="'/report-receipt?productId=' + item.productId + '&productCode=' + item.productCode" target="_blank">
                                            {{item.pplCode}}
                                        </router-link>
                                    </td>
                                    <td>
                                        <router-link :to="'/report-receipt?productId=' + item.productId + '&productCode=' + item.productName" target="_blank">
                                            {{item.productName}}
                                        </router-link>
                                    </td>
                                    <td class="center">{{item.status}}</td>
                                    <td class="center">{{item.unitName}}</td>

                                    <td class="right">{{ formatNumber(item.openingStore) }}</td>
                                    <td class="right">{{ formatNumber(item.openingAmount) }}</td>

                                    <td class="right">{{ formatNumber(item.inputStore) }}</td>
                                    <td class="right">{{ formatNumber(item.inputAmount) }}</td>

                                    <td class="right">{{ formatNumber(item.outputStore) }}</td>
                                    <td class="right">{{ formatNumber(item.outputAmount) }}</td>

                                    <td class="center">{{ formatNumber(item.closingStore) }}</td>
                                    <td class="right">{{ formatNumber(item.closingAmount)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <storeList ref="storeList" @close="closeStore" />
        <productList ref="productList" typeCode="" title="vật tư" @close="closeProduct" />
    </div>
</template>
<script>
    import reportStoreService from '../../../api/reportStoreService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import { APP_CONFIG } from '@/utils/constants'
    import storeService from '../../../api/storeService';
    import productService from '../../../api/productService';
    import storeList from '../../../components/popup/_StoreList.vue';
    import productList from '../../../components/popup/_ProductList.vue';

    export default {
        components: {
            storeList,
            productList
        },
        metaInfo: {
            title: 'Báo cáo hàng tồn'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, fromDate: common.dateNow, toDate: common.dateNow, storeId: 0, productId: 0 },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                storeName: '',
                stores: [],
                productName: '',
                products: []
            }
        },
        created(){
            this.setLoading(false);
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/report-store/export-report-inventory?fromDate=" + 
                this.search.fromDate + "&toDate=" + this.search.toDate + "&storeId=" + this.search.storeId
                + "&productId=" + this.search.productId;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            btnSearch(){
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }
                this.filter();
            },

            //Product
            closeProduct(item){
                this.productName = item.productName;
                this.search.productId = item.id;
                this.$refs.productList.close();
            },

            getProductSelected(val){
                this.productName = val.productName;
                this.search.productId = val.id;
            },

            openProduct(){
                this.$refs.productList.open();
            },

            getProducts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, typeCode: '', keyword: searchTerm };
                productService.getProducts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.products = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Store
            closeStore(item){
                this.storeName = item.storeName;
                this.search.storeId = item.id;
                this.$refs.storeList.close();
            },

            getStoreSelected(val){
                this.storeName = val.storeName;
                this.search.storeId = val.id;
            },

            openStore(){
                this.$refs.storeList.open();
            },

            getStores(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                storeService.getStores(search).then((response) => {
                    if(response.statusCode == 200){
                        this.stores = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.pageIndex = 1;
                this.search.pageSize = common.pageSize;
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/report-inventory?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportStoreService.getReportInventory(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        },
        watch: {
            storeName: function (val) { 
                if(val == ''){
                    this.search.storeId = 0;
                }
            },
            productName: function (val) { 
                if(val == ''){
                    this.search.productId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
