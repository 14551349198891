<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="3" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Tạm ứng sửa chữa</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button v-shortkey="['ctrl', 'e']" @shortkey="preview()" @click="preview()" class="md-raised md-view btn-add"><span>E</span>xport<md-tooltip>Export tạm ứng (Ctrl + E)</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col l-4 m-4 c-12">
                                        <div class="left">
                                            <img src="../../../assets/images/logo.png" />
                                        </div>
                                    </div>
                                    <div class="col l-8 m-8 c-12">
                                        <div class="center">
                                            <div class="title"><h2>Tạm ứng sửa chữa</h2></div>
                                            <div class="code"><span class="label">Số: </span><span>{{entity.advancePaymentCode}}</span></div>
                                            <div class="date"><span class="label">Ngày: </span><span>{{entity.advancePaymentDate}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line-h"> 
                                            <label>Kính gửi: </label>
                                            <p>BGĐ Công ty PPL</p>
                                        </div>
                                        <div style="display: flex; padding: 0;">
                                            <div style="font-size: 14px; font-weight: bold; padding-right: 10px;">Tên tôi là:</div>
                                            <div style="font-size: 14px;">{{ entity.staff.fullName  }}</div>
                                        </div>
                                        <div style="display: flex; padding: 0 0 20px 0;">
                                            <div style="font-size: 14px; font-weight: bold; padding-right: 10px;">Phòng/ban:</div>
                                            <div style="font-size: 14px;">{{ entity.staff.department.name }}</div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row no-gutters">
                                    <div class="col l-12 m-12 c-12">
                                        <table class="table-1">
                                            <thead>
                                                <tr>
                                                    <th style="width:70px;">STT</th>
                                                    <th style="width:250px;">VẬT TƯ</th>
                                                    <th style="width:120px;">NGUỒN</th>
                                                    <th style="width:100px;">ĐVT</th>
                                                    <th style="width:120px;">S.LƯỢNG</th>
                                                    <th style="width:120px;">ĐƠN GIÁ</th>
                                                    <th style="width:100px;">VAT</th>
                                                    <th style="width:120px;">THÀNH TIỀN</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in details" :key="item.id">
                                                <tr>
                                                    <td class="stt"><span style="font-weight:bold;">{{romanize(index + 1)}}</span></td>
                                                    <td><span style="font-weight:bold;">{{item.key}}</span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr v-for="(obj, i) in item.list" :key="obj.id">
                                                    <td class="stt">{{i + 1}}</td>
                                                    <td><span style="padding-left: 20px;">{{obj.title}}</span></td>
                                                    <td class="stt">{{obj.sourceType.name}}</td>
                                                    <td class="stt">{{obj.unit}}</td>
                                                    <td class="stt">{{obj.quantity}}</td>
                                                    <td class="currency">{{formatNumber(obj.price)}}</td>
                                                    <td class="currency">{{formatNumber(obj.vat) + '%'}}</td>
                                                    <td class="currency">{{formatNumber(obj.amount)}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="7" class="stt"><b>Tổng cộng</b></td>
                                                    <td class="currency" style="font-weight: bold;">{{formatNumber(entity.amount)}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-top: 50px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="approve"> 
                                            <div v-for="item in entity.approveList" :key="item.id" class="approve-item">
                                                <div v-show="!item.isCommenter">
                                                    <div class="title">{{item.title}}</div>
                                                    <div class="approve-status">
                                                        <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                                        <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                                        <md-chip v-else-if="item.status == 3" class="md-accent">Yêu cầu chỉnh sửa</md-chip>
                                                        <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                                    </div>
                                                    <div class="name">{{item.staff.fullName}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preview ref="preview"/>
   </div>
</template>

<script>
    import repairAdvancePaymentService from '../../../api/repairAdvancePaymentService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import comment from '../../../components/Comment.vue';
    import preview from './Preview.vue';

    export default ({
        components: {
            comment,
            preview
        },
        metaInfo: {
            title: 'Tạm ứng sửa chữa'
        },
        data() {
            return {
               title: 'Tạm ứng sửa chữa',
               id: 0,
               submitted: false,
               loadding: false,
               obj: {},
               comments: [],
               details: [],
               entity: { id: 0, approveList: [], details: [], staffId: '', staff: {}, advancePaymentCode: '', advancePaymentDate: null, status: 0, amount: 0, vat: 0, pay: 0 },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async preview () {
                this.$refs.preview.open(this.entity);
            },

            getById(){
                this.setLoading(true);
                repairAdvancePaymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        }); 
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.vehicle.licensePlate]: [...(groups[item.vehicle.licensePlate] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },
            
            back(){
                this.$router.push('/repair-advance-payment');
            }
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 70px;
        width: auto;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 16px;
    }
    .right {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-left: 30px;
    }
    .right .item {
        display: block;
        position: relative;
        font-size: 16px;
    }
    .right .md-checkbox {
        margin: 0 !important;
    }
    .label {
        font-weight: bold;
    }
    .line-h {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .line-h label {
        font-weight: bold;
        font-size: 18px;
        padding-right: 10px;
        text-decoration: underline;
    }
    .line-h p {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .line p {
        font-size: 18px;
        line-height: 1.2 rem;
    }
    b {
        font-weight: bold;
    }
    .content {
        font-style: italic;
        font-weight: bold;
    }
    .line-f {
        padding: 30px 0;
        display: flex;
        align-items: center;
    }
    .line-f .title {
        font-size: 18px;
        padding-right: 30px;
    }
    .line-f .option {
        display: flex;
    }
    .line-f .option .option-item {
        padding-right: 50px;
        font-size: 17px;
    }
    .approve {
        display: flex;
        justify-content: space-around;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 16px;
        font-weight: bold;
    }
    .note-item {
        padding-bottom: 30px;
    }
    .note-item .note-title {
        font-weight: bold;
    }
    .note-item .note {
        padding-top: 10px;
        color: red;
        font-size: 16px;
    }
</style>
