<template>
    <md-dialog id="crud-order-invoice" :md-fullscreen="true" :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-if="entity.status == 0 || entity.status == 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary">Gửi duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                        <label for="invoiceCode">Số quyết toán</label>
                                        <md-input name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập số quyết toán</span>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-datepicker v-model="entity.invoiceDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.invoiceDate.$error }">
                                        <label>Ngày lập</label>
                                        <span class="md-error" v-if="submitted && !$v.entity.invoiceDate.required">Vui lòng chọn ngày tạo</span>
                                    </md-datepicker>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col l-8 m-8 c-12">
                                    <md-field>
                                        <label for="reason">Lý do</label>
                                        <md-input name="reason" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <div style="display:flex;">
                                        <md-field>
                                            <label for="note">Tổng thanh toán</label>
                                            <md-input name="amount" :value="formatNumber(entity.pay)" ></md-input>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết quyết toán">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="list" style="overflow-x: auto; width: 100%; padding-top: 10px;">
                                                            <table class="data-table-2" style="width: 1820px;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:50px; background-color: #eee;">#</th> 
                                                                        <th rowspan="2" style="width:200px; text-align: left; background-color: #eee;">Dự toán</th> 
                                                                        <th rowspan="2" style="width:220px; text-align: left; background-color: #eee;">Tên phí</th> 
                                                                        <th rowspan="2" style="width:100px; background-color: #eee;">Loại</th>
                                                                        <th rowspan="2" style="width:80px; background-color: #eee;">Số lượng</th>
                                                                        <th colspan="3" style="width:380px; background-color: #FFC145;">Dự toán</th>
                                                                        <th colspan="6" style="width:780px; background-color: #ADD899;">Quyết toán</th>
                                                                    </tr> 
                                                                    <tr> 
                                                                        <th class="right" style="width:120px; background-color: #FFC145;">Đơn giá</th>
                                                                        <th class="right" style="width:100px; background-color: #FFC145;">VAT</th>
                                                                        <th class="right" style="width:120px; background-color: #FFC145;">Thành tiền</th>
                                                                        <th class="right" style="width:120px; background-color: #ADD899;">Đơn giá</th>
                                                                        <th class="right" style="width:100px; background-color: #ADD899;">VAT</th>
                                                                        <th class="right" style="width:120px; background-color: #ADD899;">Thành tiền</th>
                                                                        <th style="width:120px; background-color: #ADD899;">Số HĐ</th>
                                                                        <th style="width:130px; background-color: #ADD899;">Ngày HĐ</th>
                                                                        <th style="width:150px; background-color: #ADD899;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="center">{{index + 1}}</td>
                                                                        <td class="left">
                                                                            <span>{{ item.estimate.estimateCode }}</span>
                                                                        </td>
                                                                        <td class="left">
                                                                            <span>{{ item.fee.feeName}}</span>
                                                                        </td>
                                                                        <td class="center">
                                                                            {{ getFeeType(item.type) }}
                                                                        </td>
                                                                        <td class="center">
                                                                            {{item.quantity}}
                                                                        </td>
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.price)}}</div> 
                                                                        </td>
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.vat)}}</div> 
                                                                        </td> 
                                                                        <td class="number-right">
                                                                            <div>{{formatNumber(item.amount)}}</div> 
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.billPrice" :class="{'is-error': $v.entity.details.$each[index].billPrice.$error}" v-bind="currencyF2" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.billVat" :class="{'is-error': $v.entity.details.$each[index].billVat.$error}" v-bind="currencyF2" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <number @change="calSumAmount(item)" v-model="item.billAmount" :class="{'is-error': $v.entity.details.$each[index].billAmount.$error}" v-bind="currencyF2" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.billNumber" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <md-datepicker v-model="item.billDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.invoiceNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <table class="data-table-2"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th style="width:100px;">#</th> 
                                                                    <th style="width:20%;">Bộ phận/phòng</th> 
                                                                    <th style="width:20%;">Người phê duyệt</th> 
                                                                    <th style="width:20%;">Tiêu đề</th>
                                                                    <th style="width:20%;">Ghi chú</th> 
                                                                    <th style="width:10%;">Thứ tự</th> 
                                                                    <th style="width:150px">Hành động</th> 
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loading == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loading == false"> 
                                                                <tr v-for="(item, index) in entity.approveList" :key="'approve-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.title" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.note" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                        </div>
                                                                    </td> 
                                                                    <td class="grid-action">
                                                                        <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                    </td> 
                                                                </tr> 
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeStaff"/>
                <approveList ref="approveList" @close="closeApprove"/>
                <deptList ref="deptList" @close="closeDept"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import staffList from '../../../components/popup/_StaffList.vue';
import approveList from '../../../components/popup/_StaffList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import approveSettingService from '../../../api/approveSettingService';
import staffService from '../../../api/staffService';
import orderInvoiceService from '../../../api/orderInvoiceService';
import orderEstimateService from '../../../api/orderEstimateService';
import deptList from '../../../components/popup/_DepartmentList.vue';

export default ({
    components: {
        staffList,
        approveList,
        deptList
    },
    data() {
        return {
            showDialog: false,
            estimateId: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF,
            currencyF2: common.currencyF2,
            types: common.typeFee,  
            order: null,
            companyName: '',
            companies: [],
            typeOfTransport: common.typeOfTransport,
            entity: { id: 0, orderType: 1, status: 0, details: [], amount: 0, estimateList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, invoiceCode: '', invoiceDate: common.dateNow, pay: 0, payFee: 0, payOnBehalf: 0 },
            approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
            advancePayment: { id: 'id_' + common.getFakeId(), pay: 0, payFee: 0, payOnBehalf: 0, advancePaymentId: 0, estimateId: 0, orderId: 0, order: {orderCode: ''}, advancePayment: { advancePaymentCode: '', advancePaymentDate: '', staff: { fullName: '' }}},
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        calSum(item){
            let price = parseFloat(item.billPrice);
            let vat = price * (parseFloat(item.billVat) / 100);
            item.billAmount = Math.round(price + vat) * parseFloat(item.quantity);

            this.calTotal();
        },

        calSumAmount(item){
            let amountNotVat = parseFloat(item.billPrice) * parseFloat(item.quantity);
            let vatAmount = parseFloat(item.billAmount) - amountNotVat;
            item.billVat = (vatAmount / amountNotVat) * 100;
            this.calTotal();
        },

        calTotal(){
            this.entity.pay = 0;
            this.entity.payFee = 0;
            this.entity.payOnBehalf = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                if(parseFloat(this.entity.details[i].billAmount) > 0){
                    this.entity.pay = parseFloat(this.entity.pay) + parseFloat(this.entity.details[i].billAmount);
                }
            }
        },

        open(estimateId){
            this.submitted = false;
            this.estimateId = estimateId;
            this.getByEstimateId();
            this.showDialog = true;
        },

        close(){
            this.showDialog = false;
        },

        submit(status){
            this.entity.orderId = parseFloat(this.$route.params.id);
            this.submitted = true;
            this.entity.orderType = 1;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.estimateList.length > 0){
                this.entity.estimateList = this.entity.estimateList.filter(item => !(item.advancePaymentId == 0));
                this.entity.estimateList.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.approveList.length > 0){
                this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                this.entity.approveList.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.estimateList.length <= 0){
                messageBox.showWarning("Vui lòng nhập tạm ứng chi phí làm hàng");
                return;
            }
            if(this.entity.details.length <= 0){
                messageBox.showWarning("Vui lòng nhập nhu nội dung tạm ứng");
                return;
            }
            if(this.entity.approveList.length <= 0){
                messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                return;
            }
            this.entity.status = status;
            if(this.entity.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            this.entity.thirdParty = true;
            orderInvoiceService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            orderInvoiceService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getEstimateById(){
            this.loading = true;
            orderEstimateService.getById(this.estimateId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.details = [];
                    for(var i = 0; i < response.data.details.length; i ++){
                        const detail = response.data.details[i];
                        detail.billPrice = detail.price;
                        detail.billVat = detail.vat;
                        detail.billAmount = detail.amount;
                        let obj = Object.assign({}, detail, { estimateId: this.estimateId });
                        this.entity.details.push(obj);
                    }

                    const item = { 
                        id: 'id_' + common.getFakeId(), 
                        estimateId: this.estimateId,
                        pay: response.data.pay,
                        payFee: response.data.payFee,
                        payOnBehalf: response.data.payOnBehalf
                    };
                    this.entity.estimateList.push(item);

                    this.calTotal();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loading = false; });
        },

        getTypeName(transportTypeId){
            return this.typeOfTransport.filter((obj) => obj.id == transportTypeId)[0].text;
        },

        getByEstimateId(){
            this.setLoading(true);
            orderInvoiceService.getByEstimateId(this.estimateId).then((response) => {
                if(response.statusCode == 200){
                    if(response.data !== null){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                        this.title = 'Cập nhật đề nghị thanh toán';
                    }
                    else{
                        this.title = 'Thêm mới đề nghị thanh toán';
                        this.getByAccount();
                        this.entity.approveList = [];
                        this.entity.estimateList = [];
                        this.entity.approveList.push(this.approve);
                        this.entity.estimateList.push(this.advancePayment);
                        this.getEstimateById();
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.approve.staffId = response.data.id;
                        this.approve.staff = response.data;
                        this.approve.deptId = response.data.department.id;
                        this.approve.dept = response.data.department;
                        this.getApproveSetting();
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Get Approve Setting
        getApproveSetting(){
            approveSettingService.getSetting(39).then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null && response.data.length > 0)
                    {
                        for (let i = 0; i < response.data.length; i++) {
                            let setting = response.data[i];
                            let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairEstimate), orderSort: 1, status: 1, ApprovedAt: null };
                            approve.id = 'id_' + common.getFakeId() + i;
                            approve.staffId = setting.staffId;
                            approve.deptId = setting.deptId;
                            approve.dept.code = setting.department.code;
                            approve.dept.name = setting.department.name;
                            approve.staff.code = setting.staff.code;
                            approve.staff.fullName = setting.staff.fullName;
                            approve.title = setting.title;
                            approve.orderSort = setting.orderSort + 1;
                            
                            this.entity.approveList.push(approve);
                        }
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Approve
        delApproveRow(item){
            const index = this.entity.approveList.findIndex(x => x.id == item.id);
            this.entity.approveList.splice(index, 1);
        },

        addApproveRow(){
            let order = 1;
            if(this.entity.approveList != null){
                order = this.entity.approveList.length + 1;
            }
            let item = { id: 'id_' + common.getFakeId(), status: 1, dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: '', typeCode: String(common.approveType.repairEstimate), orderSort: order, ApprovedAt: null };
            this.entity.approveList.push(item);
        },

        closeApprove(item){
            const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
            this.entity.approveList[selected].staff = item;
            this.entity.approveList[selected].staffId = item.id;
            this.$refs.approveList.close();
            this.selectedId = '';
        },

        openApprove(id){
            this.selectedId = id;
            const index = this.entity.approveList.findIndex(x => x.id == id);
            this.deptId = this.entity.approveList[index].deptId;
            this.$refs.approveList.open(this.deptId);
        },

        //DEPT
        closeDept(item){
            const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
            this.entity.approveList[selected].dept = item;
            this.entity.approveList[selected].title = item.name;
            this.entity.approveList[selected].deptId = item.id;
            if(item.managerId > 0){
                this.entity.approveList[selected].staff = item.manager;
                this.entity.approveList[selected].staffId = item.managerId;
            }
            this.$refs.deptList.close();
            this.selectedId = '';
        },

        openDept(id){
            this.selectedId = id;
            this.$refs.deptList.open();
        },

        getCode(staffId){
            this.setLoading(true);
            orderInvoiceService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.invoiceCode = response.data.code;
                    this.entity.invoiceNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.entity.approveList[0].staffId = item.id;
            this.entity.approveList[0].deptId = item.department.id;
            this.entity.approveList[0].dept.code = item.department.code;
            this.entity.approveList[0].dept.name = item.department.name;
            this.entity.approveList[0].staff.code = item.code;
            this.entity.approveList[0].staff.fullName = item.fullName;
            this.entity.approveList[0].title = "Người đề nghị";
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.entity.approveList[0].staffId = item.id;
            this.entity.approveList[0].deptId = item.department.id;
            this.entity.approveList[0].dept.code = item.department.code;
            this.entity.approveList[0].dept.name = item.department.name;
            this.entity.approveList[0].staff.code = item.code;
            this.entity.approveList[0].staff.fullName = item.fullName;
            this.entity.approveList[0].title = "Người đề nghị";
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
        'entity.thirdParty': function (val) { 
            if(val == false){
                this.companyName = '';
                this.entity.companyId = null;
            }
        },
    },
    validations: {
        entity: {
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            invoiceCode: { required },
            invoiceDate: { required },
            details: {
                $each: {
                    billPrice: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    billVat: {
                        required,
                        isSelected(value) {
                            if (value >= 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    billAmount: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            },
            approveList: {
                $each: {
                    deptId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    staffId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            }
        }
    }
})
</script>

<style>
    #crud-order-invoice .md-dialog-container {
        max-width: 95% !important;
    }
</style>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        max-width: 100%;
        width: 100%;
    }
    .page-content {
        min-height: 600px;
    }
</style>