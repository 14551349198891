<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                   <div class="row">
                        <div class="col l-4 m-4 c-12">
                           <md-field :class="{'md-invalid': submitted && $v.entity.companyCode.$error }">
                               <label for="companyCode">Mã hãng tàu</label>
                               <md-input name="companyCode" v-model="entity.companyCode"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.companyCode.required">Vui lòng nhập mã hãng tàu</span>
                           </md-field>

                           <md-field :class="{'md-invalid': submitted && $v.entity.companyAlias.$error }">
                               <label for="companyAlias">Tên viết tắt</label>
                               <md-input name="companyAlias" v-model="entity.companyAlias"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.companyAlias.required">Vui lòng nhập tên viết tắt</span>
                           </md-field>

                           <md-field :class="{'md-invalid': submitted && $v.entity.companyName.$error }">
                               <label for="companyName">Tên hãng tàu</label>
                               <md-input name="companyName" v-model="entity.companyName"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.companyName.required">Vui lòng nhập tên hãng tàu</span>
                           </md-field>
                       </div>
                       <div class="col l-4 m-4 c-12">
                            <md-field>
                               <label for="phone">Số điện thoại</label>
                               <md-input name="phone" v-model="entity.phone" ></md-input>
                           </md-field>
                           <md-field>
                               <label for="fax">Fax</label>
                               <md-input name="fax" v-model="entity.fax" ></md-input>
                           </md-field>
                            <md-field>
                               <label for="address">Địa chỉ</label>
                               <md-input name="address" v-model="entity.address" ></md-input>
                           </md-field>
                       </div>
                       <div class="col l-4 m-4 c-12">
                            <md-field>
                               <label for="email">Email</label>
                               <md-input name="email" v-model="entity.email" ></md-input>
                           </md-field>

                            <md-field>
                               <label for="note">Ghi chú</label>
                               <md-input name="note" v-model="entity.note" ></md-input>
                           </md-field>

                           <div class="form-control">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                       </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="bankName" @md-selected="getBankSelected" :md-options="banks" @md-changed="getBanks"  @md-opened="getBanks">
                                    <label>Ngân hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openBank()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="bankNumber">Số TK</label>
                                <md-input name="bankNumber" v-model="entity.bankNumber" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="bankBranch">Chi nhánh</label>
                                <md-input name="bankBranch" v-model="entity.bankBranch" ></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bankList ref="bankList" title="ngân hàng" :type="companyType" @close="closeBank"/>
   </div>
</template>
<script>
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import bankList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            bankList,
        },
        metaInfo: {
            title: 'Thêm/Cập nhật hãng tàu'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                companyType: common.companyType.bank,
                bankName: '',
                banks: [],
                entity: { id: 0, bankId: null, bankNumber: '', bankBranch: '', companyCode: '', companyAlias: '', companyName: '', phone: '', fax: '', address: '', email: '', note: '', typeCode: '', companyType: common.companyType.shipping, isActive: true },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật hãng tàu';
               this.getById();
            }
            else{
               this.title = 'Thêm mới hãng tàu';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //bank
            closeBank(item){
                this.bankName = item.companyName;
                this.entity.bankId = item.id;
                this.$refs.bankList.close();
            },

            getBankSelected(val){
                this.entity.bankId = val.id;
                this.bankName = val.companyName;
            },

            openBank(){
                this.$refs.bankList.open(1);
            },

            getBanks(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.bank, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.banks = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                companyService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                companyService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/shipping-lines');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                companyService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        if(response.data.bankId > 0){
                            this.bankName = response.data.bank.companyName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/shipping-lines');
            }
        },
        watch: {
            bankName: function (val) { 
                if(val == ''){
                    this.entity.bankId = null;
                }
            }
        },
        validations: {
            entity: {
                companyCode: { required },
                companyAlias: { required },
                companyName: { required }
            }
        }
     })

</script>
