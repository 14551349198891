import httpClient  from '../api';
const API_CONTROLLER = 'permission-individual/';

export default {
    getPermission(userId, moduleCode) {
        let url = API_CONTROLLER + 'get-permission';
        return httpClient.get(url, {
            params: {
                userId: userId,
                moduleCode: moduleCode
            }
        });
    },

    getAll(moduleCode) {
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                moduleCode: moduleCode
            }
        });
    },

    delete(id){
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    getById(id) {
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    add(data) {
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },
}