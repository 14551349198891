<template>
    <div class="table-content" style="padding: 10px 0;">
        <table class="data-table" style="padding: 0; min-height: calc(100vh - 500px) !important;">
            <thead>
                <tr>
                    <th style="width:100px;">#</th>
                    <th style="width:45%;">Nội dung</th>
                    <th class="right" style="width:25%;">Số lượng</th>
                    <th class="right" style="width:25%;">Số tiền</th>
                </tr>
            </thead>
            <tbody v-if="loadding == true" style="height: 150px;">
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
            </tbody>
            <tbody v-if="loadding == false">
                <tr>
                    <td class="center">1</td>
                    <td class="left">Tổng dự trù</td>
                    <td class="right">{{ formatNumber(data.budgetNum) }}</td>
                    <td class="right">{{ formatNumber(data.budgetAmount) }}</td>
                </tr>
                <tr>
                    <td class="center">2</td>
                    <td class="left">Tổng dự toán đơn hàng</td>
                    <td class="right">{{ formatNumber(data.orderEstimateNum) }}</td>
                    <td class="right">{{ formatNumber(data.orderEstimateAmount) }}</td>
                </tr>
                <tr>
                    <td class="center">3</td>
                    <td class="left">Tổng dự toán dự án</td>
                    <td class="right">{{ formatNumber(data.projectEstimateNum) }}</td>
                    <td class="right">{{ formatNumber(data.projectEstimateAmount) }}</td>
                </tr>
                <tr>
                    <td class="center">4</td>
                    <td class="left">Tổng tạm ứng</td>
                    <td class="right">{{ formatNumber(data.advancePaymentNum) }}</td>
                    <td class="right">{{ formatNumber(data.advancePaymentAmount) }}</td>
                </tr>
                <tr>
                    <td class="center">5</td>
                    <td class="left">Tổng quyết toán</td>
                    <td class="right">{{ formatNumber(data.invoiceNum) }}</td>
                    <td class="right">{{ formatNumber(data.invoiceAmount) }}</td>
                </tr>
                <tr>
                    <td class="center">6</td>
                    <td colspan="2" class="left" style="font-weight: bold;">Tổng tiền còn lại</td>
                    <td class="right">
                        <span v-if="data.remain > 0" style="font-weight: bold; color: #00c154;">{{ formatNumber(data.remain) }}</span>
                        <span v-else style="font-weight: bold; color: red;">{{ formatNumber(data.remain) }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import reportProjectService from '@/api/reportProjectService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        props: ["project"],
        data() {
            return {
                loadding: false,
                data: [],
                id: 0,
                projectId: 0
            }
        },
        created(){
            this.getData();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            refresh(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.projectId = this.$route.params.id;
                reportProjectService.getReportSummary(this.projectId).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                        console.log(this.data)
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        }
    }

</script>
