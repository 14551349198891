<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Danh sách dự toán</div>
                    <div class="dialog-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
                <div class="dialog-search">
                    <md-field>
                        <md-input v-model="code" placeholder="Tìm theo số dự toán"></md-input>
                        <md-icon>search</md-icon>
                    </md-field>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th class="left" style="width: 230px;">Số dự toán</th>
                                        <th class="left" style="width: 150px;">Loại dự toán</th>
                                        <th class="right" style="width: 140px;">Tiền dự toán</th>
                                        <th class="right" style="width: 140px;">Tạm ứng</th>
                                        <th class="center" style="width: 140px;">Ngày dự toán</th>
                                        <th class="left" style="width: 150px;">Người dự toán</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && data.length > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td><a @click="selected(item)">{{item.estimateCode}}</a></td>
                                        <td class="left">
                                            <span v-if="item.orderId > 0" style="color: #FF8000;">Dự toán đơn hàng</span>
                                            <span v-else style="color: #ED3EF7;">Dự toán dự án</span>
                                        </td>
                                        <td class="right">{{ formatNumber(item.estimateAmount) }}</td>
                                        <td class="right">{{ formatNumber(item.advancePaymentAmount) }}</td>
                                        <td class="center">{{ item.estimateDate }}</td>
                                        <td class="left">{{ item.staff.fullName }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-dialog>
    </div>
</template>

<script>
    import projectEstimateService from '../../api/projectEstimateService'; 
    import messageBox from '../../utils/messageBox'; 

    export default {
        data: () => ({
            showDialog: false,
            data: [],
            loadding: false, 
            projectId: 0,
            code: ''
        }),

        created() {
        },

        methods: { 
            selected(item){
                this.$emit('close', item);
            },

            open(projectId){
                this.projectId = projectId;
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            refresh() {
                this.getData();
            },

            getData(){
                this.loadding = true;               
                projectEstimateService.getForAdvancePayment(this.projectId, this.code).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: {
            code: function () {  
                this.getData(); 
            },
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 1000px;
        height: 455px;
        max-width: 1000px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
</style>