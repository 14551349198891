<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="37" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Dự toán làm hàng</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                            
                            </div>
                        </div>
                        <div class="form-body">
                            <div id="view-print" style="width: 850px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                                <div style="height: 100px; display: flex; justify-content: space-between;">
                                    <div style="border-bottom: 1px solid #999; 
                                        width: 25%; display: flex;align-items: center; justify-content: center;
                                        border-top: 1px solid #999;border-left: 1px solid #999;">
                                        <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                                    </div>
                                    <div style="border-bottom: 1px solid #999;
                                        border-left: 1px solid #999;
                                        border-top: 1px solid #999;
                                        border-right: 1px solid #999;
                                        width: 45%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-transform: uppercase;
                                        font-weight: bold;">
                                        <div style="font-size: 16px;"><h3>DỰ TOÁN LÀM HÀNG</h3></div>
                                    </div>
                                    <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                        <div><span style="font-weight: bold;">Số: </span><span>{{entity.estimateCode}}</span></div>
                                        <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.estimateDate}}</span></div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column; padding: 10px 0;">
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Tên tôi là:</div>
                                        <div style="font-size: 14px;">{{ entity.staff.fullName  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Phòng/ban:</div>
                                        <div style="font-size: 14px;">{{ entity.dept.name  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Nội dung:</div>
                                        <div style="font-size: 14px;">{{ entity.note  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Đơn hàng:</div>
                                        <div style="font-size: 14px;"><router-link :to="'/order/crud/' + entity.order.id">{{entity.order.orderCode}}</router-link></div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Bill/booking:</div>
                                        <div style="font-size: 14px;">{{entity.order.booking.noBooking}}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Khách hàng:</div>
                                        <div style="font-size: 14px;">{{entity.order.customer.companyName}}</div>
                                    </div>
                                    <div style="display: flex; padding: 0;">
                                        <div style="font-size: 14px; padding-right: 10px;">Số lượng:</div>
                                        <div style="font-size: 14px;">
                                            <div v-for="(unit, index) in entity.order.units" :key="'unit-' + index">
                                                <div>{{ unit.num + ' - ' + unit.unitName }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; margin: 0 auto;">
                                    <div style="display: flex; margin: 10px auto;">
                                        <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                            <thead>
                                                <tr>
                                                    <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0; background-color: #F5EFE7;">Stt</th>
                                                    <th style="width:250px; text-align: left; padding: 5px 5px; border: 1px solid #e0e0e0; background-color: #F5EFE7;">Tên phí</th>
                                                    <th style="width:150px; text-align: left; padding: 5px 5px; border: 1px solid #e0e0e0; background-color: #F5EFE7;">Loại</th>
                                                    <th style="width:150px; text-align: right; padding: 5px 5px; border: 1px solid #e0e0e0; background-color: #F5EFE7;">Đơn giá</th>
                                                    <th style="width:150px; text-align: center; padding: 5px 5px; border: 1px solid #e0e0e0; background-color: #F5EFE7;">VAT</th>
                                                    <th style="width:150px; text-align: right; padding: 5px 5px; border: 1px solid #e0e0e0; background-color: #F5EFE7;">Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in entity.details" :key="'item-' + index">
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{index + 1}}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px; text-align: left;">{{ item.fee.feeName }}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px; text-align: left;">{{ getFeeType(item.type) }}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px; text-align: right;">{{formatNumber(item.price)}}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{formatNumber(item.vat)}}%</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px; text-align: right;">{{formatNumber(item.amount)}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="5" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"><span style="font-weight: bold;font-size: 14px;">Tổng dự toán</span></td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px;text-align: right; "><span style="font-weight: bold; font-size: 14px;">{{ formatNumber(entity.pay) }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div style="padding: 20px 0; display: flex; justify-content: space-between;">
                                    <div v-for="item in entity.approveList" :key="item.id" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                        <div style="text-transform: uppercase; font-size: 14px; font-weight: bold; padding-bottom: 10px;">{{item.title}}</div>
                                        <div style="text-align: center;">
                                            <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                            <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                            <md-chip v-else-if="item.status == 3" class="md-status-2">Yêu cầu chỉnh sửa</md-chip>
                                            <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                        </div>
                                        <div style="font-size: 14px; font-weight: bold; padding-top: 50px;">{{item.staff.fullName}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import orderEstimateService from '@/api/orderEstimateService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';
    import comment from '../../../components/Comment.vue';

    export default ({
        components: {
            comment
        },
        metaInfo: {
            title: 'Dự toán làm hàng'
        },
        data() {
            return {
               title: 'Dự toán làm hàng',
               id: 0,
               submitted: false,
               loadding: false,
               details: [],
               entity: { id: 0, staff: { fullName: '' }, customer: { companyName: '' } },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getById(){
                this.setLoading(true);
                orderEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })

</script>

<style scoped>
    table.table-1 input {
        height: 30px;
        width: 100%;
        border: 1px solid #e0e0e0;
        padding: 0 5px;
    }
    .md-checkbox {
        margin: 0 !important;
    }
    .md-content {
        padding: 0 15px 0px 15px;
        width: 550px;
        height: 455px;
        max-width: 550px;
        max-height: 150px;
    }
    .dialog-content {
        height: 150px;
    }
    .action {
        position: sticky;
        bottom: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
    }
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 16px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 15px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 15px;
    }
    .line span{
        font-size: 15px;
        font-weight: bold;
    }
    .line div {
        font-size: 15px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 140px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 17px;
    }
</style>