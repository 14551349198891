<template>
    <div class="page-content" style="background-color: #e9ecf3;">
        <div class="grid">
            <div class="row">
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập đơn hàng thường</div>
                        <div class="by-branch">
                            <div class="title-2">Thống kê theo chi nhánh</div>
                            <table class="data-table" style="min-height: 150px !important;height: 200px !important;">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:56%;">Chi nhánh</th>
                                        <th class="right" style="width:20%;">Số lượng</th>
                                        <th class="center" style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in orderByBranch" :key="item.branchName">
                                        <td class="center">{{index + 1}}</td>
                                        <td>{{ item.branchName }}</td>
                                        <td class="right">{{ formatFloat(item.total, 0) }}</td>
                                        <td class="center">{{ item.lastDate }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="right" style="font-weight: bold;">Tổng số đơn hàng</td>
                                        <td style="font-weight: bold;" class="right">{{ formatFloat(totalOrder, 0) }}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2">Thống kê theo nhân viên nhập</div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && orderByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && orderByStaff.length > 0">
                                    <tr v-for="(item, index) in orderByStaff" :key="'order-by-staff' + item.staffCode" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng điều xe hàng thường</div>
                        <div class="title-2">Thống kê theo chi nhánh</div>
                        <div class="by-branch">
                            <table class="data-table" style="min-height: 150px !important;height: 200px !important;">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:66%;">Chi nhánh</th>
                                        <th class="right" style="width:30%;">Số lượng</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in jobByBranch" :key="item.branchName">
                                        <td class="center">{{index + 1}}</td>
                                        <td>{{ item.branchName }}</td>
                                        <td class="right">{{ formatFloat(item.total, 0) }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="right" style="font-weight: bold;">Tổng số điều xe</td>
                                        <td style="font-weight: bold;" class="right">{{ formatFloat(totalJob, 0) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="title-2">Thống kê theo nhân viên nhập</div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && jobByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && jobByStaff.length > 0">
                                    <tr v-for="(item, index) in jobByStaff" :key="'job-by-staff' + item.staffCode" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  

            <div class="row" style="margin-top: 15px;">
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập chi phí làm hàng</div>
                        <div class="title-2" style="display: flex; justify-content: space-between;">
                            <div>Thống kê nhập phiếu dự toán làm hàng theo nhân viên nhập</div>
                            <div>Tổng số dự toán: {{ statsOrderEstimate.totalEstimate }}</div>
                        </div>
                        <div class="table-fixed" style="height: 348px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderEstimate.estimate.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderEstimate.estimate.length > 0">
                                    <tr v-for="(item, index) in statsOrderEstimate.estimate" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2" style="display: flex; justify-content: space-between; padding-top: 20px;">
                            <div>Thống kê nhập phiếu tạm ứng làm hàng theo nhân viên nhập</div>
                            <div>Tổng số tạm ứng: {{ statsOrderEstimate.totalAdvancePayment }}</div>
                        </div>
                        <div class="table-fixed" style="height: 348px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderEstimate.advancePayment.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderEstimate.advancePayment.length > 0">
                                    <tr v-for="(item, index) in statsOrderEstimate.advancePayment" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2" style="display: flex; justify-content: space-between; padding-top: 20px;">
                            <div>Thống kê nhập phiếu quyết toán làm hàng theo nhân viên nhập</div>
                            <div>Tổng số quyết toán: {{ statsOrderEstimate.totalInvoice }}</div>
                        </div>
                        <div class="table-fixed" style="height: 348px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderEstimate.invoice.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderEstimate.invoice.length > 0">
                                    <tr v-for="(item, index) in statsOrderEstimate.invoice" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập sửa chữa</div>
                        <div class="title-2" style="display: flex; justify-content: space-between;">
                            <div>Thống kê nhập phiếu đề nghị sửa chữa theo nhân viên nhập</div>
                            <div>Tổng số phiếu đề nghị: {{ statsRepair.totalRequest }}</div>
                        </div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.request.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.request.length > 0">
                                    <tr v-for="(item, index) in statsRepair.request" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="title-2" style="display: flex; justify-content: space-between; padding-top: 20px;">
                            <div>Thống kê nhập phiếu dự toán sửa chữa theo nhân viên nhập</div>
                            <div>Tổng số dự toán: {{ statsRepair.totalEstimate }}</div>
                        </div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.estimate.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.estimate.length > 0">
                                    <tr v-for="(item, index) in statsRepair.estimate" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2" style="display: flex; justify-content: space-between; padding-top: 20px;">
                            <div>Thống kê nhập phiếu tạm ứng sửa chữa theo nhân viên nhập</div>
                            <div>Tổng số tạm ứng: {{ statsRepair.totalAdvancePayment }}</div>
                        </div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.advancePayment.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.advancePayment.length > 0">
                                    <tr v-for="(item, index) in statsRepair.advancePayment" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2" style="display: flex; justify-content: space-between; padding-top: 20px;">
                            <div>Thống kê nhập phiếu quyết toán sửa chữa theo nhân viên nhập</div>
                            <div>Tổng số quyết toán: {{ statsRepair.totalInvoice }}</div>
                        </div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.invoice.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsRepair.invoice.length > 0">
                                    <tr v-for="(item, index) in statsRepair.invoice" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  

            <div class="row" style="margin-top: 15px;">
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập phiếu nhập kho</div>
                        <div class="title-2" style="display: flex; justify-content: space-between;">
                            <div>Thống kê nhập phiếu nhập kho theo nhân viên nhập</div>
                            <div>Tổng số phiếu nhập: {{ statsStore.totalReceipt }}</div>
                        </div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsStore.receiptByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsStore.receiptByStaff.length > 0">
                                    <tr v-for="(item, index) in statsStore.receiptByStaff" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập phiếu xuất kho</div>
                        
                        <div class="title-2" style="display: flex; justify-content: space-between;">
                            <div>Thống kê nhập phiếu xuất kho theo nhân viên nhập</div>
                            <div>Tổng số phiếu xuất: {{ statsStore.totalExport }}</div>
                        </div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsStore.exportByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsStore.exportByStaff.length > 0">
                                    <tr v-for="(item, index) in statsStore.exportByStaff" :key="'receipt-by-staff' + index" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row" style="margin-top: 15px;">
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập dự án hàng siêu trường siêu trọng</div>
                        <div class="title-2">Thống kê theo chi nhánh</div>
                        <div class="by-branch">
                            <table class="data-table" style="min-height: 150px !important;height: 200px !important;">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:56%;">Chi nhánh</th>
                                        <th class="right" style="width:20%;">Số lượng</th>
                                        <th class="center" style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in statsProjectHeavy.projectByBranch" :key="item.branchName">
                                        <td class="center">{{index + 1}}</td>
                                        <td>{{ item.branchName }}</td>
                                        <td class="right">{{ formatFloat(item.total, 0) }}</td>
                                        <td class="center">{{ item.lastDate }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="right" style="font-weight: bold;">Tổng số dự án</td>
                                        <td style="font-weight: bold;" class="right">{{ formatFloat(totalProjectHeavy, 0) }}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2">Thống kê theo nhân viên nhập</div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsProjectHeavy.projectByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsProjectHeavy.projectByStaff.length > 0">
                                    <tr v-for="(item, index) in statsProjectHeavy.projectByStaff" :key="'project-heavy-by-staff' + item.staffCode" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng đơn hàng siêu trường siêu trọng</div>
                        <div class="title-2">Thống kê theo chi nhánh</div>
                        <div class="by-branch">
                            <table class="data-table" style="min-height: 150px !important;height: 200px !important;">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:66%;">Chi nhánh</th>
                                        <th class="right" style="width:30%;">Số lượng</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in statsOrderHeavy.orderByBranch" :key="item.branchName">
                                        <td class="center">{{index + 1}}</td>
                                        <td>{{ item.branchName }}</td>
                                        <td class="right">{{ formatFloat(item.total, 0) }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="right" style="font-weight: bold;">Tổng số đơn hàng</td>
                                        <td style="font-weight: bold;" class="right">{{ formatFloat(totalOrderHeavy, 0) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="title-2">Thống kê theo nhân viên</div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:25%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderHeavy.orderByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsOrderHeavy.orderByStaff.length > 0">
                                    <tr v-for="(item, index) in statsOrderHeavy.orderByStaff" :key="'job-by-staff' + item.staffCode" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row" style="margin-top: 15px;">
                <div class="col l-6 m-6 c-12">
                    <div class="wrapper">
                        <div class="title">Thống kê số lượng nhập điều xe hàng siêu trường siêu trọng</div>
                        <div class="title-2">Thống kê theo chi nhánh</div>
                        <div class="by-branch">
                            <table class="data-table" style="min-height: 150px !important;height: 200px !important;">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:56%;">Chi nhánh</th>
                                        <th class="right" style="width:20%;">Số lượng</th>
                                        <th class="center" style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in statsJobHeavy.jobByBranch" :key="item.branchName">
                                        <td class="center">{{index + 1}}</td>
                                        <td>{{ item.branchName }}</td>
                                        <td class="right">{{ formatFloat(item.total, 0) }}</td>
                                        <td class="center">{{ item.lastDate }}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="right" style="font-weight: bold;">Tổng số dự án</td>
                                        <td style="font-weight: bold;" class="right">{{ formatFloat(totalProjectHeavy, 0) }}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="title-2">Thống kê theo nhân viên nhập</div>
                        <div class="table-fixed" style="height: 250px; overflow-x: auto; width: 100%;">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width:40px;">#</th>
                                        <th class="left" style="width:15%;">Mã nhân viên</th>
                                        <th class="left" style="width:26%;">Tên nhân viên</th>
                                        <th class="left" style="width:20%;">Phòng ban</th>
                                        <th class="right" style="width:15%;">Số lượng</th>
                                        <th style="width:20%;">Ngày nhập cuối</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsJobHeavy.jobByStaff.length == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && statsJobHeavy.jobByStaff.length > 0">
                                    <tr v-for="(item, index) in statsJobHeavy.jobByStaff" :key="'job-heavy-by-staff' + item.staffCode" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td class="left">{{item.staffCode}}</td>
                                        <td class="left">{{item.staffName}}</td>
                                        <td class="left">{{item.deptName}}</td>
                                        <td class="right">{{ formatFloat(item.total, 0)}}</td>
                                        <td class="center">{{item.lastDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import reportDashboardService from '../../api/reportDashboardService';
    import messageBox from '../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Dashboard'
        },
        data() {
            return {
                loadding: false,
                orderByStaff: [],
                orderByBranch: [],
                totalOrder: 0,
                jobByStaff: [],
                jobByBranch: [],
                totalJob: 0,
                statsProjectHeavy: { projectByBranch: [], projectByStaff: [] },
                totalProjectHeavy: 0,
                statsOrderHeavy: { orderByBranch: [], orderByStaff: [] },
                totalOrderHeavy: 0,
                statsJobHeavy: { jobByBranch: [], jobByStaff: [] },
                totalJobHeavy: 0,
                statsStore: { totalReceipt: 0, totalExport: 0, receiptByStaff: [], exportByStaff: [] },
                statsOrderEstimate: { totalEstimate: 0, totalAdvancePayment: 0, estimate: [], advancePayment: [], invoice: [] },
                statsRepair: { totalRequest: 0, request: [], totalEstimate: 0, totalAdvancePayment: 0, estimate: [], advancePayment: [], invoice: [] },
            }
        },
        created(){
            this.getStatsOrder();
            this.getStatsJob();
            this.getStatsProjectHeavy();
            this.getStatsOrderHeavy();
            this.getStatsJobHeavy();
            this.getStatsStore();
            this.getStatsOrderEstimate();
            this.getStatsRepair();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getStatsRepair(){
                this.loadding = true;
                reportDashboardService.getStatsRepair().then((response) => {
                    if(response.statusCode == 200){
                        this.statsRepair.request = response.data.requestByStaff;
                        this.statsRepair.estimate = response.data.estimateByStaff;
                        this.statsRepair.advancePayment = response.data.advancePaymentByStaff;
                        this.statsRepair.invoice = response.data.invoiceByStaff;

                        const totalRequest = this.statsRepair.request.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsRepair.totalRequest = totalRequest;

                        const totalEstimate = this.statsRepair.estimate.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsRepair.totalEstimate = totalEstimate;

                        const totalAdvancePayment = this.statsRepair.advancePayment.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsRepair.totalAdvancePayment = totalAdvancePayment;

                        const totalInvoice = this.statsRepair.invoice.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsRepair.totalInvoice = totalInvoice;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsOrderEstimate(){
                this.loadding = true;
                reportDashboardService.getStatsOrderEstimate().then((response) => {
                    if(response.statusCode == 200){
                        this.statsOrderEstimate.estimate = response.data.estimateByStaff;
                        this.statsOrderEstimate.advancePayment = response.data.advancePaymentByStaff;
                        this.statsOrderEstimate.invoice = response.data.invoiceByStaff;
                        const totalEstimate = this.statsOrderEstimate.estimate.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsOrderEstimate.totalEstimate = totalEstimate;

                        const totalAdvancePayment = this.statsOrderEstimate.advancePayment.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsOrderEstimate.totalAdvancePayment = totalAdvancePayment;

                        const totalInvoice = this.statsOrderEstimate.invoice.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsOrderEstimate.totalInvoice = totalInvoice;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsStore(){
                this.loadding = true;
                reportDashboardService.getStatsStore().then((response) => {
                    if(response.statusCode == 200){
                        this.statsStore.receiptByStaff = response.data.receiptByStaff;
                        this.statsStore.exportByStaff = response.data.exportByStaff;
                        const totalReceipt = this.statsStore.receiptByStaff.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsStore.totalReceipt = totalReceipt;
                        const totalExport = this.statsStore.exportByStaff.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.statsStore.totalExport = totalExport;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsJobHeavy(){
                this.loadding = true;
                reportDashboardService.getStatsJobHeavy().then((response) => {
                    if(response.statusCode == 200){
                        this.statsJobHeavy = response.data;
                        const totalJobHeavy = this.statsJobHeavy.jobByBranch.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.totalJobHeavy = totalJobHeavy;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsOrderHeavy(){
                this.loadding = true;
                reportDashboardService.getStatsOrderHeavy().then((response) => {
                    if(response.statusCode == 200){
                        this.statsOrderHeavy = response.data;
                        const totalOrderHeavy = this.statsOrderHeavy.orderByBranch.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.totalOrderHeavy = totalOrderHeavy;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsProjectHeavy(){
                this.loadding = true;
                reportDashboardService.getStatsProjectHeavy().then((response) => {
                    if(response.statusCode == 200){
                        this.statsProjectHeavy = response.data;
                        const totalProjectHeavy = this.statsProjectHeavy.projectByBranch.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.totalProjectHeavy = totalProjectHeavy;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsJob(){
                this.loadding = true;
                reportDashboardService.getStatsJob().then((response) => {
                    if(response.statusCode == 200){
                        this.jobByStaff = response.data.orderByStaff;
                        this.jobByBranch = response.data.orderByBranch;
                        const totalAmount = this.jobByStaff.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.totalJob = totalAmount;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            getStatsOrder(){
                this.loadding = true;
                reportDashboardService.getStatsOrder().then((response) => {
                    if(response.statusCode == 200){
                        this.orderByStaff = response.data.orderByStaff;
                        this.orderByBranch = response.data.orderByBranch;
                        const totalAmount = this.orderByStaff.reduce((accumulator, order) => {
                            return accumulator + order.total;
                        }, 0);
                        this.totalOrder = totalAmount;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },
        }
    }
</script>

<style scoped>
    .wrapper {
        background-color: #FFF;
        border-radius: 10px;
        padding: 15px;
    }
    .title {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10px;
    }
    .title-2 {
        font-weight: bold;
        padding-bottom: 5px;
    }
    .by-branch ul{
        display: flex;
    }
    .by-branch ul li {
        border: 1px solid #CCC;
        padding: 5px 10px;
    }
</style>