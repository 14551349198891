<template>
    <div class="page-container">
        <div class="functions">
            <div class="function head">
                <div>Chức năng</div>
                <div>Role</div>
            </div>
            <div @click="getAll('order')" class="function" :class="{ 'function-active': moduleCode == 'order' }">
                <div>Đơn hàng</div>
                <div>Tạo dự toán</div>
            </div>
            <div @click="getAll('project-budget')" class="function" :class="{ 'function-active': moduleCode == 'project-budget' }">
                <div>Dự trù dự án STST</div>
                <div>Chỉnh sửa/ xóa dự trù</div>
            </div>
        </div>
        <div class="user-list">
            <md-button @click="openAdmin()" class="md-raised md-primary">Thêm tài khoản</md-button>
            <div class="table-list">
                <table class="data-table">
                    <thead>
                        <tr>
                            <th style="width: 100px;">#</th>
                            <th class="center" style="width: 10%;">Hành động</th>
                            <th class="left" style="width: 10%;">Mã nhân viên</th>
                            <th class="left" style="width: 15%;">Tên đăng nhập</th>
                            <th class="left" style="width: 20%;">Họ và tên</th>
                            <th class="left" style="width: 25%;">Phòng ban</th>
                            <th class="left" style="width: 25%;">Chi nhánh</th>
                        </tr>
                    </thead>
                    <tbody v-if="loading == true" style="height: 150px;">
                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                    </tbody>
                    <tbody v-if="loading == false && data.length == 0" style="height: 150px;">
                        <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                    </tbody>
                    <tbody v-if="loading == false && data.length > 0">
                        <tr v-for="(item, index) in data" :key="item.id">
                            <td class="center">{{index + 1}}</td>
                            <td class="action">
                                <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                    <md-icon>clear</md-icon>
                                    <md-tooltip>Xóa</md-tooltip>
                                </md-button>
                            </td>
                            <td class="left">{{item.admin.staffCode}}</td>
                            <td class="left">{{item.admin.userName}}</td>
                            <td class="left">{{item.admin.fullName}}</td>
                            <td class="left">{{item.admin.deptName}}</td>
                            <td class="left">{{item.admin.branchName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <adminList ref="adminList" @close="closeAdmin"/>
    </div>
</template>

<script>
import permisonIndividualService from '../../../api/permisonIndividualService';
import adminList from '../../../components/popup/_AdminList.vue';
import messageBox from '../../../utils/messageBox';
import { mapActions } from 'vuex';

export default {
    components: {
        adminList
    },
    data(){
        return {
            loading: false,
            data: [],
            moduleCode: 'order'
        }
    },
    created() {
        this.getAll('order');
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        closeAdmin(item){
            this.add(item.id);
            this.$refs.adminList.close();
        },

        add(userId){
            this.setLoading(true);
            let actionCode = "CRUD";
            if(this.moduleCode == "order"){
                actionCode = "CRUD-ESTIMATE";
            }
            else if(this.moduleCode == "project-budget"){
                actionCode = "UD-PROJECT-BUDGET";
            }
            let entity = { id: 0, userId: userId, moduleCode: this.moduleCode, actionCode: actionCode };
            permisonIndividualService.add(entity).then((response) => {
                if(response.statusCode == 200){
                    this.getAll(this.moduleCode);
                    messageBox.showMessage("Thêm mới thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getAll(moduleCode){
            this.moduleCode = moduleCode;
            this.setLoading(true);
            permisonIndividualService.getAll(moduleCode).then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false);});
        },

        openAdmin(){
            this.$refs.adminList.open();
        },

        confirmDelete(id){
            this.$modal.show('dialog', {
            title: 'Thông báo',
            text: 'Bạn có chắc muốn xóa không?',
            buttons: [
                {
                    title: 'Hủy',
                    handler: () => {
                        this.$modal.hide('dialog')
                    }
                },
                {
                    title: 'Xóa',
                    handler: () => {
                        this.del(id)
                    }
                }
            ]
            })
        },

        del(id){
            this.$modal.hide('dialog');
            this.setLoading(true);
            permisonIndividualService.delete(id).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Xóa thành công");
                    this.getAll(this.moduleCode);
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },
    }
}
</script>

<style scoped>
    .function-active {
        background-color: antiquewhite;
    }
    .page-container {
        display: flex;
        height: calc(100vh - 110px);
    }
    .functions {
        display: flex;
        flex-direction: column;
        width: 350px;
        background-color: #FFF;
        border-right: 1px solid #eee;
    }
    .function:first-child {
        background-color: #eee;
        height: 35px;
        border-top: 1px solid #ccc;
    }
    .function:first-child div {
        font-weight: bold;
    }
    .function:hover {
        background-color: antiquewhite;
        cursor: pointer;
    }
    .function:first-child:hover {
        background-color: #eee;
        cursor: auto;
    }
    .function {
        display: flex;
    }
    .function div {
        padding: 10px;
        width: 50%;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
    .function div:last-child {
        border-right: 1px solid #ccc;
    }
    .user-list {
        width: calc(100vw - 360px);
        margin-left: 10px;
        height: calc(100vh - 110px);
        background-color: #FFF;
    }
    .table-list {
        padding: 10px;
    }
</style>