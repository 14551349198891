<template>
    <div>
        <header>
            <div class="nav-toggle">
                <div>
                    <span class="line top"></span>
                    <span class="line middle"></span>
                    <span class="line bottom"></span>
                </div>
            </div>
            <div class="logo"><a href="/"><img alt="iqsoft" src="../assets/images/logo.png" ></a></div>
            <div class="top-menu">
                <div class="helper">
                    <router-link to="/huong-dan-su-dung">
                        <i class="fa-regular fa-circle-question"></i>
                    </router-link>
                </div>
                <div style="margin-left: 5px;">
                    <router-link to="/notify">
                        <md-badge :md-content="notify" md-dense>
                            <md-button class="md-icon-button">
                                <md-icon>notifications</md-icon>
                            </md-button>
                        </md-badge>
                    </router-link>
                </div>
                <div class="account-dropdown-menu">
                    <a href="#"><i class="far fa-user-circle"></i><span>{{ currentUser }}</span></a>
                    <ul class="dropdown-list">
                        <li><router-link to="/user/info"><i class="fas fa-info-circle"></i> Thông tin tài khoản</router-link></li>
                        <li><router-link to="/user/change-password"><i class="fas fa-key"></i> Đổi mật khẩu</router-link></li>
                        <li><router-link to="/huong-dan-su-dung"><i class="fa-regular fa-circle-question"></i> Hướng dẫn sử dụng</router-link></li>
                        <li><a v-on:click="logout()"><i class="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>
            </div>
        </header>
        <nav id="menu">
            <ul id="navMenu">
                <li v-for="item in menu" :key="item.moduleId"><a href="#">{{item.moduleName}}</a>
                    <ul v-if="item.children.length > 0" class="sub-menu">
                        <li v-for="child in item.children" :key="child.moduleId">
                            <router-link :class="{ 'disabled': !child.isRead }" :to="child.url"><span>{{child.moduleName}}</span><i v-if="child.children.length > 0" class="fa-solid fa-caret-right"></i></router-link>
                            <ul v-if="child.children.length > 0">
                                <li v-for="c in child.children" :key="c.moduleId">
                                    <router-link :class="{ 'disabled': !c.isRead }" :to="c.url">{{c.moduleName}}</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import permissionService from './../api/permissionService';
import notifyService from './../api/notifyService';
import messageBox from './../utils/messageBox';
import $ from 'jquery';
import { mapActions } from 'vuex';

export default({
    data() {
        return {
            currentUser: '',
            loadding: false,
            menu: [],
            notify: 0
        }
    },

    created(){
        this.currentUser = localStorage.getItem("fullName");
        this.getMenu();
        this.getNotify();
    },
    methods: {
        ...mapActions('common', ['setLogout']),

        getMenu(){
            this.loadding = true;
            permissionService.getMenu().then((response) => {
                if(response.statusCode == 200){
                    this.menu = response.data;
                }
                else{
                    messageBox.showWarning(response.error);
                }
            }).finally(() => { this.loadding = false; });
        },

        getNotify(){
            this.loadding = true;
            notifyService.getNotify().then((response) => {
                if(response.statusCode == 200){
                    this.notify = response.data;
                }
                else{
                    messageBox.showWarning(response.error);
                }
            }).finally(() => { this.loadding = false; });
        },
        logout() {
            this.setLogout();
            this.$router.push('/login');
        },
    },
    mounted(){
        $(document).ready(function(){
            const navToggle = document.querySelector(".nav-toggle");
            $(".nav-toggle").click(function() {
                navToggle.classList.toggle("close");
                $("#menu").toggleClass("open");
            });
        });
    },
})
</script>

<style scoped>


header{
    background-color: var(--bg-header);
    position: sticky;
    z-index: 1050;
    height: 45px;
    line-height: 45px;
    top: 0;
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    border-bottom: 1px solid #333;
    justify-content: space-between;
}

header .logo img{
    display: inline;
    height: 30px;
    vertical-align: middle;
}

header .top-menu {
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

.md-badge-content {
    display: flex;
    align-items: center;
}

header .top-menu a{
    color: #eee;
}

header .top-menu a i{
    color: #eee;
    font-size: 16px;
}

header > .top-menu > div{
    margin-left: 20px;
}

header > .top-menu .account-dropdown-menu > a::after{
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\F0D7";
    margin-left: 8px;
    display: inline-block;
}

header > .top-menu .account-dropdown-menu > a:hover{
    color: var(--color-primary);
}

.nav-toggle{
    display: none;
}

.nav-toggle div{
    border: 1px solid var(--color-primary);
    display: flex;
    margin: 9px 0;
    position: relative;
    cursor: pointer;
    width: 30px;
    border-radius: 4px;
    justify-content: center;
}

.nav-toggle:focus{
	outline: none;
}

.nav-toggle span.line{
    position: absolute;
    height: 2px;
    width: 18px;
    background-color: var(--color-primary);
    border-radius: 8px;
    transition: all cubic-bezier(0.26, 0.1, 0.27, 1.55) 0.25s;
}

.nav-toggle .top{
    top: 25%;
}

.nav-toggle .middle{
    top: 45%;
}

.nav-toggle .bottom{
    top: 65%
}

.nav-toggle.close .top{
    transform: rotate(45deg);
    top: 45%;
}

.nav-toggle.close .middle,
.nav-toggle.close .bottom
{
    transform: rotate(-45deg);
    top: 45%;
}

.account-dropdown-menu{
    position: relative;
}

.account-dropdown-menu .dropdown-list{
    position: absolute;
    right: 0;
    background-color: var(--bg-header);
    list-style: none;
    width: 200px;
    opacity: 0;
    border-top: 1px solid var(--color-primary);
    padding-bottom: 10px;
    z-index: 1051;
    visibility: hidden;
}

.account-dropdown-menu:hover > .dropdown-list{
    opacity: 1;
    visibility: visible;
    top: 43px;
}

.account-dropdown-menu .dropdown-list li{
    height: 35px;
}

.account-dropdown-menu .dropdown-list li a{
    display: block;
    padding-left: 15px;
}

.account-dropdown-menu .dropdown-list li:hover a{
    color: var(--color-primary);
}

.account-dropdown-menu .dropdown-list i{
    color: var(--color-primary);
    font-size: 13px;
    padding-right: 5px;
}

.account-dropdown-menu > a > i{
    font-size: 15px;
    padding-right: 5px;
}

#menu {
    background-color: var(--bg-menu);
    height: 35px;
    z-index: 1049;
    position: sticky;
    top: 45px;
}

.horizontal-menu{
    line-height: 35px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    z-index: 1051;
}

.horizontal-menu > li{
    border-right: 1px solid #333;
    position: relative;
    border-bottom: 1px solid #333;
}

.horizontal-menu a{
    display: block;
    color: #eee !important;
    padding: 0 15px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
}

.horizontal-menu a:hover {
    color: var(--color-primary) !important;
}

.horizontal-menu .disabled{
    pointer-events: none !important;
}

.horizontal-menu a.disabled{
    color: #d9dad7 !important;
    opacity: .5;
}

.horizontal-menu > li.has-sub-menu > a::after {
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\F0D7";
    margin-left: 8px;
    display: inline-block;
}

.horizontal-menu > li > ul{
    position: absolute;
    background-color: var(--bg-menu);
    list-style: none;
    width: 240px;
    padding-left: 0;
    top: -999px;
    z-index: 1051;
}

.horizontal-menu > li > ul > li{
    background-color: var(--bg-menu);
    height: 35px;
    line-height: 35px;
    border-top: 1px solid #333;
}

.horizontal-menu > li:hover ul{
    top: 35.5px;
}

.horizontal-menu > li > ul > li:hover{
    background-color: #555;
}

ul#navMenu ul {
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    margin: 0px;
    color: #eee;
    padding: 2px 0;
}

ul#navMenu ul li a {
    background-color: var(--bg-menu);
    color: #eee!important;
    text-decoration: none;
    cursor: pointer;
    padding: 6px 2px 6px 10px;
}

ul#navMenu li {
    display: inline;
    float: left;
    position: relative;
    border-right: 1px solid #333;
}

ul#navMenu li ul li{
    border-right: none;
    border-bottom: 1px solid #333;
}

ul#navMenu a {
    text-decoration: none;
    color: #deebff;
    float: left;
    height: 100%;
    padding: 6px 15px;
}

ul#navMenu li a {
    text-align: center;
}

ul#navMenu li ul a {
    display: flex;
    justify-content: space-between;
}

ul#navMenu a:hover {
    background-color: #555;
    color: var(--color-primary);
}

ul#navMenu ul li a:hover {
    background-color: #555;
    color: var(--color-primary) !important;
}


ul#navMenu li:hover ul {
    display: block;
}

ul#navMenu ul a {
    min-width: 225px;
}

ul#navMenu ul li {
    display: block;
    margin: 0px;
}
ul#navMenu ul ul {
    top: 0;
    left: 100%;
}

ul#navMenu li:hover ul ul {
    display: none;
}

ul#navMenu ul li:hover ul {
    display: block;
}

ul#navMenu > li > a::after{
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\F0D7";
    margin-left: 8px;
    display: inline-block;
    font-size: .75rem;
}

ul#navMenu > li > ul > li > a::after{
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "";
    margin-left: 8px;
    display: inline-block;
    font-size: .75rem;
}

.sub-menu li a {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sub-menu li a span{
    width: 95%;
    text-align: left;
}

ul#navMenu ul.sub-menu ul {
    padding: 0;
    border: 1px solid #333;
}

@media (max-width: 920px){
    #menu{
        display: none;
    }
    #menu.open{
        display: flex;
    }
    .nav-toggle{
        display: flex;
    }
    
    .nav-toggle.close{
        opacity: 1 !important;
    }    

    .account-dropdown-menu > a > span{
        display: none;
    }

    header .nav-toggle{
        display: flex;
        width: 33.333%;
    }

    header .logo{
        width: 33.333%;
        text-align: center;
    }

    header .top-menu{
        width: 33.333%;
    }

    header > .top-menu > div {
        margin-left: 12px;
    }

    .horizontal-menu{
        flex-direction: column;
        background-color: var(--bg-menu);
        height: calc(100vh - 45px);
        z-index: 1050;
        width: 100vw;
    }

    .horizontal-menu > li > ul{
        position: relative;
        top: 0;
        width: 100vw;
        margin-bottom: 10px;
    }

    .horizontal-menu > li > ul li{
        border: none;
        padding-left: 20px;
    }

    .horizontal-menu > li:hover ul{
        top: 0;
    }

    .dropdown-list{
        z-index: 1051;
    }

    .time {
        display: none;
    }

    .main-content {
        padding: 10px;
    }
}
</style>
