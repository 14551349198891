import httpClient  from '.'; 
const API_CONTROLLER = 'order-heavy/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code';
        return httpClient.get(url, {
            params: {
                staffId: staffId
            }
        });
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getGoodsForOrderVehicle(orderId) { 
        let url = API_CONTROLLER + 'get-goods-for-order-vehicel';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    getAll(projectId) { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                projectId: projectId
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                customerId: search.customerId,
                contractId: search.contractId,
                keyword: search.keyword,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    addDetail(data) { 
        let url = API_CONTROLLER + 'add-detail';
        return httpClient.post(url, data);
    },

    editDetail(data) { 
        let url = API_CONTROLLER + 'edit-detail';
        return httpClient.post(url, data);
    },

    deleteDetail(id){ 
        let url = API_CONTROLLER + 'delete-detail';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },
}