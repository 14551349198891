<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-if="entity.status == 0 || entity.status == 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-if="entity.status == 0" v-shortkey="['ctrl', 's']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp">Lưu tạm<md-tooltip>Lưu tạm</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                <label for="invoiceCode">Số quyết toán</label>
                                <md-input name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập số quyết toán</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.invoiceDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.invoiceDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceDate.required">Vui lòng chọn ngày tạo</span>
                            </md-datepicker>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div style="display:flex;">
                                <md-field>
                                    <label for="note">Tổng quyết toán</label>
                                    <md-input disabled name="amount" :value="formatNumber(entity.pay)" ></md-input>
                                </md-field>
                                <md-field>
                                    <label for="note">Tổng dự toán</label>
                                    <md-input disabled name="vat" :value="formatNumber(totalEstimate)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên phụ trách</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>  
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                               <label for="reason">Nội dung</label>
                               <md-input name="reason" v-model="entity.note"></md-input>
                           </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="note">Chênh lệch quyết toán</label>
                                <md-input disabled name="pay" :value="formatNumber(entity.pay - totalEstimate)" ></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs :md-active-tab="1">
                                <md-tab v-if="entity.estimateList.length == 0 || entity.estimateList[0].estimate.paymentType == 1" id="tab-estimate" class="tab-content" md-label="Dự toán">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:80px;">#</th>
                                                            <th class="left" style="width:25%;">Số dự toán</th> 
                                                            <th class="left" style="width:22%;">Số tạm ứng</th>
                                                            <th class="left" style="width:20%;">Đơn hàng</th> 
                                                            <th class="right" style="width:20%;">Tiền dự toán</th>
                                                            <th style="width:10%">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.estimateList" :key="'estimate-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.estimate.estimateCode" class="form-control" type="text">
                                                                    <md-button @click="openEstimateList(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm số dự toán</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td>
                                                            <td class="left">
                                                                {{item.advancePayment.advancePaymentCode}}
                                                            </td>
                                                            <td class="left">
                                                                {{ item.estimate.order.orderCode }}
                                                            </td>
                                                            <td class="right">
                                                                {{ formatNumber(item.estimate.pay) }}
                                                            </td>
                                                            <td class="grid-action">
                                                                <a @click="delRow(item, index)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab v-if="entity.estimateList.length == 0 || entity.estimateList[0].estimate.paymentType == 1" id="tab-detail" class="tab-content" md-label="Chi tiết quyết toán">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="list" style="overflow-x: auto; width: 100%; padding-top: 10px;">
                                                    <table class="data-table-2" style="width: 2320px;"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th rowspan="2" style="width:50px; background-color: #eee;">#</th> 
                                                                <th rowspan="2" class="left" style="width:200px; background-color: #eee;">Dự toán</th> 
                                                                <th rowspan="2" class="left" style="width:200px; background-color: #eee;">Tên phí</th> 
                                                                <th rowspan="2" class="left" style="width:150px; background-color: #eee;">Loại</th>
                                                                <th rowspan="2" style="width:80px; background-color: #eee;">Số lượng</th>
                                                                <th colspan="3" style="width:320px; background-color: #96C9F4;">Dự toán</th>
                                                                <th rowspan="2" style="width:120px; background-color: #FFE9D0;">Tạm ứng</th>
                                                                <th colspan="6" style="width:620px; background-color: #ADD899;">Giải chi</th>
                                                                <th colspan="4" style="width:500px; background-color: #F5DAD2;">Quyết toán</th>
                                                            </tr>
                                                            <tr> 
                                                                <th class="right" style="width:120px; background-color: #96C9F4;">Đơn giá</th>
                                                                <th class="right" style="width:100px; background-color: #96C9F4;">VAT(%)</th>
                                                                <th class="right" style="width:120px; background-color: #96C9F4;">Thành tiền</th>
                                                                <th class="right" style="width:120px; background-color: #ADD899;">Đơn giá</th>
                                                                <th class="right" style="width:100px; background-color: #ADD899;">VAT(%)</th>
                                                                <th class="right" style="width:120px; background-color: #ADD899;">Thành tiền</th>
                                                                <th class="left" style="width:100px; background-color: #ADD899;">Số HĐ</th>
                                                                <th style="width:100px; background-color: #ADD899;">Ngày HĐ</th>
                                                                <th style="width:100px; background-color: #ADD899;">Ngày giải chi</th>
                                                                <th style="width:120px; background-color: #F5DAD2;">Đơn giá</th>
                                                                <th style="width:100px; background-color: #F5DAD2;">VAT</th>
                                                                <th style="width:120px; background-color: #F5DAD2;">Thành tiền</th>
                                                                <th style="width:180px; background-color: #F5DAD2;">Ghi chú</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="'detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="left">
                                                                    <span>{{ item.estimate.estimateCode }}</span>
                                                                </td>
                                                                <td class="left">
                                                                    <span>{{ item.fee.feeName}}</span>
                                                                </td>
                                                                <td class="left">
                                                                    {{ getFeeType(item.type) }}
                                                                </td>
                                                                <td class="center">
                                                                    {{item.quantity}}
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.price)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.vat)}}</div> 
                                                                </td> 
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.amount)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.amount)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.explainPrice)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.explainVat)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.explainAmount)}}</div> 
                                                                </td>
                                                                <td class="left">
                                                                    <div>{{item.billNumber}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{item.billDate}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{item.explainDate}}</div> 
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.billPrice" :class="{'is-error': $v.entity.details.$each[index].billPrice.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.billVat" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calAmount(item)" v-model="item.billAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.invoiceNote" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab v-else id="tab-detail" class="tab-content" md-label="Chi tiết quyết toán">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="list" style="overflow-x: auto; width: 100%; padding-top: 10px;">
                                                    <table class="data-table-2" style="width: 1750px;"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th rowspan="2" style="width:50px; background-color: #eee;">#</th> 
                                                                <th rowspan="2" class="left" style="width:200px; background-color: #eee;">Dự toán</th> 
                                                                <th rowspan="2" class="left" style="width:200px; background-color: #eee;">Tên phí</th> 
                                                                <th rowspan="2" class="left" style="width:150px; background-color: #eee;">Loại</th>
                                                                <th rowspan="2" style="width:80px; background-color: #eee;">Số lượng</th>
                                                                <th colspan="3" style="width:320px; background-color: #96C9F4;">Dự toán</th>
                                                                <th colspan="6" style="width:500px; background-color: #F5DAD2;">Quyết toán</th>
                                                            </tr>
                                                            <tr> 
                                                                <th class="right" style="width:120px; background-color: #96C9F4;">Đơn giá</th>
                                                                <th class="right" style="width:100px; background-color: #96C9F4;">VAT(%)</th>
                                                                <th class="right" style="width:120px; background-color: #96C9F4;">Thành tiền</th>
                                                                <th style="width:120px; background-color: #F5DAD2;">Đơn giá</th>
                                                                <th style="width:100px; background-color: #F5DAD2;">VAT</th>
                                                                <th style="width:120px; background-color: #F5DAD2;">Thành tiền</th>
                                                                <th class="left" style="width:100px; background-color: #F5DAD2;">Số HĐ</th>
                                                                <th style="width:100px; background-color: #F5DAD2;">Ngày HĐ</th>
                                                                <th style="width:180px; background-color: #F5DAD2;">Ghi chú</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="'detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="left">
                                                                    <span>{{ item.estimate.estimateCode }}</span>
                                                                </td>
                                                                <td class="left">
                                                                    <span>{{ item.fee.feeName}}</span>
                                                                </td>
                                                                <td class="left">
                                                                    {{ getFeeType(item.type) }}
                                                                </td>
                                                                <td class="center">
                                                                    {{item.quantity}}
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.price)}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.vat)}}</div> 
                                                                </td> 
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.amount)}}</div> 
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.billPrice" :class="{'is-error': $v.entity.details.$each[index].billPrice.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="calSum(item)" v-model="item.billVat" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number v-model="item.billAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="left">
                                                                    <div>{{item.billNumber}}</div> 
                                                                </td>
                                                                <td class="number-right">
                                                                    <div>{{item.billDate}}</div> 
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.invoiceNote" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                                <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar">
                                                    <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                </div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th> 
                                                            <th style="width:20%;">Bộ phận/phòng</th> 
                                                            <th style="width:20%;">Người phê duyệt</th> 
                                                            <th style="width:20%;">Tiêu đề</th>
                                                            <th style="width:20%;">Ghi chú</th> 
                                                            <th style="width:10%;">Thứ tự</th> 
                                                            <th style="width:150px">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.dept.name" class="form-control" type="text">
                                                                    <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="grid-control">
                                                                    <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                    <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                        <md-icon>manage_search</md-icon>
                                                                        <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </td> 
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.title" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.note" class="form-control" type="text">
                                                                </div>
                                                            </td>
                                                            <td class="center">
                                                                <div class="group-control">
                                                                    <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                </div>
                                                            </td> 
                                                            <td class="grid-action">
                                                                <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td> 
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeApprove"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <estimateList ref="estimateList" @close="closeEstimate"/>
   </div>
</template>
<script>
    import orderInvoiceService from '@/api/orderInvoiceService';
    import orderEstimateService from '@/api/orderEstimateService';
    import approveSettingService from '@/api/approveSettingService';
    import staffService from '@/api/staffService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import staffList from '@/components/popup/_StaffList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import estimateList from '@/components/popup/_OrderEstimateListForInvoice.vue';
    import approveList from '@/components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            estimateList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật quyết toán chi phí làm hàng'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                entity: { id: 0, orderType: 1, status: 0, details: [], amount: 0, estimateList: [], approveList: [], note: '', staffId: 0, staff: {id: 0, fullName: ''}, invoiceCode: '', invoiceDate: common.dateNow, pay: 0, payFee: 0, payOnBehalf: 0, payService: 0 },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                estimate: { id: 0, estimateId: 0, estimate: { estimateCode: '', paymentType: 1, pay: 0, order: { orderCode: '' } }, advancePaymentId: null, advancePayment: { advancePaymentCode: '' } },
                vat: common.vatF,
                currencyF: common.currencyF2,
                staffName: '',
                staffs: [],
                types: common.typeFee,
                catFee: 0,
                totalEstimate: 0
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật quyết toán chi phí làm hàng';
               this.getById();
            }
            else{
               this.title = 'Thêm mới quyết toán chi phí làm hàng';
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.entity.estimateList.push(this.estimate);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSummary(){
                this.entity.pay = 0;
                this.totalEstimate = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.totalEstimate = this.totalEstimate + parseFloat(this.entity.details[i].amount);
                    this.entity.pay = this.entity.pay + parseFloat(this.entity.details[i].billAmount)
                }
            },

            calAmount(item){
                item.billPrice = common.calPrice(item.quantity, item.billVat, item.billAmount);
                this.calSummary();
            },

            calSum(item){
                item.billAmount = common.calAmount(item.quantity, item.billPrice, item.billVat);
                this.calSummary();
            },

            getTypeName(transportTypeId){
                return this.typeOfTransport.filter((obj) => obj.id == transportTypeId)[0].text;
            },

            getCode(staffId){
                this.setLoading(true);
                orderInvoiceService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.invoiceCode = response.data.code;
                        this.entity.invoiceNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            
            addRow(){
                const item = { 
                    id: 'id_' + common.getFakeId(), 
                    advancePayment: { advancePaymentCode: '', advancePaymentDate: '' },
                    advancePaymentId: null,
                    estimate: { estimateCode: '', paymentType: 1, pay: 0, order: { orderCode: '' } },
                    estimateId: 0
                };
                this.entity.estimateList.push(item);
            },

            //Estimate Request
            delRow(item, index){
                this.entity.estimateList.splice(index, 1);
                for(var i = 0; i < this.entity.details.length; i ++){
                    let estimateId = this.entity.details[i].estimateId;
                    if(item.estimateId === estimateId){
                        this.entity.details = this.entity.details.filter(obj => !(obj.estimateId == item.estimateId));
                    }
                }

                this.calSummary();
            },

            closeEstimate(list){
                const index = this.entity.estimateList.findIndex(x => x.id == this.selectedId);
                this.entity.estimateList.splice(index, 1);
                list.map(item => {
                    let obj = {
                        id: 0,
                        estimate: item,
                        estimateId: item.id,
                        advancePaymentId: item.advancePayment.id,
                        advancePayment: item.advancePayment
                    };
                    const index = this.entity.estimateList.findIndex(x => x.estimateId == item.id);
                    if(index == -1){
                        this.entity.estimateList.push(obj);
                    }   
                    this.getEstimateById(item.id);
                })
            },

            getEstimateById(id){
                this.setLoading(true);
                orderEstimateService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        for(var i = 0; i < response.data.details.length; i ++){
                            const detail = response.data.details[i];
                            detail.billPrice = detail.price;
                            detail.billVat = detail.vat;
                            detail.billAmount = detail.amount;
                            this.entity.details.push(detail);
                        }
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.$refs.estimateList.close();});
            },

            openEstimateList(id){
                this.selectedId = id;
                this.$refs.estimateList.open();
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(39).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairAdvancePayment), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.estimateList.length <= 0){
                    messageBox.showWarning("Vui lòng nhập tạm ứng chi phí làm hàng");
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập chi tiết quyết toán");
                    return;
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                orderInvoiceService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/order-invoice');
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                orderInvoiceService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/order-invoice');
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getApproveSetting();
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                orderInvoiceService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/order-invoice?orderType=' + this.orderType);
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                invoiceCode: { required },
                invoiceDate: { required },
                details: {
                    $each: {
                        billPrice: {
                            required,
                            isSelected(value) {
                                if (value >= 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                }
            }
        }
    })
</script>
